export class OfferCardHelpers {
  static generateIndexesForRow(
    rowIndex: number,
    maxItemsPerRow: number,
    itemsAmount: number
  ): number[] {
    const indexesPerRow: number[] = [];
    const startIndex: number = rowIndex * maxItemsPerRow;

    for (
      let i = startIndex;
      i < Math.min(startIndex + maxItemsPerRow, itemsAmount);
      i++
    ) {
      indexesPerRow.push(i);
    }

    return indexesPerRow;
  }

  static getMaxItemsAmountPerRow(width: number): number {
    let maxItems: number;
    if (width >= 1572) {
      maxItems = 5;
    } else if (width <= 1571 && width >= 1129) {
      maxItems = 4;
    } else if (width <= 1128 && width >= 845) {
      maxItems = 3;
    } else if (width <= 844 && width >= 562) {
      maxItems = 2;
    } else if (width <= 561) {
      maxItems = 1;
    }
    return maxItems;
  }

  static getRowsAmount(itemsAmount: number, width: number): number {
    const maxItemsPerRow: number = this.getMaxItemsAmountPerRow(width);
    const extraRow: boolean = itemsAmount % maxItemsPerRow !== 0;

    return Math.floor(itemsAmount / maxItemsPerRow) + (extraRow ? 1 : 0);
  }
}
