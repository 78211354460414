import { ApiKeyClient, bonusSatsStateVar } from "@apollo";
import { API_KEY_GET_BONUS_SATS } from "@queries";

export async function getBonusSatsAmount(): Promise<number> {
  try {
    const {
      data: { bonusSats },
    } = await ApiKeyClient.query<{
      bonusSats: number;
    }>({ query: API_KEY_GET_BONUS_SATS, fetchPolicy: "network-only" });

    return bonusSatsStateVar(bonusSats);
  } catch (err) {
    console.error(`[REFERRAL SATS ERROR]: ${err}`);
  }
}
