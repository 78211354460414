export const pt = {
  dialogText: {
    one:
      "Levamos a segurança a sério, por isso limitamos o recurso de transferência no aplicativo Coinmiles.",
    two:
      "Faça o download agora para transferir seu Bitcoin para sua carteira favorita ou para sua conta bancária através de transferência em Bitcoin cash out",
    three:
      "Faça o download agora para transferir seu Bitcoin para sua carteira favorita ou para sua conta bancária!",
  },
  myBalance: "Meu saldo",
  btcPrice: "Preço BTC",
  interacTransfer: "Transferência em Bitcoin cash out",
  bitcoinSent: "Bitcoin enviado",
  myActivity: "Minhas atividades",
  missing: "Faltando transações?",
  clickHere: " Clique aqui",
  noRewards: "Ainda não há recompensas?",
  startVisiting: "Comece a visitar nossos parceiros e ganhe dinheiro de volta.",
  welcomeBonus: "Bônus de boas-vindas",
  bonus: "Bônus",
  you: "Você",
  referralSignUp: "se inscreveu!",
  newUserReferralSignUp: "Você foi indicado por ",
  missingTransactionsModal: {
    whyTransactionMissing: "Por que uma das minhas transações está faltando?",
    missingTransactionText: {
      one:
        "Não se preocupe, sua transação pode demorar até 7 dias para aparecer. Embora façamos o possível para fornecer notificações em tempo real à medida que as recebemos, estamos trabalhando com vários parceiros que talvez ainda não tenham implementado essa funcionalidade em tempo real. \n \n Em alguns casos, como com anunciantes de viagens, os relatórios de vendas podem levar semanas ou mais para serem exibidos. Isso ocorre porque os sites de viagens geralmente relatam vendas apenas quando os clientes fazem check-in para voos ou hotéis ou retiram o carro alugado. \n \n Se uma de suas transações ainda não aparecer após esse atraso, notifique-nos por e-mail em ",
      two: "clicando aqui ",
      three: "e certifique-se de anexar uma cópia do seu recibo.",
    },
    transactionOtherCurrency: "Por que algumas transações são informadas em outra moeda?",
    transactionCurrencyAnswer:
      "Nossos parceiros operam em vários países. Alguns deles convertem automaticamente o valor da sua compra em sua moeda local e depois nos pagam sua recompensa nessa moeda. A moeda que mostramos é a moeda na qual recebemos seu pagamento.",
  },
  rewardGift: "Presente de ",
};
