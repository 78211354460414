import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Auth } from "aws-amplify";
import { trim } from "validator";

// Component imports
import { DialogButton, Error, LoadingSpinner } from "@components";
import { Grid, Typography } from "@material";

// Misc Imports

import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";
import { AuthUtils, ModalHelper, navigate, UserUtils } from "@utils";
import { sendSegmentTrackEvent } from "helpers/analytics";

const useStyles = makeStyles((theme: Theme) => ({
  textFieldContainer: {
    height: "auto",
    width: 308,
    marginBottom: 6,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  textField: {
    width: 308,
    height: 48,
    padding: 2,
    paddingLeft: 16,
    border: `1px solid ${theme.palette.colors.border.light}`,
    fontFamily: "Roboto, sans-serif",
    fontSize: 16,
    borderRadius: 2,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  formFieldText: {
    marginTop: 10,
    color: "#000000",
    fontSize: 14,
    textAlign: "center",
    paddingBottom: 6,
  },
  signUpButtonContainer: {
    marginTop: 18,
    marginBottom: 18,
  },
}));

type FormValues = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  password: string;
  passwordConfirmation: string;
};

export function SignUpForm() {
  const classes: any = useStyles({});
  const { t } = useTranslation(["signUp", "common"]);

  const [emailExistsError, setEmailExistsError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { register, handleSubmit, errors, watch } = useForm();

  const signUp: SubmitHandler<FormValues> = async (data): Promise<void> => {
    setLoading(true);
    setEmailExistsError(false);

    const { firstName, lastName, emailAddress, password } = data;

    try {
      sendSegmentTrackEvent("emailNewSignupButton");

      await Auth.signUp({
        username: trim(emailAddress),
        password: trim(password),
        attributes: {
          given_name: trim(firstName),
          family_name: trim(lastName),
        },
      });

      const navigateToHome = (): void => navigate("/", { replace: true });

      ModalHelper.open({
        modalType: "signUpSuccess",
        modalProps: {
          emailAddress: data.emailAddress,
          navigateToHome,
          resendButton: true,
        },
        modalOptions: { onClose: navigateToHome },
      });
    } catch (err) {
      setLoading(false);
      setEmailExistsError(true);
    }
  };

  return (
    <form onSubmit={handleSubmit(signUp)}>
      <Grid container alignItems="flex-end" className={classes.textFieldContainer}>
        <Typography className={classes.formFieldText}>{t("common:firstName")}</Typography>
        <input
          id="sign-up-first-name"
          placeholder={t("common:firstName")}
          name="firstName"
          ref={register({
            required: true,
            maxLength: 20,
            validate: (value) => UserUtils.isValidName(value),
          })}
          className={classes.textField}
        />
        {errors.firstName && <Error>{t("common:errors.firstName")}</Error>}
      </Grid>{" "}
      <Grid container alignItems="flex-end" className={classes.textFieldContainer}>
        <Typography className={classes.formFieldText}>{t("common:lastName")}</Typography>
        <input
          id="sign-up-last-name"
          placeholder={t("common:lastName")}
          name="lastName"
          ref={register({
            required: true,
            maxLength: 20,
            validate: (value) => UserUtils.isValidName(value),
          })}
          className={classes.textField}
        />
        {errors.lastName && <Error>{t("common:errors.lastName")}</Error>}
      </Grid>
      <Grid container alignItems="flex-end" className={classes.textFieldContainer}>
        <Typography className={classes.formFieldText}>
          {t("common:emailAddress")}
        </Typography>
        <input
          id="sign-up-email"
          placeholder={t("common:emailAddress")}
          name="emailAddress"
          ref={register({
            required: true,
            validate: (value) => UserUtils.isValidUsername(value),
          })}
          className={classes.textField}
        />
        {errors.emailAddress && <Error>{t("common:errors.emailAddress")}</Error>}
        {emailExistsError && <Error>{t("common:errors.invalidCredentials")}</Error>}
      </Grid>
      <Grid container alignItems="flex-end" className={classes.textFieldContainer}>
        <Typography className={classes.formFieldText}>{t("common:password")}</Typography>
        <input
          id="sign-up-password"
          placeholder={t("common:password")}
          name="password"
          type="password"
          ref={register({
            required: true,
            validate: (value) => UserUtils.isValidPassword(value),
          })}
          className={classes.textField}
        />
        {errors.password && <Error>{t("common:errors.passwordRequirements")}</Error>}
      </Grid>
      <Grid container alignItems="flex-end" className={classes.textFieldContainer}>
        <Typography className={classes.formFieldText}>
          {t("common:passwordConfirmation")}
        </Typography>
        <input
          id="sign-up-password-confirmation"
          placeholder={t("common:passwordConfirmation")}
          name="passwordConfirmation"
          type="password"
          ref={register({
            validate: (value) => value === watch("password"),
          })}
          className={classes.textField}
        />
        {errors.passwordConfirmation && (
          <Error>{t("common:errors.passwordConfirmation")}</Error>
        )}
      </Grid>
      <Grid className={classes.signUpButtonContainer}>
        <DialogButton
          colorVariant="pink"
          width="100%"
          height={56}
          borderRadius={10}
          fontSize={16}
          type="submit"
          hoverFade={false}
        >
          {loading ? <LoadingSpinner size={24} color="white" /> : t("signUpWithEmail")}
        </DialogButton>
      </Grid>
    </form>
  );
}
