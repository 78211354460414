import React from "react";

import { bonusSatsStateVar, useReactiveVar } from "@apollo";

// Misc imports

import { Assets } from "@assets";
import { useMediaQuery } from "@hooks";
import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";
import { ModalHelper } from "@utils";

// Component imports
import {
  AppleLoginButton,
  DialogButton,
  DividerLineWithText,
  FacebookLoginButton,
  Link,
} from "@components";
import { CloseIcon } from "@icons";
import { Grid, Paper, Typography } from "@material";
const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    maxWidth: 425,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    position: "relative",
    margin: "auto",
    backgroundColor: "white",
    borderRadius: 8,
    outline: "none !important",
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      width: "100%",
      padding: 0,
    },
  },
  cancelIconContainer: {
    position: "absolute",
    alignItems: "center",
    top: 12,
    right: 16,
    zIndex: 5,
  },
  cancelIcon: {
    cursor: "pointer",
  },
  bitcoinIconsContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 8,
  },
  logo: {
    width: 140,
  },
  textAndButtonsContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    padding: 32,
    paddingTop: 8,
    [theme.breakpoints.down(354)]: {
      height: "auto",
      width: "95%",
      padding: 16,
      paddingTop: 0,
    },
  },
  textContainer: {
    width: "100%",
  },
  joinHeaderContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 4,
    width: "auto",
  },
  topJoinHeaderText: {
    textAlign: "left",
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 1.2,
    marginBottom: 2,
  },
  joinHeaderText: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    fontSize: 27,
    fontWeight: 800,
    lineHeight: 1.2,
    marginBottom: 8,
  },
  joinSubheaderTextContainer: {
    marginBottom: 24,
  },
  joinSubheaderText: {
    fontSize: 17,
    textAlign: "left",
    lineHeight: 1.3,
    [theme.breakpoints.down(445)]: {
      fontSize: "4vw",
    },
  },
  socialLoginButtonContainer: {
    width: "100%",
  },
  emailSignUpButtonContainer: {
    width: "100%",
    marginBottom: 8,
  },
  emailSignupButton: { fontFamily: "Roboto", fontSize: 24, fontWeight: 700 },
  termsText: {
    textAlign: "center",
  },
  alreadyMemberTextContainer: {},
  alreadyMemberText: {
    textAlign: "center",
    color: "#464646",
    fontSize: 18,
    margin: "10px 8px 8px 8px",
    [theme.breakpoints.down("xs")]: {
      marginTop: 8,
    },
  },
  leftHalf: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      height: 492,
      alignItems: "flex-end",
      padding: 32,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 8,
      padding: 0,
      height: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 8,
      paddingTop: 0,
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  rightHalf: {
    position: "relative",
    width: "100%",
    height: 492,
    backgroundColor: "#EFEFEF",
    zIndex: 2,
  },
}));

export function SignUpDrawer() {
  const classes: any = useStyles({});
  const { t } = useTranslation(["signUp", "common"]);
  const extraSmall: boolean = useMediaQuery("(max-width:599px)");

  const bonusSats = useReactiveVar(bonusSatsStateVar);

  return (
    <Paper className={classes.paper}>
      <Grid item className={classes.cancelIconContainer}>
        <CloseIcon
          className={classes.cancelIcon}
          width={13}
          fill={"#EFEFEF"}
          onClick={ModalHelper.close}
        />
      </Grid>
      <Grid className={classes.bitcoinIconsContainer}>
        <img
          src="https://bitcoin-miles-image-service-production.s3.ca-central-1.amazonaws.com/tutorial/hands-web-cropped.jpg"
          style={{
            width: "100%",
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
          }}
          alt="welcome-to-coinmiles"
        />
      </Grid>
      <Grid
        container
        item
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.textAndButtonsContainer}
      >
        <Grid container justifyContent="center" className={classes.textContainer}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.joinHeaderContainer}
          >
            <Typography className={classes.joinHeaderText}>
              <img
                src={Assets.WAVE_EMOJI}
                width={25}
                style={{ marginRight: 8 }}
                alt="waving-hand-emoji"
              />
              {t("common:welcome")}!
            </Typography>
          </Grid>
          <Grid className={classes.joinSubheaderTextContainer}>
            <Typography
              className={classes.joinSubheaderText}
              style={{ textAlign: "center" }}
            >
              {t("drawer.loginToViewDeals.one")}
              <strong>{t("drawer.loginToViewDeals.two")}</strong>
              {t("drawer.loginToViewDeals.three")}
              {bonusSats}
              {t("drawer.loginToViewDeals.four")}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.socialLoginButtonContainer}>
          <FacebookLoginButton
            handleClose={ModalHelper.close}
            height={extraSmall ? 48 : 56}
            style={{ marginBottom: 12 }}
          />
          <AppleLoginButton
            handleClose={ModalHelper.close}
            height={extraSmall ? 48 : 56}
          />
        </Grid>
        <DividerLineWithText text={t("common:or")} />
        <Grid className={classes.emailSignUpButtonContainer}>
          <Link className={classes.emailSignupButton} to="/sign-up">
            <DialogButton
              colorVariant="white"
              borderRadius={10}
              height={extraSmall ? 48 : 56}
              fontSize={16}
              width="100%"
              onClick={ModalHelper.close}
            >
              {t("signUpWithEmail")}
            </DialogButton>
          </Link>
        </Grid>
      </Grid>
    </Paper>
  );
}
