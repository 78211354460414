import { DeviceUtils, getUserCountry } from "@utils";

const assembleMetadata = (locationData: LocationData): UserMetadata => {
  const { city, region, country } = locationData;
  const device = {
    type: DeviceUtils.getPhoneOrDesktop(),
    model: "n/a",
    os: DeviceUtils.getOS(),
  };
  const language: string = localStorage.getItem("i18nextLng");
  const webOrMobile = "web";

  return { city, region, country, device, language, webOrMobile };
};

export const getUserMetadata = async (): Promise<UserMetadata> =>
  new Promise<UserMetadata>(async (resolve) => {
    let locationData: LocationData = {
      city: null,
      region: null,
      country: null,
    };

    try {
      locationData = await getUserCountry();

      const metadata = assembleMetadata(locationData);
      resolve(metadata);
    } catch (err) {
      locationData = {
        city: "Unable to detect.",
        region: "Unable to detect.",
        country: "Unable to detect.",
      };

      const metadata = assembleMetadata(locationData);
      resolve(metadata);
    }
  });
