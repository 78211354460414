import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Auth } from "aws-amplify";

import { cognitoUserStateVar } from "@apollo";

// Component imports
import { DialogButton, Error, LoadingSpinner } from "@components";
import { Grid, Typography } from "@material";

// Misc Imports

import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";
import {
    AuthUtils,
    ModalHelper,
    navigate,
    UserUtils,
    // rebuildCognitoSession,
} from "@utils";

const useStyles = makeStyles((theme: Theme) => ({
    textFieldContainer: {
        height: "auto",
        width: 308,
    },
    modalText: {
        marginTop: 16,
        color: "#000000",
        fontSize: 14,
        textAlign: "center",
    },
    textField: {
        width: 308,
        height: 48,
        padding: 2,
        paddingLeft: 16,
        border: `1px solid ${theme.palette.colors.border.light}`,
        fontFamily: "Roboto, sans-serif",
        fontSize: 16,
        borderRadius: 2,
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    submitButtonContainer: {
        marginTop: 27,
        marginBottom: 18,
    },
}));

type FormValues = {
    newPassword: string;
    code?: string;
};

type Props = ResetPasswordFormParams;

export function ResetPasswordForm({ emailAddress, referrer }: Props) {
    const classes: any = useStyles({});
    const { t } = useTranslation(["resetPassword", "common"]);

    const [invalidCode, setInvalidCode] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const { register, handleSubmit, errors, watch } = useForm();

    const isAutomaticRequest = !!(referrer === "new-password-required");

    const handleRedirectToSignIn = (): void => {
        ModalHelper.close();
        navigate("/sign-in", { replace: true });
    };

    const handleOpenPasswordChangedSuccessModal = (): void => {
        ModalHelper.open({
            modalType: "passwordChangedSuccess",
            modalProps: { handleRedirectToSignIn },
            modalOptions: { onClose: handleRedirectToSignIn },
        });
    };

    const handleChangePasswordSubmit = async (data: FormValues): Promise<void> => {
        setLoading(true);
        setInvalidCode(false);

        const { code, newPassword } = data;

        const username = emailAddress;

        try {
            await Auth.forgotPasswordSubmit(username, code, newPassword);
            handleOpenPasswordChangedSuccessModal();
            setLoading(false);
        } catch (err) {
            setInvalidCode(true);
            setLoading(false);
        }
    };

    const handleNewPasswordSubmit = async (data: FormValues): Promise<void> => {
        setLoading(true);

        const { newPassword } = data;

        try {
            const cognitoUser = cognitoUserStateVar();

            await Auth.completeNewPassword(cognitoUser, newPassword);

            handleOpenPasswordChangedSuccessModal();
            setLoading(false);
            cognitoUserStateVar(undefined);
        } catch (error) {
            console.error(error);
            setLoading(false);
            cognitoUserStateVar(undefined);
        }
    };

    const onSubmitClicked: SubmitHandler<FormValues> = (data): void => {
        isAutomaticRequest
            ? handleNewPasswordSubmit(data)
            : handleChangePasswordSubmit(data);
    };

    return (
        <form autoComplete="new-password" onSubmit={handleSubmit(onSubmitClicked)}>
            {!isAutomaticRequest && (
                <Grid
                    container
                    alignItems="flex-end"
                    className={classes.textFieldContainer}
                >
                    <Typography className={classes.modalText}>{t("code")}</Typography>
                    <input
                        autoComplete="off"
                        id="reset-password-code"
                        name={"code"}
                        placeholder={t("code")}
                        ref={register({
                            required: true,
                        })}
                        className={classes.textField}
                    />
                    {invalidCode && <Error>{t("errorText.invalidCode")}</Error>}
                </Grid>
            )}
            <Grid container alignItems="flex-end" className={classes.textFieldContainer}>
                <Typography className={classes.modalText}>
                    {t("common:newPassword")}
                </Typography>
                <input
                    autoComplete="off"
                    id="reset-password-password"
                    type="password"
                    name={"newPassword"}
                    placeholder={t("common:newPassword")}
                    ref={register({
                        required: true,
                        validate: (value) => UserUtils.isValidPassword(value),
                    })}
                    className={classes.textField}
                />
                {errors.newPassword && (
                    <Error>{t("common:errors.passwordRequirements")}</Error>
                )}
            </Grid>
            <Grid container alignItems="flex-end" className={classes.textFieldContainer}>
                <Typography className={classes.modalText}>{t("passwordConf")}</Typography>
                <input
                    autoComplete="off"
                    id="reset-password-password-confirmation"
                    type="password"
                    name={"newPasswordConfirmation"}
                    placeholder={t("passwordConf")}
                    ref={register({
                        validate: (value) => value === watch("newPassword"),
                    })}
                    className={classes.textField}
                />
                {errors.newPasswordConfirmation && (
                    <Error>{t("common:errors.passwordConfirmation")}</Error>
                )}
            </Grid>
            <Grid className={classes.submitButtonContainer}>
                <DialogButton colorVariant="pink" width="100%" type="submit">
                    {loading ? (
                        <LoadingSpinner size={24} color="white" />
                    ) : (
                        t("common:submit")
                    )}
                </DialogButton>
            </Grid>
        </form>
    );
}
