export const pt = {
  resetPassword: "Redefinir senha",
  enterNewPassword: "Digite sua nova senha.",
  enterTheCode: "Digite o código que foi enviado para o endereço de e-mail fornecido.",
  code: "Code",
  passwordConf: "Nova confirmação de senha",
  errorText: {
    oops: "Ops. Parece que esse link expirou.",
    sendNew: "Clique aqui para enviar um novo link.",
    invalidCode: "Código inválido",
  },
};
