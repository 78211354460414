import React, {forwardRef, ForwardRefExoticComponent, ReactNode} from "react";

import {Link as GatsbyLink} from "gatsby";

type Props = {
  children: ReactNode;
  to: string;
  onClick?: any;
  state?: any;
  className?: string;
  activeStyle?: any;
  getProps?: any;
};

export const Link: ForwardRefExoticComponent<Props> = forwardRef(
  ({children, to, onClick, state, className, activeStyle, getProps}, _ref) => {
    return (
      <GatsbyLink
        to={to}
        onClick={onClick}
        style={{color: "#000000", textDecoration: "none"}}
        state={state}
        className={className}
        activeStyle={activeStyle}
        getProps={getProps}
      >
        {children}
      </GatsbyLink>
    );
  }
);
