import { gql } from "@apollo/client";

export const USER_POOL_GET_USER_DATA = gql`
  query GetUserData {
    user: getUser {
      emailAddress
      firstName
      lastName
      didLogin
      profilePhotoUrl
      metadata {
        country
      }
    }
  }
`;
