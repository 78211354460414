import { en } from "./translations.en";
import { fr } from "./translations.fr";
import { pt } from "./translations.pt";

export const translations = {
  en: {
    signIn: en,
  },
  fr: {
    signIn: fr,
  },
  pt: {
    signIn: pt,
  },
};
