import * as React from "react";

export const ListIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{"28A86905-3532-427D-AEBA-751C815FAC9E"}</title>
    <path
      d="M7 16v3H4v-3h3zm13 0v3H9v-3h11zM7 11v3H4v-3h3zm13 0v3H9v-3h11zM7 6v3H4V6h3zm13 0v3H9V6h11z"
      fillRule="nonzero"
    />
  </svg>
);
