import { gql } from "@apollo/client";

export const API_KEY_GET_LOCATION_SUGGESTIONS_BY_CITY = gql`
  query GetLocationSuggestionsByCity(
    $searchField: String
    $sessionToken: String
  ) {
    getLocationSuggestionsByCity(
      searchField: $searchField
      sessionToken: $sessionToken
    ) {
      placeId
      locality
      city
    }
  }
`;
