import { gql } from "@apollo/client";

export const API_KEY_GET_ONE_LOCATION_BY_CITY = gql`
  query GetGeometryByLocation($placeId: ID!) {
    getGeometryByLocation(placeId: $placeId) {
      lat
      lng
    }
  }
`;
