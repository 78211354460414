import * as React from "react";

export const HeartIconOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 26 26" {...props}>
    <path
      d="M13.202 6.75c-.015-.01-.025-.012-.031-.02-.397-.395-.788-.796-1.191-1.186A5.09 5.09 0 009.942 4.34 5.264 5.264 0 003.63 7.056a5.145 5.145 0 00-.528 2.142c-.027.87.147 1.7.535 2.484.24.484.541.924.918 1.309.363.372.735.735 1.101 1.103l2.292 2.295c.983.983 1.969 1.964 2.951 2.947.675.674 1.347 1.349 2.02 2.024l.277.29c.103-.11.193-.205.284-.297l2.11-2.11 2.11-2.11c.699-.7 1.399-1.398 2.096-2.097.666-.667 1.334-1.336 1.999-2.005a5.029 5.029 0 001.282-2.174 5.2 5.2 0 00-.046-3.14 5.173 5.173 0 00-1.54-2.317 5.236 5.236 0 00-4.356-1.225c-.952.163-1.8.551-2.521 1.19-.431.383-.828.804-1.24 1.208a6.465 6.465 0 00-.172.177m-.02-2.956c.295-.212.576-.44.88-.628.334-.205.68-.39 1.034-.553a6.824 6.824 0 012.093-.563 7.294 7.294 0 013.523.456 7.235 7.235 0 012.8 1.944 7.246 7.246 0 011.694 3.269 7.333 7.333 0 01-.532 4.815 7.49 7.49 0 01-1.485 2.08l-2.462 2.46c-.712.714-1.425 1.426-2.138 2.139l-2.13 2.13c-.68.68-1.363 1.36-2.04 2.042-.271.274-.59.453-.972.509a1.62 1.62 0 01-1.205-.283 2.202 2.202 0 01-.281-.242c-.618-.613-1.233-1.23-1.847-1.846-.677-.676-1.353-1.351-2.028-2.028L5.908 17.32c-.7-.7-1.4-1.396-2.096-2.097-.392-.4-.791-.796-1.159-1.22-.414-.477-.733-1.021-.997-1.598a7.247 7.247 0 01-.58-1.988 7.432 7.432 0 01.472-3.85 7.211 7.211 0 01.958-1.668c.372-.488.8-.925 1.281-1.307a7.19 7.19 0 011.634-.98 7.286 7.286 0 012.103-.561 7.325 7.325 0 015.313 1.467c.12.094.238.192.346.277"
      fillRule="evenodd"
    />
  </svg>
);
