import { gql } from "@apollo/client";

export const MUTATION_SEND_USER_INFO_TO_MAILERLITE = gql`
  mutation SendUserInfoToMailerLite(
    $firstName: String!
    $lastName: String!
    $emailAddress: String!
  ) {
    userOptedIn: sendUserInfoToMailerLite(
      firstName: $firstName
      lastName: $lastName
      emailAddress: $emailAddress
    )
  }
`;
