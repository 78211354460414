import * as React from "react";

export const GridIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{"C2045B29-EB32-40A9-B2EF-093C087274AF"}</title>
    <path
      d="M10 14v6H4v-6h6zm10 0v6h-6v-6h6zM10 4v6H4V4h6zm10 0v6h-6V4h6z"
      fillRule="evenodd"
    />
  </svg>
);
