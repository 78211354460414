import { gql } from "@apollo/client";

export const USER_POOL_GET_LINKED_CARDS_DATA = gql`
  query GetLinkedCardsData {
    linkedCards: getLinkedCards {
      id
      brand
      expMonth
      expYear
      externalId
      last4
    }
  }
`;
