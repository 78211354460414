import React from "react";
import { Auth } from "aws-amplify";

// Component imports
import { AppleIcon } from "@icons";

// Misc Imports
import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";
import { sendSegmentTrackEvent } from "helpers/analytics";

const useStyles = (props) =>
  makeStyles((theme: Theme) => ({
    socialLoginButton: {
      cursor: "pointer",
      outline: "none",
      fontFamily: "'Roboto', sans-serif",
      padding: 0,
      width: "100%",
      height: props.height || 56,
      backgroundColor: theme.palette.black,
      color: theme.palette.white,
      fontWeight: 500,
      fontSize: 16,
      textTransform: "none",
      textAlign: "center",
      marginBottom: 8,
      borderRadius: 10,
      minWidth: 250,
      "&:hover": {
        backgroundColor: `${theme.palette.black} !important`,
      },
    },
    appleIcon: {
      fill: theme.palette.white,
      width: 20,
      marginRight: 14,
      [theme.breakpoints.down("xs")]: {
        marginRight: 16,
      },
      transitionProperty: "fill",
      transitionDuration: "0.6s",
      transitionTimingFunction: "ease-out",
    },
    innerLabel: {
      display: "flex",
      alignItems: "center",
      marginLeft: "auto",
      marginRight: "auto",
      justifyContent: "flex-start",
      width: 220,
    },
    labelText: {
      color: theme.palette.white,
      transitionProperty: "color",
      transitionDuration: "0.6s",
      transitionTimingFunction: "ease-out",
    },
  }));

type Props = {
  handleClose?: any;
  height?: number | string;
  style?: any;
};

enum AppleIdentityProvider {
  Apple = "SignInWithApple",
}

export function AppleLoginButton(props: Props) {
  const classes: any = useStyles(props)({});
  const { t } = useTranslation("signIn");

  const { handleClose, style } = props;

  const loginWithApple = async (): Promise<void> => {
    try {
      sendSegmentTrackEvent("appleSigninButton");

      await Auth.federatedSignIn({
        customProvider: AppleIdentityProvider.Apple,
      });
    } catch (_err) {}
  };

  return (
    <button
      className={classes.socialLoginButton}
      onClick={() => {
        handleClose && handleClose();
        loginWithApple();
      }}
      style={style}
    >
      <div className={classes.innerLabel}>
        <AppleIcon className={classes.appleIcon} />
        {t("social.apple")}
      </div>
    </button>
  );
}
