import React from "react";

type Props = {
  children: any;
  center?: boolean;
};

export const Error: React.FC<Props> = ({children, center}) => (
  <p
    style={{
      color: "red",
      marginTop: 4,
      marginBottom: 4,
      textAlign: center ? "center" : "left",
    }}
  >
    {children}
  </p>
);
