export const fr = {
  pageMessage: {
    one: "Réserver un hôtel.",
    two: "Jusqu'à 25% de remise \n en Bitcoin.",
  },
  inputDestination: "Votre destination",
  emptyDestination: "Veuillez saisir une destination",
  checkIn: "Arrivée",
  checkOut: "Départ",
  room: "chambre",
  rooms: "chambres",
  guest: "personne",
  guests: "personnes",
  done: "terminé",
  btnSearch: "Rechercher",
};
