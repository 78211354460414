import React from "react";

import { currentCountryVar, currentUserVar, useQuery, useReactiveVar } from "@apollo";
import { SCREEN_GET_NAVBAR_DATA } from "@queries";

// Misc Imports
import { Wallet } from "@models";
import { makeStyles } from "@styles";

// Component imports
import { CoinmilesLogo, Link } from "@components";
import { AppBar, Grid, Hidden, Toolbar } from "@material";

import { NavbarButtons } from "./navbar-buttons";
import { NavbarLocaleSelectionContainer } from "./navbar-locale-selection-container";
import { NavbarLoginButton } from "./navbar-login-button";
import { NavbarMenuLarge } from "./navbar-menu-large";
import { NavbarMenuMobile } from "./navbar-menu-mobile";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.white,
    zIndex: 10,
    [theme.breakpoints.down("sm")]: {
      minHeight: 67,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      marginBottom: 3,
    },
  },
  toolbar: {
    maxWidth: 1400,
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: "0px",
    paddingRight: "0px",
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    height: 25.8,
    width: 132,
    marginLeft: 0,
    marginTop: 0,
    marginRight: 63,
    [theme.breakpoints.down("md")]: {
      marginRight: 32,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 5,
      marginRight: 0,
    },
  },
}));

type NavbarData = {
  wallet: Wallet;
};

export function Navbar() {
  const classes: any = useStyles({});

  const country = useReactiveVar(currentCountryVar);
  const user = useReactiveVar(currentUserVar);

  const { data, loading } = useQuery<NavbarData>(SCREEN_GET_NAVBAR_DATA, {
    variables: { currency: country?.currencyCode },
  });

  return (
    <AppBar position="sticky" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Grid container justifyContent="space-between">
          <Grid
            container
            item
            alignItems="center"
            xs={4}
            md={8}
            className={classes.leftSide}
          >
            <Link to="/">
              <CoinmilesLogo className={classes.logo} />
            </Link>
            <Hidden only={["xs", "sm"]}>
              <NavbarButtons loggedIn={!!user} />
            </Hidden>
          </Grid>
          <Grid
            container
            item
            justifyContent="flex-end"
            alignItems="center"
            xs={8}
            md={4}
            className={classes.rightside}
          >
            <NavbarLocaleSelectionContainer />
            <Hidden only={["sm", "md", "lg", "xl"]}>
              <Grid item>
                {user ? (
                  <NavbarMenuMobile user={user} loading={loading} />
                ) : (
                  <NavbarLoginButton small />
                )}
              </Grid>
            </Hidden>
            <Hidden only={["xs"]}>
              {user ? (
                <NavbarMenuLarge
                  user={user}
                  wallet={data?.wallet}
                  country={country}
                  loading={loading}
                />
              ) : (
                <NavbarLoginButton />
              )}
            </Hidden>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
