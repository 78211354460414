import React from "react";
import PropTypes from "prop-types";
import Amplify from "aws-amplify";

import { initializeAppStartup } from "@utils";

import { Layout, TopLayout } from "@components";
import { amplifyConfig } from "@amplify";

Amplify.configure(amplifyConfig);

initializeAppStartup();

export const wrapRootElement = ({ element }) => (
  <TopLayout>{element}</TopLayout>
);

export const wrapPageElement = ({ element, props }) => (
  <Layout location={props.location}>{element}</Layout>
);

wrapRootElement.propTypes = {
  element: PropTypes.node,
};
