import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import { makeStyles } from "@styles";
import { Button, Menu, Dropdown } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "@i18n";

export type Props = {
  roomCount: number;
  setRoomcount: Dispatch<SetStateAction<number>>;
  guestCount: number;
  setGuestcount: Dispatch<SetStateAction<number>>;
  roomGuestCounter: (operation: string, counter: any, limit?: number) => void;
  closeRoomGuestCounter: (e: { key: string }) => void;
  roomGuestCounterVisibility: (flag: boolean | ((prevState: boolean) => boolean)) => void;
  isCounterVisible: boolean;
  counterStyle: (count: number, num: number) => boolean;
};

export function RoomGuestCounter({
  roomCount,
  setRoomcount,
  guestCount,
  setGuestcount,
  roomGuestCounter,
  closeRoomGuestCounter,
  roomGuestCounterVisibility,
  isCounterVisible,
  counterStyle,
}: Props): JSX.Element {
  const { t } = useTranslation("travel");
  const useStyles = makeStyles((theme: Theme) => ({
    dropdownLabel: {
      width: "25%",
      marginLeft: 6,
      color: "#858585",
      fontSize: 16,
    },
    dropdownCounters: {
      fontSize: 21,
      color: "#3e3e3e",
    },
  }));
  const style: any = useStyles({});

  const counterMenu = (
    <Menu onClick={closeRoomGuestCounter}>
      <Menu.Item key="1" style={{ padding: "1.5em 1em 1.15em", cursor: "initial" }}>
        <span className={style.dropdownLabel}>
          {counterStyle(roomCount, 1) ? t("room") : t("rooms")}
        </span>
        <MinusOutlined
          style={{
            fontSize: 14,
            margin: "-0.6em",
            padding: "0.4em",
            marginLeft: "2em",
            color: counterStyle(roomCount, 1) ? "#dfdfdf" : "#505050",
            cursor: counterStyle(roomCount, 1) ? "not-allowed" : "pointer",
            border: counterStyle(roomCount, 1)
              ? "1px solid #dfdfdf"
              : "1px solid #ababab",
            borderRadius: "30px",
          }}
          onClick={() => roomGuestCounter("decrement", setRoomcount)}
        />
        <span className={style.dropdownCounters}>{roomCount}</span>
        <PlusOutlined
          style={{
            fontSize: 14,
            margin: "-0.5em",
            padding: "0.5em",
            color: counterStyle(roomCount, 5) ? "#dfdfdf" : "#505050",
            cursor: counterStyle(roomCount, 5) ? "not-allowed" : "pointer",
            border: counterStyle(roomCount, 5)
              ? "1px solid #dfdfdf"
              : "1px solid #ababab",
            borderRadius: "30px",
          }}
          onClick={() => roomGuestCounter("increment", setRoomcount, 5)}
        />
      </Menu.Item>
      <Menu.Item key="2" style={{ padding: "1.15em 1em", cursor: "initial" }}>
        <span className={style.dropdownLabel}>
          {counterStyle(guestCount, 1) ? t("guest") : t("guests")}
        </span>
        <MinusOutlined
          style={{
            fontSize: 14,
            margin: "-0.6em",
            padding: "0.4em",
            marginLeft: "2em",
            color: counterStyle(guestCount, 1) ? "#dfdfdf" : "#505050",
            cursor: counterStyle(guestCount, 1) ? "not-allowed" : "pointer",
            border: counterStyle(guestCount, 1)
              ? "1px solid #dfdfdf"
              : "1px solid #ababab",
            borderRadius: "30px",
          }}
          onClick={() => roomGuestCounter("decrement", setGuestcount)}
        />
        <span className={style.dropdownCounters}>{guestCount}</span>
        <PlusOutlined
          style={{
            fontSize: 14,
            margin: "-0.5em",
            padding: "0.5em",
            color: counterStyle(guestCount, 6) ? "#dfdfdf" : "#505050",
            cursor: counterStyle(guestCount, 6) ? "not-allowed" : "pointer",
            border: counterStyle(guestCount, 6)
              ? "1px solid #dfdfdf"
              : "1px solid #ababab",
            borderRadius: "30px",
          }}
          onClick={() => roomGuestCounter("increment", setGuestcount, 6)}
        />
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">
        <Button
          key="3"
          type="primary"
          size="middle"
          style={{
            width: "100%",
            letterSpacing: "0.15em",
            textTransform: "uppercase",
            fontSize: 13,
            borderRadius: 4,
          }}
        >
          {t("done")}
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown
        overlay={counterMenu}
        trigger={["click"]}
        onVisibleChange={roomGuestCounterVisibility}
        visible={isCounterVisible}
        overlayStyle={{
          minWidth: "18.9rem",
          userSelect: "none",
        }}
      >
        <span className="dropdownItem">
          {`${roomCount} ${
            counterStyle(roomCount, 1) ? t("room") : t("rooms")
          }, ${guestCount} ${counterStyle(guestCount, 1) ? t("guest") : t("guests")}`}
        </span>
      </Dropdown>

      <style>{`
                .ant-dropdown-menu {
                    top: 15px;
                    right: 40px;
                    border-radius: 12px;
                }
                .ant-dropdown-menu-title-content {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                }
                .ant-dropdown-menu-item:hover {
                    background-color: unset;
                }
                .dropdownItem {
                    width: 100%;
                    display: inline-block;     
                    position: relative;    
                    margin-left: 10px;
                    color: #676767;
                    font-size: 16px;
                    letter-spacing: 0.35px;
                    user-select: none;
                }
            `}</style>
    </>
  );
}
