import React from "react";

import { makeStyles } from "@styles";

const useStyles = makeStyles((theme: Theme) => ({
  dividerLine: {
    border: "0",
    opacity: 0.5,
    marginLeft: 0,
    marginRight: "auto",
    height: "1px",
    width: "100%",
    backgroundColor: theme.palette.colors.border.divider,
    marginBottom: 1,
  },
}));

type Props = {
  style?: any;
};

export function DividerLine({ style }: Props) {
  const classes: any = useStyles({});
  return <hr className={classes.dividerLine} style={style}></hr>;
}
