import React from "react";
import {motion, AnimatePresence} from "framer-motion";

// Component imports
import {DialogButton, Link} from "@components";

// Misc Imports
import {useTranslation} from "@i18n";

type Props = {
  small?: boolean;
};

export function NavbarLoginButton({small}: Props) {
  const {t} = useTranslation("navbar");

  return (
    <AnimatePresence>
      <motion.div
        initial={{opacity: 0, scale: 0.8}}
        animate={{opacity: 1, scale: 1}}
        transition={{
          type: "spring",
          stiffness: 80,
          delay: 1,
          damping: 10,
          when: "beforeChildren",
        }}
      >
        <Link to={"/sign-in"}>
          <DialogButton
            width={small ? 95 : 166}
            height={small ? 36 : 48}
            fontSize={small ? 14 : 16}
            fontWeight={600}
            colorVariant="pink"
            borderRadius={24}
            hoverFade={false}
          >
            {small ? t("login") : t("loginToEarn")}
          </DialogButton>
        </Link>
      </motion.div>
    </AnimatePresence>
  );
}
