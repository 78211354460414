import { Amount } from "./amount";

interface BTCFiatAmountInterface {
  fiat?: Amount;
  btc?: Amount;
  exchangeRate?: string;
}

export class BTCFiatAmount implements BTCFiatAmountInterface {
  fiat?: Amount;
  btc?: Amount;
  exchangeRate?: string;

  constructor({ btc = null, fiat = null, exchangeRate = null } = {}) {
    if (btc && btc instanceof Amount) {
      this.btc = btc;
    } else if (btc) {
      this.btc = new Amount(btc);
    }

    if (fiat && fiat instanceof Amount) {
      this.fiat = fiat;
    } else if (fiat) {
      this.fiat = new Amount(fiat);
    }

    if (exchangeRate) {
      this.exchangeRate = Number(exchangeRate).toFixed(8);
    }
  }
}
