import React, { useCallback } from "react";

// Misc Imports
import {
  createRedirectData,
  filterUserOffers,
  ModalHelper,
  openOfferRedirect,
} from "@utils";

// Component imports
import { WhatILikeCardView } from "@components";

export function WhatILikeCardsContainer({ offers, category, order }) {
  const filterOptions: FilterData = {
    category,
    order,
  };
  const filteredUserOffers: StaticOffer[] = filterUserOffers(
    offers,
    filterOptions,
  );

  const onRedirectClicked = useCallback(async (offer: StaticOffer): Promise<
    void
  > => {
    try {
      const redirectData = await createRedirectData({ offer });
      openOfferRedirect(redirectData);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const onTermsModalClicked = useCallback(async (offer: StaticOffer): Promise<
    void
  > => {
    try {
      const redirectData = await createRedirectData({ offer });
      const termsModalProps: TermsData = {
        name: offer.advertiserName,
        terms: offer.terms,
        redirectData,
        offerId: offer.id,
        openOfferRedirect,
      };
      ModalHelper.open({ modalType: "terms", modalProps: termsModalProps });
    } catch (err) {
      console.error(err);
    }
  }, []);

  const whatILikeCards: JSX.Element[] = filteredUserOffers.map(
    (offer: StaticOffer) => (
      <WhatILikeCardView
        key={offer.id}
        offer={offer}
        onRedirectClicked={onRedirectClicked}
        onTermsModalClicked={onTermsModalClicked}
      />
    ),
  );

  return <>{whatILikeCards}</>;
}
