import { UserPoolClient } from "@apollo";
import {
  USER_POOL_GET_USER_DATA,
  USER_POOL_GET_WALLET_DATA,
  USER_POOL_GET_FAVOURITES_DATA,
  USER_POOL_GET_LINKED_CARDS_DATA,
} from "@queries";

import { User } from "@models";

export const fetchSignInData = async (
  currencyCode: string,
): Promise<User | null> => {
  let mongoUser: User;

  try {
    try {
      const {
        data: { user },
      } = await UserPoolClient.query<CurrentUser>({
        query: USER_POOL_GET_USER_DATA,
      });
      mongoUser = user;
    } catch (err) {
      throw new Error(`[USER DATA ERROR]: ${err}`);
    }

    try {
      UserPoolClient.query<SignInData>({
        query: USER_POOL_GET_WALLET_DATA,
        variables: {
          currency: currencyCode,
          convertedCurrency: currencyCode,
        },
      });
    } catch (err) {
      throw new Error(`[WALLET ERROR]: ${err}`);
    }

    try {
      UserPoolClient.query<SignInData>({
        query: USER_POOL_GET_FAVOURITES_DATA,
      });
    } catch (err) {
      throw new Error(`[FAVOURITES ERROR]: ${err}`);
    }

    try {
      UserPoolClient.query<SignInData>({
        query: USER_POOL_GET_LINKED_CARDS_DATA,
      });
    } catch (err) {
      throw new Error(`[LINKED CARDS ERROR]: ${err}`);
    }

    return mongoUser;
  } catch (err) {
    throw new Error(
      `[USER DATA FETCH SIGN IN ERROR]: Unable to fetch Mongo DB user data for user. Reason: ${err}`,
    );
  }
};
