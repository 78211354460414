import axios from "axios";

interface IPStackData {
  data: { city: string; region_name: string; country_code: string };
}

const getPublicIp = async (): Promise<string> =>
  new Promise<string>(async (resolve, reject) => {
    let attempts = 3;

    while (attempts > 0) {
      try {
        const { data: publicIp }: { data: string } = await axios.get(
          `https://api.ipify.org`,
        );

        resolve(publicIp);
        break;
      } catch (err) {
        attempts -= 1;

        if (attempts <= 0) {
          reject(`[PUBLIC IP ERROR]: ${err}`);
          break;
        }
      }
    }
  });

const getIpStackUrl = ({ publicIp, apiKey }): string =>
  `https://api.ipstack.com/${publicIp}?access_key=${apiKey}&fields=country_code,city,region_name`;

const getLocationData = async ({ url }): Promise<LocationData> =>
  new Promise<LocationData>(async (resolve, reject) => {
    let attempts = 3;

    while (attempts > 0) {
      try {
        const {
          data: { city, region_name: region, country_code: country },
        }: IPStackData = await axios.get(url);

        resolve({ city, region, country });
        break;
      } catch (err) {
        attempts -= 1;

        if (attempts <= 0) {
          reject(`[IP STACK ERROR]: ${err}`);
          break;
        }
      }
    }
  });

// Get the user's IP then get the default country code based on the IP
export const getUserCountry = async (): Promise<LocationData> => {
  try {
    const publicIp = await getPublicIp();

    const { city, country, region }: LocationData = await getLocationData({
      url: getIpStackUrl({
        publicIp,
        apiKey: process.env.GATSBY_IP_STACK_API_KEY,
      }),
    });

    return {
      city,
      region,
      country,
    };
  } catch (err) {
    throw new Error(err);
  }
};
