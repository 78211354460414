export enum OfferCategoryTypes {
  All = "ALL",
  Mens = "MENS",
  Womens = "WOMENS",
  SportsOutdoor = "SPORTS_OUTDOOR",
  HomeGarden = "HOME_GARDEN",
  Electronics = "ELECTRONICS",
  HotelTravel = "HOTEL_TRAVEL",
  BeautyWellness = "BEAUTY_WELLNESS",
  BabyKids = "BABY_KIDS",
  FoodGrocery = "FOOD_GROCERY",
  Education = "EDUCATION",
  FinanceCrypto = "FINANCE_CRYPTO",
  Luxury = "LUXURY",
  ActivitiesEvents = "ACTIVITIES_EVENTS",
}

type OfferCategory = { id: string; name: string };

export const offerCategoryTypes: OfferCategory[] = [
  {
    id: OfferCategoryTypes.All,
    name: "All Offers",
  },
  {
    id: OfferCategoryTypes.Mens,
    name: "Men's",
  },
  {
    id: OfferCategoryTypes.Womens,
    name: "Women's",
  },
  {
    id: OfferCategoryTypes.SportsOutdoor,
    name: "Sports & Outdoor",
  },
  {
    id: OfferCategoryTypes.HomeGarden,
    name: "Home & Garden",
  },
  {
    id: OfferCategoryTypes.Electronics,
    name: "Electronics",
  },
  {
    id: OfferCategoryTypes.HotelTravel,
    name: "Hotel & Travel",
  },
  {
    id: OfferCategoryTypes.BeautyWellness,
    name: "Beauty & Wellness",
  },
  {
    id: OfferCategoryTypes.BabyKids,
    name: "Baby & Kids",
  },
  {
    id: OfferCategoryTypes.FoodGrocery,
    name: "Food & Grocery",
  },
  {
    id: OfferCategoryTypes.Education,
    name: "Education",
  },
  {
    id: OfferCategoryTypes.FinanceCrypto,
    name: "Finance & Crypto",
  },
  {
    id: OfferCategoryTypes.Luxury,
    name: "Luxury",
  },
  {
    id: OfferCategoryTypes.ActivitiesEvents,
    name: "Activities & Events",
  },
];
