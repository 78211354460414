import React, { useState, useEffect } from "react";
import { withPrefix } from "gatsby";
import { Auth } from "aws-amplify";
import Helmet from "react-helmet";

import { currentUserVar, useReactiveVar } from "@apollo";
import { sendSegmentIdentifyEvent } from "helpers/analytics";
import { i18n } from "@i18n";
import { currentCountryVar } from "@apollo";
import { getUserCountry } from "@utils";

interface SmartLookInfo {
  id: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
}

export const HeadComponent = () => {
  const user = useReactiveVar(currentUserVar);
  const [smartLookInfo, setSmartLookInfo] = useState<SmartLookInfo>(undefined);

  useEffect(() => {
    let isSubscribed = true;

    (async (): Promise<void> => {
      if (user) {
        try {
          const currentUser = await Auth.currentAuthenticatedUser();
          if (!!currentUser && isSubscribed) {
            const {
              attributes: {
                ["custom:userid"]: id,
                given_name: firstName,
                family_name: lastName,
                email: emailAddress,
              },
            } = currentUser;
            setSmartLookInfo({ id, firstName, lastName, emailAddress });

            // Set the user identity for Segment
            const language = i18n.language;
            const locationData = await getUserCountry();

            sendSegmentIdentifyEvent({
              user,
              id,
              language,
              countryCode: locationData.country,
              region: locationData?.region,
              city: locationData?.city,
            });
          }
        } catch {
          setSmartLookInfo(undefined);
        }
      } else {
        setSmartLookInfo(undefined);
      }
    })();

    // prevent memory leaks
    return () => {
      isSubscribed = false;
    };
  }, [user]);

  return (
    <Helmet>
      {/* Load Fonts*/}
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        rel="preload"
        as="style"
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=block"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=block"
        rel="stylesheet"
      />
      <link
        rel="preload"
        as="style"
        href="https://fonts.googleapis.com/css2?family=Anton:wght@300;400;500;700;900&display=block"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Anton:wght@300;400;500;700;900&display=block"
        rel="stylesheet"
      />
      <link
        rel="preload"
        as="style"
        href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;900&display=block"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;900&display=block"
        rel="stylesheet"
      />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width"
      />
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="Coinmiles lets you earn Bitcoin when you shop online or in-store. Whether it’s to get a Pizza, to travel or to fashion up, there’s always a way to earn. Hundreds of top brands are available. Shop on our webapp."
      />

      {/* Open Graph information for sharing through socials */}
      <meta property="og:url" content="https://app.coinmiles.io" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Coinmiles" />
      <meta
        property="og:description"
        content="The ultimate reward app. Automatically receive Bitcoin when you shop online. Easier & more rewarding than points."
      />
      <meta property="fb:app_id" content="2327344913970225" />
      <meta
        property="og:image"
        content="http://bitcoin-miles-image-service-production.s3.ca-central-1.amazonaws.com/coinmiles-open-graph.jpg"
      />
      <meta
        property="og:image:secure_url"
        content="https://bitcoin-miles-image-service-production.s3.ca-central-1.amazonaws.com/coinmiles-open-graph.jpg"
      />
      {/* End Open Graph code */}

      {/* Smartlook initialization and setting user data tagged to a recording session */}
      <script type="text/javascript">
        {`window.smartlook||(function(d) { var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';c.charset='utf-8';c.src='https://rec.smartlook.com/recorder.js';h.appendChild(c);})(document);smartlook('init', '${process.env.GATSBY_SMARTLOOK_API_KEY}');`}
      </script>
      {smartLookInfo && (
        <script>
          {`smartlook('identify', '${smartLookInfo.id}', {
        "name": "${smartLookInfo.firstName} ${smartLookInfo.lastName}",
        "email": "${smartLookInfo.emailAddress}"
        });`}
        </script>
      )}
      {/* NOTE: DISABLED FOR NOW ... Embedding Hubspot tracking code for data tracking & chatflow implementation*/}
      {/* <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/5526598.js"
      ></script>
      {/* Add Ortto tracking codes */}
      <script type="text/javascript">
        {`window.ap3c = window.ap3c || {};var ap3c = window.ap3c;ap3c.cmd = ap3c.cmd || [];ap3c.cmd.push(function() {ap3c.init('Y5LSlnkq4ZAgypJpY29pbm1pbGVzNQ', 'https://capture-api.autopilotapp.com/');ap3c.track({v: 0});});ap3c.activity = function(act) { ap3c.act = (ap3c.act || []); ap3c.act.push(act); };var s, t; s = document.createElement('script'); s.type = 'text/javascript'; s.src = "https://cdn3l.ink/app.js";t = document.getElementsByTagName('script')[0]; t.parentNode.insertBefore(s, t);`}
      </script>
      <title>Coinmiles</title>
    </Helmet>
  );
};
