import * as React from "react";

export const LookingIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={240} viewBox="0 0 212 184" fill="none" {...props}>
    <path fill="#fff" d="M0 0h212v184H0z" />
    <path
      d="M81.552 63.397l5.13-5.563 12.795-.185 6.613 5.748 5.563-5.563 15.143.31 6.49 5.624-23.055 15.638-5.006.494-5.007-.68-18.666-15.823z"
      fill="#D1221E"
    />
    <path
      d="M87.61 93.065c8.192 0 14.833-6.641 14.833-14.834 0-8.192-6.641-14.834-14.834-14.834-8.192 0-14.834 6.642-14.834 14.834 0 8.193 6.642 14.834 14.834 14.834zM125.313 93.065c8.192 0 14.834-6.641 14.834-14.834 0-8.192-6.642-14.834-14.834-14.834-8.193 0-14.834 6.642-14.834 14.834 0 8.193 6.641 14.834 14.834 14.834z"
      fill="#FC1E12"
    />
    <path
      d="M67.336 109.383l-14.958 22.684 24.91-9.086v17.245h58.162v-16.38l30.533 9.952-20.459-25.033s-17.677-6.923-38.074-6.923-40.114 7.541-40.114 7.541z"
      fill="#E6E6E9"
    />
    <path
      d="M78.338 64.942c-2.658 2.225-4.512 4.883-5.44 8.22-1.05 4.018-.556 7.85 1.546 11.497 1.298 2.287 3.152 4.141 5.44 5.501 4.635 2.658 9.394 2.844 14.215.618 4.203-1.978 6.861-5.315 8.035-9.766.186-.618.248-1.297.433-1.977 2.534 1.36 5.007 1.421 7.541 0 .062.185.062.37.123.494.371 1.236.618 2.534 1.113 3.647 1.607 3.523 4.141 6.119 7.726 7.726 3.523 1.545 7.108 1.73 10.693.556a14.945 14.945 0 008.097-6.18c2.102-3.153 2.843-6.614 2.287-10.323-.495-3.523-2.102-6.552-4.698-8.962l-5.933-5.872c-1.854-1.792-4.327-2.843-6.923-2.843h-7.479c-2.719 0-5.377 1.174-7.231 3.152l-2.04 2.225-2.04-2.225a9.837 9.837 0 00-7.231-3.214h-6.429c-2.596 0-5.13 1.05-6.922 2.905l-4.883 4.821zm46.789 22.437a10.3 10.3 0 0010.322-10.322c0-5.625-4.573-10.446-10.693-10.322-5.253.123-9.951 4.573-9.951 10.322-.062 5.686 4.574 10.322 10.322 10.322zm-27.32-10.384c0-5.872-4.573-10.26-9.765-10.384-6.305-.185-10.817 4.821-10.817 10.322 0 5.687 4.636 10.322 10.26 10.322 5.81 0 10.323-4.45 10.323-10.26z"
      stroke="#000"
      strokeWidth={2.029}
      strokeMiterlimit={10}
    />
    <path
      d="M125.127 87.379a10.301 10.301 0 01-10.322-10.322c0-5.748 4.698-10.199 9.952-10.322 6.119-.124 10.693 4.636 10.693 10.322 0 5.686-4.636 10.322-10.323 10.322zm.68-16.194c-1.669-.433-4.265.433-5.686 2.534-1.545 2.349-1.422 5.316.742 7.48 1.916 1.915 4.821 2.595 7.602.864 1.854-1.174 3.029-3.77 2.534-5.624-1.916 1.174-3.646 1.112-4.944-.247-1.422-1.422-1.484-3.09-.248-5.007zM97.808 76.995c0 5.748-4.512 10.26-10.26 10.26-5.687 0-10.323-4.635-10.26-10.322 0-5.5 4.511-10.508 10.816-10.322 5.068.185 9.704 4.512 9.704 10.384zm-9.58-5.934c-1.855-.37-4.389.556-5.687 2.596-1.607 2.472-1.236 5.563.742 7.54 1.978 1.979 5.006 2.35 7.602.743 1.793-1.113 3.029-3.956 2.473-5.625-2.164 1.174-3.647 1.05-4.945-.247-1.298-1.36-1.36-2.843-.186-5.007zM103 69.33a3.297 3.297 0 013.214-3.522c1.731-.062 3.338 1.298 3.461 3.09.124 1.916-1.236 3.461-3.152 3.585-1.916.124-3.523-1.298-3.523-3.152z"
      fill="#fff"
    />
    <path
      d="M125.807 71.185c-1.236 1.978-1.174 3.585.248 5.007 1.298 1.36 3.09 1.421 4.944.247.495 1.854-.68 4.45-2.534 5.625-2.719 1.73-5.686 1.05-7.602-.866-2.164-2.163-2.226-5.13-.742-7.479 1.36-2.101 4.017-2.966 5.686-2.534zM88.228 71.061c-1.175 2.163-1.113 3.647.185 5.007 1.298 1.298 2.843 1.421 4.945.247.556 1.669-.68 4.512-2.473 5.625-2.596 1.669-5.686 1.236-7.602-.742-1.978-1.978-2.349-5.068-.742-7.54 1.36-2.102 3.894-2.968 5.687-2.597z"
      fill="#000"
    />
    <path
      d="M125.251 92.633c8.295 0 15.02-6.725 15.02-15.02s-6.725-15.02-15.02-15.02-15.02 6.725-15.02 15.02 6.725 15.02 15.02 15.02zM87.486 92.633c8.295 0 15.019-6.725 15.019-15.02s-6.724-15.02-15.02-15.02c-8.294 0-15.019 6.725-15.019 15.02s6.725 15.02 15.02 15.02z"
      stroke="#000"
      strokeWidth={1.95}
      strokeMiterlimit={10}
    />
    <path
      d="M133.039 90.284c3.214 4.697 9.086 13.474 12.424 18.357-.495-.185-.804-.247-1.175-.371-4.079-1.359-8.097-2.843-12.238-4.079-4.512-1.36-9.148-2.04-13.783-2.534l-5.934-.309c4.45-1.36 8.406-4.203 11.496-8.035M79.883 90.284c-3.152 4.636-9.271 13.536-12.547 18.357.37-.123.556-.185.803-.247 3.833-1.298 7.727-2.596 11.559-3.894 4.017-1.298 8.097-2.101 12.238-2.534l8.221-.495c-4.45-1.298-8.406-4.079-11.497-7.85"
      stroke="#000"
      strokeWidth={2.472}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.163 138.495v-14.896c-.37.123-.68.185-.927.309-9.21 3.09-18.357 6.243-27.566 9.333-2.473.865-4.883.742-7.17-.556-4.45-2.411-5.872-8.097-3.029-12.3 3.647-5.501 7.417-10.879 11.126-16.318 6.49-9.518 25.341-35.91 25.712-36.405M137.984 69.269c.432.556 25.836 35.664 35.849 50.374.865 1.236 1.545 2.473 1.792 3.956.866 4.945-2.348 9.148-6.799 10.013a8.92 8.92 0 01-4.759-.371 4975.144 4975.144 0 01-27.938-9.518c-.247-.062-.494-.186-.803-.248v14.958M84.334 58.823C88.474 49.428 96.695 43 106.213 43c9.395 0 17.554 6.304 21.757 15.514"
      stroke="#000"
      strokeWidth={2.472}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M179.87 24.353a4.177 4.177 0 01-4.182 4.172 4.177 4.177 0 01-4.183-4.172 4.177 4.177 0 014.183-4.172 4.177 4.177 0 014.182 4.172zM145.366 154.172a4.178 4.178 0 01-4.183 4.172 4.178 4.178 0 01-4.183-4.172 4.178 4.178 0 014.183-4.172 4.178 4.178 0 014.183 4.172zM66.355 163.335a2.09 2.09 0 01-2.092 2.086 2.088 2.088 0 01-2.091-2.086c0-1.152.936-2.086 2.09-2.086a2.09 2.09 0 012.093 2.086zM76.183 49.086a2.09 2.09 0 01-2.092 2.086A2.088 2.088 0 0172 49.086c0-1.151.936-2.086 2.091-2.086a2.09 2.09 0 012.092 2.086zM70.537 12.781a2.785 2.785 0 01-2.789 2.781 2.785 2.785 0 01-2.788-2.78A2.785 2.785 0 0167.748 10a2.785 2.785 0 012.789 2.781zM202.577 131.781a2.785 2.785 0 01-2.789 2.781 2.785 2.785 0 01-2.788-2.781 2.785 2.785 0 012.788-2.781 2.785 2.785 0 012.789 2.781zM23.971 54.477a3.48 3.48 0 01-3.485 3.476A3.481 3.481 0 0117 54.477C17 52.557 18.56 51 20.486 51a3.48 3.48 0 013.485 3.477zM172.1 165.672a3.48 3.48 0 01-3.485 3.476 3.481 3.481 0 01-3.486-3.476 3.481 3.481 0 013.486-3.477 3.481 3.481 0 013.485 3.477zM169.642 77.767l-5.433 3.129-3.138-5.42 5.434-3.128 3.137 5.419zM93.291 24.119l-4.034 2.323-2.33-4.024 4.035-2.323 2.33 4.024zM57.102 61.804l-1.809 4.282L51 64.282 52.809 60l4.293 1.804zM176.102 136.804l-1.809 4.282-4.293-1.804 1.809-4.282 4.293 1.804z"
      stroke="#999BA6"
      strokeWidth={0.58}
    />
    <path
      d="M132 17l2.092 7.648M47.532 25.992l-3.267 4.33M30.267 94L27 98.33M99.335 160.384l-5.093 1.888M62.104 140.857L57 139"
      stroke="#999BA6"
      strokeWidth={0.58}
    />
    <path
      clipRule="evenodd"
      d="M190.137 94.694L187 91.564 191.706 90l-1.569 4.694zM27.963 26.476l-4.27 1.204.928-4.86 3.342 3.656zM37.27 164.656L33 165.86l.928-4.86 3.341 3.656zM165.34 48.182l-4.269 1.204.928-4.86 3.341 3.656zM197.269 53.656L193 54.86l.928-4.86 3.341 3.656zM20.27 122.656L16 123.86l.928-4.86 3.341 3.656z"
      stroke="#999BA6"
      strokeWidth={0.58}
    />
  </svg>
);
