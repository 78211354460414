import React from "react";
import {makeStyles} from "@styles";

const useStyles = makeStyles(() => ({
  listCardRowContainer: {
    maxWidth: 1400,
  },
}));

type Props = {
  children: JSX.Element[];
  style: any;
};

export function ListCardRowContainer({children, style}: Props) {
  const classes: any = useStyles({});
  return (
    <div style={style} className={classes.listCardRowContainer}>
      {children}
    </div>
  );
}
