import React from "react";

// Misc Imports
import { Assets } from "@assets";
import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";

// Component imports
import { DialogButton } from "@components";
import { Grid, Paper, Typography } from "@material";
import { CloseIcon } from "@icons";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: "relative",
    zIndex: 1302,
    borderRadius: 12,
    padding: 16,
    maxWidth: 800,
    width: 400,
    height: "auto",
    outline: "none !important",
    [theme.breakpoints.down("xs")]: {
      margin: "auto 16px auto 16px",
      height: "auto",
    },
  },
  giftboxIcon: { marginTop: 8 },
  cancelIconContainer: {
    position: "absolute",
    top: 12,
    right: 16,
    zIndex: 5,
  },
  cancelIcon: {
    cursor: "pointer",
  },
  textAndButtonsContainer: {
    padding: 32,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
    },
  },
  textContainer: { textAlign: "center", width: 275 },
  headerText: {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 18,
    [theme.breakpoints.down("xs")]: {
      fontSize: 21,
    },
  },
  dialogText: {
    fontSize: 17,
    fontWeight: 400,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
  buttonContainer: { marginTop: 24 },
}));

type Props = {
  onAccept: () => void;
  onDeny: () => void;
};

export function EmailOptInModal({ onAccept, onDeny }: Props) {
  const classes: any = useStyles({});
  const { t } = useTranslation("onlineScreen");

  return (
    <>
      <Paper className={classes.paper}>
        <Grid
          item
          className={classes.cancelIconContainer}
          style={{ position: "absolute", alignItems: "center" }}
        >
          <CloseIcon
            className={classes.cancelIcon}
            width={13}
            fill={"black"}
            onClick={onDeny}
          />
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.textAndButtonsContainer}
        >
          <Grid item className={classes.giftboxIcon}>
            <img
              style={{
                height: 200,
                width: 200,
              }}
              src={Assets.GIFT_BOX}
              alt="email-opt-in"
            />
          </Grid>
          <Grid className={classes.textContainer}>
            <Typography className={classes.headerText}>
              {t("emailOptInModal.title")}
            </Typography>
            <Typography className={classes.dialogText}>
              {t("emailOptInModal.content")}
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Grid className={classes.buttonContainer}>
              <DialogButton
                colorVariant="pink"
                width={250}
                height={60}
                onClick={onAccept}
              >
                {t("emailOptInModal.yesButton")}
              </DialogButton>
            </Grid>
            <Grid className={classes.buttonContainer}>
              <DialogButton
                colorVariant="white"
                width={250}
                height={60}
                onClick={onDeny}
              >
                {t("emailOptInModal.noButton")}
              </DialogButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
