import React from "react";

// Component imports
import { Link } from "@components";
import { Grid } from "@material";

// Misc Imports

import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";

const useStyles = makeStyles((theme: Theme) => ({
  headerButtonContainer: {
    marginTop: 7,
    marginBottom: 7,
  },
  menuButton: {
    display: "flex",
    alignItems: "center",
    width: "auto",
    margin: "16px 31px 18px 31px",
    color: "#1F1F30",
    height: 24,
  },
  menuButtonText: {
    fontSize: 16,
    fontWeight: 500,
    height: 24,
    transitionProperty: "color",
    transitionDuration: "0.4s",
    transitionTimingFunction: "ease-out",
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.main,
      transitionProperty: "color",
      transitionDuration: "0.3s",
    },
  },
  localeCountainer: {
    display: "flex",
    alignItems: "center",
    width: "auto",
  },
  countryText: {
    paddingTop: 2,
    fontWeight: 600,
    marginLeft: 8,
    marginRight: 24,
  },
}));

type Props = {
  loggedIn: boolean;
};

export function NavbarButtons({ loggedIn }: Props) {
  const classes: any = useStyles({});
  const { t } = useTranslation("navbar");

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.headerButtonContainer}
      md
      lg
    >
      <Link
        to="/"
        className={classes.menuButton}
        activeStyle={{ color: "#FF3451", transition: "color 0.5s" }}
      >
        <span className={classes.menuButtonText}>{t("shopOnline")}</span>
      </Link>
      <Link
        to="/travel"
        className={classes.menuButton}
        activeStyle={{ color: "#FF3451", transition: "color 0.5s" }}
      >
        <span className={classes.menuButtonText}>{t("travel")}</span>
      </Link>
      <Link
        to="/wallet"
        className={classes.menuButton}
        activeStyle={{ color: "#FF3451", transition: "color 0.5s" }}
      >
        <span className={classes.menuButtonText}>{t("myWallet")}</span>
      </Link>
      {/* <Link
        to="/gift-cards"
        className={classes.menuButton}
        activeStyle={{ color: "#FF3451", transition: "color 0.5s" }}
      >
        <span className={classes.menuButtonText}>{t("giftCards")}</span>
      </Link> */}
      {/* {loggedIn && (
        <Link
          to="/what-i-like"
          className={classes.menuButton}
          activeStyle={{ color: "#FF3451", transition: "color 0.5s" }}
        >
          <span className={classes.menuButtonText}>{t("whatILike")}</span>
        </Link>
      )} */}
    </Grid>
  );
}
