import React, { useState, MouseEvent } from "react";

// Misc Imports

import { useTranslation } from "@i18n";
import { makeStyles, withStyles } from "@styles";

// Component imports
import { DialogButton, LoadingSpinner } from "@components";
import { Grid, Paper, ToggleButton, ToggleButtonGroup, Typography } from "@material";

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: 336,
    height: "auto",
    padding: "16px 24px 16px 24px",
    backgroundColor: "white",
    borderRadius: 4,
    outline: "none !important",
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      width: 276,
      height: 311,
      padding: 18,
    },
  },
  buttonHeader: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 16,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  categoryButton: {
    height: 43,
    padding: 8,
    fontSize: 14,
    fontWeight: 400,
    textAlign: "center",
    border: "1px solid #efefef",
    textTransform: "none",
    width: 240,
    [theme.breakpoints.down("xs")]: {
      width: 180,
    },
  },
  applyButton: {
    marginTop: 16,
    marginBottom: 8,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));

const StyledButton = withStyles({
  root: {
    marginBottom: 8,
    backgroundColor: "white",
    color: "black",
    borderRadius: "5px !important",
    transitionProperty: "background-color",
    transitionDuration: "0.3s",
    "&:hover": {
      backgroundColor: "#F4F4F4 !important",
      transitionProperty: "background-color",
      transitionDuration: "0.3s",
    },
  },
  label: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  selected: {
    backgroundColor: "black !important",
    color: "white !important",
    "&:hover": {
      backgroundColor: "black !important",
      color: "white !important",
    },
  },
})(ToggleButton);

interface LocaleSelectionOption {
  id: string;
  name: string;
  flag: string;
}

type Props = {
  currentLocaleId: string;
  handleModalSubmit: (localeId: string) => Promise<void>;
};

export function LocaleSelectionModal({ currentLocaleId, handleModalSubmit }: Props) {
  const classes: any = useStyles({});
  const { t } = useTranslation("common");

  const [modalLocaleId, setModalLocaleId] = useState<string>(currentLocaleId);

  const [loading, setLoading] = useState<boolean>(false);

  const assignLocale = (
    _event: MouseEvent<HTMLElement>,
    newModalLocaleId: string,
  ): void => {
    newModalLocaleId !== null && setModalLocaleId(newModalLocaleId);
  };

  const setLoadingAndModalSubmit = (): void => {
    setLoading(true);
    handleModalSubmit(modalLocaleId);
  };

  const modalLocaleSelectionOptions: LocaleSelectionOption[] = [
    {
      id: "CA-en",
      name: "Canada - English",
      flag:
        "https://coinmiles-affiliate-offers-production.s3.ca-central-1.amazonaws.com/flags/CA.png",
    },
    {
      id: "CA-fr",
      name: "Canada - Français",
      flag:
        "https://coinmiles-affiliate-offers-production.s3.ca-central-1.amazonaws.com/flags/CA.png",
    },
    {
      id: "US-en",
      name: "USA - English",
      flag:
        "https://coinmiles-affiliate-offers-production.s3.ca-central-1.amazonaws.com/flags/US.png",
    },
    {
      id: "FR-fr",
      name: "France - Français",
      flag:
        "https://coinmiles-affiliate-offers-production.s3.ca-central-1.amazonaws.com/flags/FR.png",
    },
    {
      id: "BR-pt",
      name: "Brazil - Portuguese",
      flag:
        "https://coinmiles-affiliate-offers-production.s3.ca-central-1.amazonaws.com/flags/BR.png",
    },
  ];

  return (
    <Paper className={classes.paper}>
      <Grid container item direction="column" justifyContent="center" alignItems="center">
        <Grid item>
          <Typography className={classes.buttonHeader}>{t("selectLocale")}</Typography>
        </Grid>
        <ToggleButtonGroup
          value={modalLocaleId}
          exclusive
          onChange={assignLocale}
          className={classes.buttonContainer}
        >
          {modalLocaleSelectionOptions.map(
            (localeOption: LocaleSelectionOption, index: number) => (
              <StyledButton
                key={index}
                value={localeOption.id}
                disableRipple={true}
                className={classes.categoryButton}
              >
                <Grid container item justifyContent="flex-start" alignItems="center">
                  <img
                    src={localeOption.flag}
                    width={30}
                    height={"auto"}
                    style={{ marginRight: 10 }}
                    alt={`${localeOption.id}-flag`}
                  />
                  {localeOption.name}
                </Grid>
              </StyledButton>
            ),
          )}
        </ToggleButtonGroup>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid className={classes.applyButton}>
            <DialogButton
              colorVariant="pink"
              width={133}
              height={36}
              padding={16}
              fontSize={14}
              onClick={setLoadingAndModalSubmit}
            >
              {loading ? <LoadingSpinner size={24} color="white" /> : t("select")}
            </DialogButton>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
