import React from "react";

import {
  currentCountryVar,
  currentUserVar,
  useReactiveVar,
  UserPoolClient,
} from "@apollo";
import { USER_POOL_GET_WALLET_DATA } from "@queries";
import { useUpdateUser } from "@hooks";

// Misc Imports
import { Wallet } from "@models";
import { getAffiliateServiceArea, isServerSideRendering, ModalHelper } from "@utils";

// Component imports
import { NavbarLocaleSelectionButton } from "./navbar-locale-selection-button";

export function NavbarLocaleSelectionContainer() {
  if (isServerSideRendering()) return null;

  const country = useReactiveVar(currentCountryVar);
  const user = useReactiveVar(currentUserVar);
  const { updateUserLanguageCountry } = useUpdateUser();

  const localStorageLanguage = localStorage.getItem("i18nextLng");

  const handleModalSubmit = async (localeId: string): Promise<void> => {
    const countryCode = localeId.split("-")[0];
    const languageCode = localeId.split("-")[1];

    const previousCountry = localStorage.getItem("userCountry");
    const previousLanguage = localStorage.getItem("i18nextLng");

    const changeLanguage = !!(languageCode !== previousLanguage);
    const changeCountry = !!(countryCode !== previousCountry);

    // No change
    if (!changeLanguage && !changeCountry) {
      ModalHelper.close();
      return;

      // Change country but not language
    } else if (!changeLanguage && changeCountry) {
      const newCountry: AffiliateServiceArea = getAffiliateServiceArea(countryCode);

      if (user) {
        await UserPoolClient.query<{ wallet: Wallet }>({
          query: USER_POOL_GET_WALLET_DATA,
          variables: {
            id: user?.id,
            currency: newCountry?.currencyCode,
            convertedCurrency: country?.currencyCode,
          },
          fetchPolicy: "network-only",
        });
      }
      await updateUserLanguageCountry({ languageCode: null, countryCode });

      localStorage.setItem("userCountry", countryCode);
      currentCountryVar(newCountry);
      ModalHelper.close();

      // Change language but not country
    } else if (changeLanguage && !changeCountry) {
      localStorage.setItem("i18nextLng", languageCode);

      await updateUserLanguageCountry({ languageCode, countryCode: null });

      ModalHelper.close();

      // DEV NOTE - This is a hack to force a refresh of the page after the language change. Worth considering better solutions.
      setTimeout(() => {
        window.location.reload();
      }, 1000);

      // Change both language and country
    } else if (changeLanguage && changeCountry) {
      // Refetch of Wallet handled by initializeAppStartup function after app refresh
      localStorage.setItem("userCountrySavedBeforeRefresh", countryCode);
      localStorage.setItem("i18nextLng", languageCode);

      await updateUserLanguageCountry({ languageCode, countryCode });

      ModalHelper.close();

      // DEV NOTE - This is a hack to force a refresh of the page after the language change. Worth considering better solutions.
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const localeSelectionModalOpen = (): void => {
    const currentLocaleId = `${
      country.countryCode
    }-${localStorageLanguage.toLowerCase()}`;

    const localeSelectionModalProps: any = {
      currentLocaleId,
      handleModalSubmit,
    };

    ModalHelper.open({
      modalType: "localeSelection",
      modalProps: localeSelectionModalProps,
    });
  };

  return (
    <NavbarLocaleSelectionButton
      currentCountry={country}
      onClick={localeSelectionModalOpen}
    />
  );
}
