import * as React from "react";
import { Assets } from "@assets";
import { Grid } from "@material";
import { CustomImage } from "@components";
import { Form, Button, DatePicker, ConfigProvider } from "antd";
import { useStyles } from "./styles";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { RoomGuestCounter } from "./room-guest-counter";

export const TravelScreenTemplate = ({
  t,
  searchForm,
  geocoderRef,
  onSubmitSearch,
  calendarLocale,
  disabledDates,
  setDates,
  onCalendarDatesChange,
  roomGuestProps,
  isCounterVisible,
}): JSX.Element => {
  const styles = useStyles();
  return (
    <>
      <div className={styles.mainContainer}>
        <Grid container item xs={12} className={styles.mainGrid}>
          <Grid item sm={12} md className={styles.leftGrid}>
            <div className={`${styles.textContainer} ${styles.column}`}>
              <div>
                <label className={`${styles.centerContent} ${styles.contentTitleOne}`}>
                  {t("pageMessage.one")}
                </label>
                <label className={`${styles.centerContent} ${styles.contentTitleTwo}`}>
                  {t("pageMessage.two")}
                </label>
              </div>
            </div>
          </Grid>

          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            sm={12}
            md
          >
            <div className={`${styles.column} ${styles.centerContent}`}>
              <div className={styles.cardContainer}>
                <Form
                  size="large"
                  form={searchForm}
                  onFinish={onSubmitSearch}
                  style={{ marginTop: 20, width: "100%" }}
                >
                  <Form.Item>
                    <div className="geocoderCtn" ref={geocoderRef}></div>
                  </Form.Item>
                  <Form.Item name="datePicker">
                    <ConfigProvider locale={calendarLocale}>
                      <DatePicker.RangePicker
                        placeholder={[t("checkIn"), t("checkOut")]}
                        format={"YYYY-MMM-DD"}
                        suffixIcon={false}
                        allowClear={false}
                        disabledDate={disabledDates}
                        onCalendarChange={(value): void => {
                          setDates(value);
                        }}
                        onChange={onCalendarDatesChange}
                      />
                    </ConfigProvider>
                  </Form.Item>
                  <Form.Item name="guest_room">
                    <div className="dropdownCtn">
                      <CustomImage
                        src={Assets.USER_ICON}
                        style={{
                          width: 16,
                          height: 16,
                          marginLeft: 5,
                        }}
                      />
                      <RoomGuestCounter {...roomGuestProps} />
                      <CustomImage
                        src={isCounterVisible ? Assets.ARROW_UP : Assets.ARROW_DOWN}
                        style={{
                          width: 18,
                          height: 18,
                          marginRight: "0.5em",
                        }}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      size="large"
                      style={{
                        height: "48px",
                        width: "100%",
                        letterSpacing: "0.1em",
                        borderRadius: 5,
                        fontSize: 17,
                      }}
                      type="primary"
                      danger
                    >
                      {t("btnSearch")}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <style>{`
                .mapboxgl-ctrl-geocoder {
                    min-width: 100%;
                    box-shadow: none;
                }
                .mapboxgl-ctrl-geocoder--input {
                    min-height: 38px;
                    padding-left: 38px;
                    font-size: 16px;
                    letter-spacing: 0.5px;
                }
                .mapboxgl-ctrl-geocoder--input:focus {
                    outline: none;
                }
                .mapboxgl-ctrl-geocoder--icon {
                    margin-left: 5px;
                }
                .geocoderCtn {
                    height: 48px;
                    width: 100%;
                    display: inline-flex;
                    align-items: center;
                    border: 1px solid #e1e1e1;
                    border-radius: 2px;
                    transition: border 0.3s, box-shadow 0.3s;
                }
                .geocoderCtn:hover {
                    border-color: #40a9ff;
                }
                .ant-form-item {
                    margin-bottom: 16px;
                }
                .ant-form-item-control-input {
                    width: 100%;
                    align-self: center;
                }
                .ant-menu-submenu-title {
                    padding-left: 10px !important;
                    border: 1px solid #d9d9d9;
                    border-radius: 2px;
                    transition: border 0.3s, box-shadow 0.3s;
                    color: #bfbfbf;
                }
                .ant-picker {
                    height: 48px;
                    width: 100%;
                    text-align-last: center;
                }
                .ant-picker-input input::placeholder {
                    color: #757575;
                }
                @media(max-width: 576px) { 
                    .ant-picker-panels { 
                        flex-direction: column;
                    }
                }
                .dropdownCtn {
                    height: 48px;
                    width: 100%;
                    display: inline-flex;
                    align-items: center;
                    padding: 6px 11px 6px;
                    border: 1px solid #d9d9d9;
                    border-radius: 2px;
                    transition: border 0.3s, box-shadow 0.3s;
                    cursor: pointer;
                }
                .dropdownCtn:hover {
                    border-color: #40a9ff;
                }
                span {
                    vertical-align: middle;
                }
            `}</style>
    </>
  );
};
