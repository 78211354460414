export const en = {
  signUp: "Sign up to continue",
  firstName: "First name",
  lastName: "Last name",
  emailAddress: "Email address",
  password: "Password",
  passwordConfirmation: "Password Confirmation",
  signUpWithEmail: "Continue with email",
  signUpDisclaimer: {
    one: "By signing up, I agree to Coinmiles'",
    two: " Terms of Service",
    three: " and",
    four: " Privacy Policy",
    five: ". Coinmiles is only available in Canada at this time.",
  },
  alreadyMember: "Already a member?",
  modal: {
    earnAt: "Shop at",
    weNeedToKnow: {
      one: "We need to know who should get rewarded!",
      two: "Good news: when you create an account, ",
      three: "we'll automatically put ",
      four: " Sats in your wallet.",
    },
  },
  drawer: {
    letsCheckOut: "Let's check out the deals",
    aCoolGift: "A cool gift for you",
    loginToViewDeals: {
      one: "Log in to view the offers. It's ",
      two: "fast, free ",
      three: "and we give you ",
      four: " Sats as a welcome bonus.",
    },
  },
  landingPage: {
    header: {
      verified1: "THANKS",
      verified2: "FOR",
      verified3: "JOINING",
      verified4: "COINMILES!",
      expiredCode: "Invalid link",
      invalidCode: "Invalid code",
      error: "An error occurred",
    },
    verified: "Your account is now confirmed. You may now sign in with email ",
    expiredCode:
      "Your confirmation link is invalid or expired. Have you already confirmed you account? If not, click the button below to contact support.",
    invalidCode:
      "The confirmation code you have provided is invalid. Click the button below to contact support",
    error: "An error occurred with your confirmation, please try again.",
    button: {
      verified: "Go to sign in",
      expiredCode: "Contact Support",
      invalidCode: "Contact Support",
      backToHome: "Back to Home",
      openWithApp: "Go back to the app",
      openWithWebsite: "Sign In",
    },
  },
  success: {
    thanks: "Thanks for joining Coinmiles!",
    confirmation: {
      one: "We have sent a confirmation email to ",
      two: "Please confirm your account to begin using Coinmiles.",
    },
    didntReceive: "Didn't receive an email?",
    sendAgain: " Send again",
    ok: "OK",
    confirmAndContinue: "Sounds Good",
    confirmationResent: "Confirmation resent",
  },
};
