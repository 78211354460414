import React from "react";
import { Auth } from "aws-amplify";
import { DialogButton } from "@components";
import { Grid, Paper, Typography } from "@material";

import { Assets } from "@assets";
import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";
import { ModalHelper } from "@utils";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    outline: "none",
    padding: 32,
    [theme.breakpoints.down("xs")]: {
      width: 320,
      padding: 16,
      height: "auto",
      margin: "auto 16px auto 16px",
    },
  },
  dialog: {
    height: "auto",
    width: 480,
    borderRadius: 8,
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      width: "100%",
    },
  },
  mailIcon: { marginTop: 8 },
  signInText: {
    fontSize: 30,
    fontWeight: 600,
    textAlign: "center",
    marginTop: 22,
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  dialogText: {
    fontSize: 20,
    fontWeight: 400,
    textAlign: "center",
    marginTop: 11,
    marginBottom: 16,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  menuButton: {
    marginBottom: 8,
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
}));

type Props = {
  emailAddress: string;
  navigateToHome: () => void;
  resendButton: boolean;
};

export function SignUpSuccessModal({
  emailAddress,
  navigateToHome,
  resendButton,
}: Props) {
  const classes: any = useStyles({});
  const { t } = useTranslation("signUp");

  const onButtonClickResend = (): void => {
    handleResend();
  };

  const onButtonClickOk = (): void => {
    closeAndNavtoHome();
  };

  const closeAndNavtoHome = (): void => {
    navigateToHome();
    ModalHelper.close();
  };

  const handleResend = async (): Promise<void> => {
    try {
      await Auth.resendSignUp(emailAddress);
      closeAndNavtoHome();
    } catch (_err) {
      //
    }
  };

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        className={classes.dialog}
      >
        <Grid item className={classes.mailIcon}>
          <img
            style={{ height: "100%", width: "100%" }}
            src={Assets.MAIL_ICON}
            alt="mail-icon"
          />
        </Grid>
        <Grid container item justifyContent="center">
          <Typography className={classes.signInText}>
            {resendButton ? t("success.thanks") : t("success.confirmationResent")}
          </Typography>
        </Grid>
        <Grid container item justifyContent="center">
          <Typography className={classes.dialogText}>
            {`${t("success.confirmation.one")} ${emailAddress}. ${t(
              "success.confirmation.two",
            )}`}
          </Typography>
        </Grid>
        <Grid className={classes.menuButton}>
          <DialogButton width={"100%"} colorVariant="pink" onClick={onButtonClickOk}>
            {t("success.confirmAndContinue")}
          </DialogButton>
        </Grid>
        {resendButton && (
          <Grid
            container
            item
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography className={classes.dialogText}>
              {t("success.didntReceive")}
            </Typography>
            <Grid className={classes.menuButton}>
              <DialogButton
                width={"100%"}
                colorVariant="white"
                onClick={onButtonClickResend}
              >
                {t("success.sendAgain")}
              </DialogButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
