export const pt = {
  pageMessage: {
    one: "Reserve um hotel.",
    two: "Até 25% cashback\n em Bitcoin.",
  },
  inputDestination: "Seu destino",
  emptyDestination: "Insira um destino",
  checkIn: "Check in",
  checkOut: "Check out",
  room: "quarto",
  rooms: "quartos",
  guest: "hóspede",
  guests: "hóspedes",
  done: "pronto",
  btnSearch: "pesquisar",
};
