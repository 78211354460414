import { currentUserVar, useMutation, useReactiveVar, UserPoolClient } from "@apollo";
import { MUTATION_UPDATE_USER } from "@queries";

import { User } from "@models";
import { getUserMetadata } from "@utils";
interface UpdateUserAttributes {
  didLogin?: boolean;
  metadata?: UserMetadata;
  profilePhotoUrl?: string;
  emailOptIn?: boolean;
}
interface UpdateUserLanguageCountryParams {
  languageCode: string;
  countryCode: string;
}

type Params = {
  onCompleted?: () => void;
  onError?: (err) => void;
};

type ReturnedFunctions = {
  updateUser: (params: UpdateUserAttributes) => void;
  updateUserMetadata: (user: User) => Promise<void>;
  updateUserLanguageCountry: ({ languageCode, countryCode }) => void;
};

export const useUpdateUser = ({
  onCompleted,
  onError,
}: Params = {}): ReturnedFunctions => {
  const user = useReactiveVar(currentUserVar);

  const [updateUserMutation] = useMutation<CurrentUser>(MUTATION_UPDATE_USER, {
    client: UserPoolClient,
    onCompleted: ({ user }) => {
      onCompleted && onCompleted();
      currentUserVar(user);
    },
    onError: (err) => {
      onError && onError(err);
    },
  });

  const updateUser = ({
    didLogin,
    metadata,
    profilePhotoUrl,
    emailOptIn,
  }: UpdateUserAttributes) => {
    const updatedUser = {
      didLogin,
      metadata,
      emailOptIn,
      profilePhotoUrl: profilePhotoUrl === null ? "" : profilePhotoUrl,
    };

    updateUserMutation({ variables: { ...updatedUser } });
  };

  const updateUserMetadata = async (user: User): Promise<void> => {
    try {
      const metadata = await getUserMetadata();

      // if the country & language are empty then update them with the automatic (IP-based) values
      if (user.metadata?.country) {
        metadata.country = user.metadata.country;
      }
      if (user.metadata?.language) {
        metadata.language = user.metadata.language;
      }

      updateUserMutation({ variables: { metadata } });
    } catch (error) {
      console.error("Could not update metadata: ", error);
    }
  };

  const updateUserLanguageCountry = async ({
    languageCode,
    countryCode,
  }: UpdateUserLanguageCountryParams): Promise<void> => {
    try {
      const metadata = await getUserMetadata();

      if (languageCode) {
        metadata.language = languageCode;
      } else {
        metadata.language = localStorage.getItem("i18nextLng") ?? metadata.language;
      }

      if (countryCode) {
        metadata.country = countryCode;
      } else {
        metadata.country = localStorage.getItem("userCountry") ?? metadata.country;
      }

      updateUserMutation({ variables: { metadata } });
    } catch (error) {
      console.error("Could not update language and/or country: ", error);
    }
  };

  return {
    updateUser,
    updateUserMetadata,
    updateUserLanguageCountry,
  };
};
