import React, { MouseEvent } from "react";

// Component imports
import { GridIcon, ListIcon } from "@icons";

// Misc Imports
import { useTranslation } from "@i18n";
import { makeStyles, withStyles } from "@styles";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    outline: "none",
    cursor: "pointer",
    backgroundColor: theme.palette.white,
    color: theme.palette.black,
    borderRadius: "4px !important",
    transitionProperty: "background-color",
    transitionDuration: "0.3s",
    padding: 0,
    height: 42,
    width: 42,
    minWidth: 42,
    border: `1px solid ${theme.palette.colors.border.light}`,
    marginLeft: 8,
    "&:hover": {
      backgroundColor: `${theme.palette.colors.background.lightGrey} !important`,
      transitionProperty: "background-color",
      transitionDuration: "0.3s",
    },
  },
  mobileButton: {
    outline: "none",
    cursor: "pointer",
    backgroundColor: theme.palette.white,
    color: theme.palette.black,
    borderRadius: "4px !important",
    transitionProperty: "background-color",
    transitionDuration: "0.3s",
    padding: 0,
    height: 42,
    width: 42,
    minWidth: 42,
    border: `1px solid ${theme.palette.colors.border.light}`,
  },
  buttonSelected: {
    backgroundColor: theme.palette.black,
    color: theme.palette.white,
    borderRadius: "4px !important",
    transitionProperty: "background-color",
    transitionDuration: "0.3s",
    padding: 0,
    height: 42,
    width: 42,
    minWidth: 42,
    border: `1px solid ${theme.palette.colors.border.light}`,
    marginLeft: 8,
    "&:hover": {
      backgroundColor: theme.palette.black,
      color: theme.palette.white,
    },
  },
}));

type Props = {
  type: "mobile" | "desktop";
  display: string;
  setFilters: any;
};

export function DisplayToggle({ type, display, setFilters }: Props) {
  const classes: any = useStyles({});
  const { t } = useTranslation(["onlineScreen", "common"]);

  const handleDisplaySelectDesktop = (
    event: MouseEvent<HTMLButtonElement>,
  ): void => {
    const { value } = event.currentTarget as HTMLButtonElement;
    setFilters((prevState) => ({ ...prevState, display: value }));
  };

  const handleDisplaySelectMobile = (): void => {
    if (display === "Cards") {
      setFilters((prevState) => ({ ...prevState, display: "List" }));
    } else if (display === "List") {
      setFilters((prevState) => ({ ...prevState, display: "Cards" }));
    }
  };

  const displayOptions: FilterOption[] = [
    { id: "Cards", name: "Cards", icon: GridIcon },
    { id: "List", name: "List", icon: ListIcon },
  ];

  const displayOptionsTranslated: FilterOption[] = displayOptions.map(
    (display: FilterOption) => {
      return {
        id: display.id,
        name: t(`filtersHeader.displayOptions.${display.id}`),
        icon: display.icon,
      };
    },
  );

  const DisplayToggleMobile = (): JSX.Element => (
    <button
      onClick={handleDisplaySelectMobile}
      className={classes.mobileButton}
    >
      {display === "Cards" ? (
        <ListIcon fill={"#000000"} width={24} />
      ) : (
        <GridIcon fill={"#000000"} width={24} />
      )}
    </button>
  );

  const DisplayToggleDesktop = (): JSX.Element => (
    <div>
      {displayOptionsTranslated.map(
        (displayOption: FilterOption, index: number) => (
          <button
            key={index + 1}
            value={displayOption.id}
            onClick={handleDisplaySelectDesktop}
            className={`${
              display !== displayOption.id
                ? classes.button
                : classes.buttonSelected
            }`}
          >
            <displayOption.icon
              fill={display === displayOption.id ? "#FFFFFF" : "#000000"}
              width="60%"
            />
          </button>
        ),
      )}
    </div>
  );

  return type === "mobile" ? <DisplayToggleMobile /> : <DisplayToggleDesktop />;
}
