import React from "react";

// Component imports
import { DialogButton, ProfileLinkedCard } from "@components";
import { Paper, Grid, Typography } from "@material";

// DEV NOTE ---> Move to own folder once implemented
// import {AddCardModal} from "./add-card-modal";

// Misc Imports

import { Assets } from "@assets";
import { useTranslation } from "@i18n";
import { LinkedCard } from "@models";
import { makeStyles } from "@styles";
import { ModalHelper } from "@utils";

const useStyles = makeStyles((theme: Theme) => ({
  profileCards: {
    height: "auto",
    width: "100%",
    backgroundColor: "white",
    border: `1px solid #EBEBEB`,
    padding: 24,
    minHeight: 510,
  },
  transactionHeader: {
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end",
    },
  },
  dividerLine: {
    border: "0",
    opacity: 0.5,
    marginLeft: 0,
    marginRight: "auto",
    height: "1px",
    width: "100%",
    backgroundColor: "#DEDEDE",
  },
  linkedCardsHeader: {
    fontSize: 24,
    fontWeight: 500,
  },
  cardsContainer: {},
  noLinkedCardsContent: {
    marginTop: 64,
  },
  creditCardIcon: {
    marginTop: 14,
    marginBottom: 27,
    width: 110,
  },
  noLinkedCardsText: {
    fontSize: 16,
    color: "#000000",
    textAlign: "center",
  },
  linkAnotherCardText: {
    fontSize: 16,
    color: "#000000",
    textAlign: "left",
    marginBottom: 10,
  },
  linkCardButton: {
    height: 47,
    width: "auto",
  },
}));

type Props = {
  linkedCards: LinkedCard[];
};

export function ProfileLinkedCardsDisplay({ linkedCards }: Props) {
  const classes: any = useStyles({});
  const { t } = useTranslation("profile");

  const handleOpenAppDownloadModal = (): void => {
    ModalHelper.open({
      modalType: "appDownload",
      modalProps: {
        width: 540,
        messageStrings: [[t("linkedCardDisplay.dialogMessageString")]],
      },
    });
  };

  const linkedCardsDisplay: JSX.Element[] = linkedCards?.length
    ? linkedCards.map((card: LinkedCard, index: number) => {
        return (
          <div key={index}>
            <ProfileLinkedCard card={card} />
            <hr className={classes.dividerLine}></hr>
          </div>
        );
      })
    : undefined;

  return (
    <Paper className={classes.profileCards}>
      <Grid container direction="column">
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          lg={12}
          className={classes.transactionHeader}
        >
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {" "}
            <Typography className={classes.linkedCardsHeader}>
              {t("linkedCardDisplay.myLinkedCards")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            item
            direction="column"
            justifyContent={linkedCardsDisplay ? "flex-start" : "center"}
            alignItems={linkedCardsDisplay ? "stretch" : "center"}
            lg={12}
            className={classes.cardsContainer}
          >
            {linkedCardsDisplay ? (
              <Grid container direction="column" justifyContent="space-between">
                <div style={{ width: "100%" }}>{linkedCardsDisplay}</div>
                <Grid
                  container
                  item
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  xs={12}
                  style={{ marginTop: 20 }}
                >
                  <Grid item>
                    <Typography className={classes.linkAnotherCardText}>
                      {t("linkedCardDisplay.linkAnotherCard")}
                    </Typography>
                  </Grid>
                  <Grid
                    className={classes.linkCardButton}
                    style={{ marginTop: "auto", marginBottom: "auto" }}
                  >
                    <DialogButton
                      colorVariant="black"
                      width={"auto"}
                      height={"100%"}
                      borderRadius={0}
                      padding={12}
                      onClick={handleOpenAppDownloadModal}
                    >
                      {t("linkedCardDisplay.linkCardButton")}
                    </DialogButton>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="center"
                  className={classes.noLinkedCardsContent}
                >
                  <img
                    src={Assets.CREDIT_CARD}
                    className={classes.creditCardIcon}
                    alt="credit-card-icon"
                  />
                </Grid>
                <Typography className={classes.noLinkedCardsText}>
                  {t("linkedCardDisplay.noLinkedCards")}
                </Typography>
                <Typography className={classes.noLinkedCardsText}>
                  {t("linkedCardDisplay.linkFirstCard")}
                </Typography>
                <Grid
                  className={classes.linkCardButton}
                  style={{ marginTop: 32, marginBottom: 8 }}
                >
                  <DialogButton
                    colorVariant="black"
                    width={"auto"}
                    height={"100%"}
                    borderRadius={0}
                    padding={12}
                    onClick={handleOpenAppDownloadModal}
                  >
                    {t("linkedCardDisplay.linkCardButton")}
                  </DialogButton>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
