export enum EWalletItem {
    Affiliate = "Affiliate",
    Redemption = "Redemption",
    Travel = "Travel",
    BTCTransfer = "BTCTransfer",
    Interac = "Interac",
    Promotion = "Promotion",
    StoryRewards = "StoryRewards",
    Referral = "Referral",
}
