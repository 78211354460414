export const en = {
  signIn: "Log in to continue",
  walletSignIn: "Log in to view your wallet",
  forgotPassword: "Forgot password?",
  loginWithEmail: "Continue with email",
  notMember: "Not a member?",
  joinCoinmiles: " Join Coinmiles",
  social: {
    facebook: "Continue with Facebook",
    apple: "Continue with Apple",
  },
  activationModal: {
    confirmEmail: "Confirm Your Email",
    sentEmail: "We sent you an email containing a confirmation link.",
    confirmBefore: "Confirm your email before signing in.",
    sendAgain: "Send again",
  },
  restorePasswordModal: {
    restorePassword: "Restore password",
    enterEmail: "Enter your email address to reset your password.",
    email: "Email address",
    send: "Send",
    account: "Do you have an account?",
    signIn: " Log in",
  },
};
