import React from "react";

type Props = {to: string; newTab?: boolean; className?: string; onClick?: any};

export const ExternalLink: React.FC<Props> = ({
  children,
  to,
  newTab,
  className,
  onClick,
}) => (
  <a
    href={to}
    target={newTab && "_blank"}
    rel={newTab && "noopener"}
    className={className}
    onClick={onClick}
    style={{color: "#000000", textDecoration: "none"}}
  >
    {children}
  </a>
);
