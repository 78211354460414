export enum CountryCodes {
  CA = "CA",
  US = "US",
  UK = "UK",
  FR = "FR",
  BE = "BE",
  AU = "AU",
  NZ = "NZ",
  BR = "BR",
}
