export const fr = {
  ambassador: "Ambassadeur",
  applyFilters: "Appliquer un filtre",
  backToMain: "Retour à la page d'accueil",
  blog: "Blog",
  changePassword: "Changer le mot de passe",
  checkOut: "Découvrez",
  contact: "Contacter",
  details: "Détails",
  download: "Téléchargez l’application!",
  earn: "Jusqu'à",
  exclusive: "Offres exclusives",
  faq: "Questions fréquemment posées",
  filters: "Filtres",
  firstName: "Prénom",
  gotIt: "J’ai compris",
  travel: "Voyage",
  orderBy: "Trier par",
  emailAddress: "Adresse électronique",
  joinToEarn: "Jusqu'à",
  joinPartner: "Devenir un partenaire",
  keepExploring: "Continue à explorer",
  lastName: "Nom de famille",
  listView: "Liste",
  loading: "Chargement...",
  login: "Se connecter",
  logOut: "Se déconnecter",
  newPassword: "Nouveau mot de passe",
  noThanks: "Non merci",
  ok: "OK",
  oldPassword: "Ancien mot de passe",
  or: "OU",
  password: "Mot de passe",
  passwordConfirmation: "Confirmation du mot de passe",
  privacyPolicy: "Politique de confidentialité",
  profile: "Profil",
  resetPassword: "Réinitialiser le mot de passe",
  search: "Chercher",
  select: "Sélectionner",
  selectCategory: "Choisissez une catégorie",
  selectCountry: "Choisissez un pays",
  selectLanguage: "Sélectionnez une langue",
  selectLocale: "Choisissez votre pays / langue",
  send: "Envoyer",
  shop: "Activer l’offre",
  shopAt: "Activer l’offre de",
  shopOnline: "Acheter en ligne",
  signIn: "Se connecter",
  signInToEarn: "Connectez-vous pour gagner!",
  signInToView: "Découvrez l’offre",
  signUp: "S'inscrire",
  submit: "Soumettre",
  termsAndConditions: "Conditions d’utilisation",
  termsOfService: "Conditions d'utilisation",
  transaction: "Transaction",
  transactions: "Transactions",
  viewDeal: "Voir",
  wallet: "Portefeuille",
  whatILike: "Ce que j’aime",
  giftCards: "Carte cadeau",
  yourBalance: "Votre solde",
  errors: {
    emailAddress: "L'adresse courriel n'est pas valide.",
    firstName: "Des caractères non acceptés sont présents dans le nom.",
    invalidCredentials: "Nom d'utilisateur ou mot de passe invalide.",
    lastName: "Des caractères non acceptés sont présents dans le nom.",
    password: "Le mot de passe n'est pas valide.",
    passwordConfirmation: "La confirmation du mot de passe ne correspond pas.",
    passwordRequirements:
      "Veuillez entrer un mot de passe contenant une lettre minuscule, une lettre majuscule et un chiffre. Assurez-vous que votre mot de passe contient au moins 8 caractères.",
    fileUploadError:
      "Une erreur s'est produite dans le téléchargement de votre photo de profil.",
    mongoError: "Une erreur s'est produite lors de la connexion. Veuillez réessayer.",
    pageNotFound: "Désolé, page introuvable.",
  },
};
