export const fr = {
  resetPassword: "Réinitialiser le mot de passe",
  enterNewPassword: "Entrez votre nouveau mot de passe.",
  enterTheCode: "Entrez le code transmis à l'adresse courriel.",
  code: "Code",
  passwordConf: "Confirmation du nouveau mot de passe",
  errorText: {
    oops: "Oups. Le lien est expiré.",
    sendNew: "Cliquez ici pour obtenir un nouveau lien.",
    invalidCode: "Code invalide",
  },
};
