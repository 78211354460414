import React, { useState, MouseEvent } from "react";
import { motion } from "framer-motion";

// Component imports
import { FavouritedHeart, Image, PayoutBanner } from "@components";
import { RightArrowIcon } from "@icons";
import { Chip, Grid, Typography } from "@material";

// Misc Imports
import { useMediaQuery } from "@hooks";
import { useTranslation } from "@i18n";
import { cardVariants, makeStyles, arrowBounce } from "@styles";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    maxHeight: 80,
  },
  brandLogoContainer: {
    height: 64,
    width: 64,
    maxWidth: 64,
    marginTop: 8,
    marginBottom: 8,
    border: "1px solid #DEDEDE",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      border: "none",
    },
  },
  brandLogo: {
    margin: "auto",
    width: 45,
    height: 45,
  },
  advertiserNameContainer: {
    marginLeft: 32,
    [theme.breakpoints.down("xs")]: {
      marginLeft: "3vw",
    },
  },
  advertiserName: {
    fontSize: 18,
    fontWeight: 500,
    color: "#000000",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down(359)]: {
      fontSize: 13,
    },
  },
  chip: {
    backgroundColor: "white",
    fontSize: 16,
    width: 132,
    height: 36,
    position: "relative",
    border: "1.5px solid black",
    transitionProperty: "background-color, color",
    transitionDuration: "0.4s",
    transitionTimingFunction: "ease-out",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      width: 94,
      height: 32,
      fontWeight: 400,
      border: "1px solid black",
    },
    [theme.breakpoints.down(350)]: {
      width: 88,
    },
    "&:hover": {
      backgroundColor: "black",
      color: "white",
      transitionProperty: "background-color, color",
      transitionDuration: "0.2s",
    },
  },
  favAndTermsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    maxHeight: 28,
    marginTop: 2,
  },
  heartIconContainer: { marginRight: 24, marginTop: 4, cursor: "pointer" },
  termsButton: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    width: 72,
    height: 31,
    fontSize: 14,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      right: 32,
    },
    [theme.breakpoints.down("xs")]: {
      right: 12,
    },
  },
  buttonIcon: {
    marginLeft: 3,
    marginTop: 8,
  },
}));

export function ListCardView({
  offer,
  loggedIn,
  favourited,
  onRedirectClicked,
  onTermsModalClicked,
  onSignUpModalClicked,
}: OfferCardProps) {
  const classes: any = useStyles({});
  const small: boolean = useMediaQuery("(max-width:959px)");
  const { t } = useTranslation("common");
  const localStorageLanguage: string = localStorage.getItem("i18nextLng");

  const [termsVisible, setTermsVisible] = useState<boolean>(false);

  const handleMouseHover = (): void => {
    setTermsVisible(!termsVisible);
  };

  const handleTermsButtonClick = (event: MouseEvent<HTMLInputElement>): void => {
    if (event.button === 0) {
      event.stopPropagation();
      onTermsModalClicked(offer);
    }
  };

  const handleCardPressed = (event: MouseEvent<HTMLInputElement>): void => {
    if (event.button === 0) {
      if (event.button === 0) {
        loggedIn ? onRedirectClicked(offer) : onSignUpModalClicked(offer);
      }
    }
  };

  const payoutTextLocalized: string =
    localStorageLanguage === "fr" && offer.payoutText.includes("$")
      ? `${offer.payoutText.substring(1)}$`
      : offer.payoutText;

  return (
    <Grid
      container
      item
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      xs={12}
      onMouseEnter={!termsVisible && !small ? handleMouseHover : undefined}
      onMouseLeave={termsVisible && !small ? handleMouseHover : undefined}
    >
      <Grid
        container
        item
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        xs
        sm={8}
        md={6}
        lg={6}
      >
        <Grid
          container
          item
          xs={2}
          justifyContent="center"
          alignItems="center"
          className={classes.brandLogoContainer}
          onClick={handleCardPressed}
        >
          <Image
            image={offer.advertiserLogoImage}
            className={classes.brandLogo}
            alt={`${offer.id}-advertiser-logo`}
          />
        </Grid>
        <Grid
          container
          item
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          xs
          className={classes.advertiserNameContainer}
        >
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography className={classes.advertiserName}>
              {offer.advertiserName}
            </Typography>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <PayoutBanner payoutText={payoutTextLocalized} />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="column"
        justifyContent={loggedIn ? "flex-start" : "center"}
        alignItems="flex-end"
        xs={4}
        sm={4}
        md={3}
        style={{ minHeight: 69, marginTop: 12 }}
      >
        <Chip
          label={loggedIn ? t("shop") : t("viewDeal")}
          className={classes.chip}
          onClick={handleCardPressed}
          style={{ fontWeight: 500 }}
        />
        <Grid container justifyContent="flex-end">
          {loggedIn && (small || termsVisible) && (
            <div className={classes.favAndTermsContainer}>
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={cardVariants}
                whileHover="hover"
              >
                <div onMouseDown={handleTermsButtonClick} className={classes.termsButton}>
                  {t("details")}
                  <motion.div variants={arrowBounce}>
                    <RightArrowIcon
                      width={12}
                      fill={"black"}
                      className={classes.buttonIcon}
                    />
                  </motion.div>
                </div>
              </motion.div>
            </div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
