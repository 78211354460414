import React from "react";

// Misc Imports
import { Assets, couponCategories } from "@assets";
import { useTranslation } from "@i18n";
import {
  BTCFiatAmount,
  TransactionTypes,
  WalletTransaction,
  WalletTransactionStatus,
} from "@models";
import { makeStyles } from "@styles";
import { getCurrencySignByCurrency, ModalHelper } from "@utils";
import { useReactiveVar, currentCountryVar } from "@apollo";

// Component imports
import { DividerLine } from "@components";
import { Grid, Paper, Skeleton, Typography } from "@material";

import { WalletTransactionCard } from "../wallet-transaction-card";

const useStyles = makeStyles((theme: Theme) => ({
  walletActivity: {
    backgroundColor: "white",
    border: `1px solid #EBEBEB`,
    width: "100%",
    padding: 24,
    minHeight: 419,
  },
  transactionHeader: {
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-end",
    },
  },
  balanceHeader: {
    fontSize: 24,
    fontWeight: 500,
  },
  missingTransactionsContainer: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
  missingTransactions: {
    fontSize: 14,
    fontWeight: 400,
  },
  transactionContainer: { minHeight: 285 },
  moneyBagContainer: {
    height: 88,
    width: 88,
    borderRadius: "50%",
    border: "1px solid #9e9e9e",
    backgroundColor: "#FAFAFA",
  },
  moneyBag: {
    width: 64,
  },
  noRewardsHeader: {
    fontSize: 20,
    color: "black",
    marginTop: 8,
    marginBottom: 8,
  },
  noRewardsText: {
    fontSize: 18,
    color: "#393939",
  },
  transactionSkeletonContainer: {
    width: "100%",
    height: 88,
    paddingTop: 15,
    paddingBottom: 15,
    [theme.breakpoints.down("xs")]: {
      height: 76,
    },
  },
}));

type Props = {
  transactions: WalletTransaction[];
  loading: boolean;
};

export function WalletActivity({ transactions, loading }: Props) {
  const classes: any = useStyles({});
  const { t } = useTranslation("wallet");

  const handleOpenMissingTransactionsModal = (): void => {
    ModalHelper.open({ modalType: "missingTransactions" });
  };

  const interacFee = 3.99;
  const country = useReactiveVar(currentCountryVar);

  function getTransactionDetails(transaction: WalletTransaction): ITransactionDetails {
    const detailsFunction = {
      [TransactionTypes.AdHocRewards]: () => getPromotion(transaction, "adHoc"),
      [TransactionTypes.Affiliate]: () => getAffiliate(transaction),
      [TransactionTypes.BTCTransfert]: () => getBtcTransfer(transaction),
      [TransactionTypes.InitialRewards]: () => getPromotion(transaction, "initial"),
      [TransactionTypes.Interac]: () => getInterac(transaction),
      [TransactionTypes.Redemption]: () => getRedemption(transaction),
      [TransactionTypes.ReferralForNewUser]: () => getReferral(transaction, "new"),
      [TransactionTypes.ReferralForReferrer]: () => getReferral(transaction, "ref"),
      [TransactionTypes.Travel]: () => getTravel(transaction),
      ["GiftCard"]: () => getGiftCard(transaction), // todo: why is new type not recognized?
      [TransactionTypes.StoryRewards]: () => getStoryReward(transaction),
    }[transaction.externalRef.type];

    return detailsFunction?.();
  }

  function getAffiliate({
    externalRef: { data },
    amount,
    status,
  }: WalletTransaction): ITransactionDetails {
    const { btc, fiat } = new BTCFiatAmount(amount);
    const { currency, unscaledValue } = fiat;
    const { advertiserName, externalData } = data;
    const currencyCode = country.currencyCode !== currency ? `${currency} ` : "";
    const currencySign = getCurrencySignByCurrency(currency);
    const missing = status === WalletTransactionStatus.MissingTransaction;

    const itemTitle = advertiserName;
    const imageSource = externalData?.offer?.advertiserLogo;
    const fiatValue = missing
      ? "---"
      : `${currencyCode}${currencySign}${unscaledValue?.toFixed(2)}`;
    const btcValue = btc?.value ? `${btc.unscaledValue.toFixed(8)} BTC` : "";
    const symbol = missing ? "" : "+ ";

    return { itemTitle, imageSource, fiatValue, btcValue, symbol };
  }

  function getBtcTransfer({ amount }: WalletTransaction): ITransactionDetails {
    const { btc } = new BTCFiatAmount(amount);

    const itemTitle = t("bitcoinSent");
    const imageSource = Assets.WITHDRAWAL_ICON;
    const fiatValue = `${btc.unscaledValue.toFixed(6)} BTC`;
    const btcValue = "";

    return { itemTitle, imageSource, fiatValue, btcValue, symbol: "- " };
  }

  function getInterac({ externalRef: { data } }: WalletTransaction): ITransactionDetails {
    const { fiat } = new BTCFiatAmount(data.amount);

    const itemTitle = t("interacTransfer");
    const imageSource = Assets.INTERAC_LOGO;
    const fiatValue = `CAD $${Math.abs(fiat.unscaledValue - interacFee).toFixed(2)}`;
    const btcValue = "";

    return { itemTitle, imageSource, fiatValue, btcValue, symbol: "- " };
  }

  function getPromotion(
    { amount }: WalletTransaction,
    type: "initial" | "adHoc",
  ): ITransactionDetails {
    const { btc } = new BTCFiatAmount(amount);

    const itemTitle = { initial: t("welcomeBonus"), adHoc: t("bonus") }[type];
    const imageSource = Assets.WELCOME_GIFT;
    const fiatValue = `${btc.unscaledValue.toFixed(6)} BTC`;
    const btcValue = "";

    return { itemTitle, imageSource, fiatValue, btcValue, symbol: "+ " };
  }

  function getStoryReward({
    amount,
    externalRef: { data },
  }: WalletTransaction): ITransactionDetails {
    const { btc } = new BTCFiatAmount(amount);
    // TODO: figure out why the dype of "data" returns as Promotion instead of StoryReward
    const _affiliateName = data?.affiliateName;
    const itemTitle = `${t("rewardGift")} ${_affiliateName}`;
    const imageSource = data.logo;
    const fiatValue = "";
    const btcValue = btc?.value
      ? `${(btc.unscaledValue * 100000000).toFixed(0)} Sats`
      : "";

    return {
      itemTitle,
      imageSource,
      fiatValue,
      btcValue,
      symbol: "+ ",
    };
  }

  function getReferral(
    { amount, externalRef: { data } }: WalletTransaction,
    type: "new" | "ref",
  ): ITransactionDetails {
    const { btc } = new BTCFiatAmount(amount);

    const itemTitle = {
      new: `${t("newUserReferralSignUp")} ${data.referrerFirstName}!`,
      ref: `${data.newUserFirstName} ${t("referralSignUp")}`,
    }[type];
    const imageSource = Assets.REFERRAL_LOGO;
    const fiatValue = btc?.value ? `${btc?.unscaledValue?.toFixed(6)} BTC` : "";
    const btcValue = btc?.value ? `${btc?.unscaledValue?.toFixed(8)} BTC` : "";

    return { itemTitle, imageSource, fiatValue, btcValue, symbol: "+ " };
  }

  function getRedemption({
    amount,
    externalRef: { data },
  }: WalletTransaction): ITransactionDetails {
    const { btc, fiat } = new BTCFiatAmount(amount);
    const { currency, unscaledValue } = fiat;
    const { campaign } = data;
    const { brand } = campaign;
    const currencyCode = country.currencyCode !== currency ? `${currency} ` : "";
    const currencySign = getCurrencySignByCurrency(currency);

    const itemTitle = brand.name;
    const imageSource =
      brand.useLogoImage === false
        ? couponCategories.find((cat) => cat.name === brand.industry)?.icon
        : brand.images[0].original;
    const fiatValue = `${currencyCode}${currencySign}${unscaledValue?.toFixed(2)}`;
    const btcValue = btc?.value ? `${btc.unscaledValue.toFixed(8)} BTC` : "";

    return { itemTitle, imageSource, fiatValue, btcValue, symbol: "+ " };
  }

  function getTravel({
    externalRef: { data },
    amount: amountData,
  }: WalletTransaction): ITransactionDetails {
    const { btc, fiat } = new BTCFiatAmount(amountData);
    const { currency, unscaledValue } = fiat;
    const { hotelName } = data;
    const currencyCode = country.currencyCode !== currency ? `${currency} ` : "";

    const itemTitle = hotelName;
    const imageSource = Assets.HOTELS_WALLET_ICON;
    const fiatValue = `${currencyCode}$${unscaledValue?.toFixed(2)}`;
    const btcValue = btc?.value ? `${btc.unscaledValue.toFixed(8)} BTC` : "";

    return { itemTitle, imageSource, fiatValue, btcValue, symbol: "+ " };
  }

  function getGiftCard({
    externalRef: { data },
    amount: amountData,
  }: WalletTransaction): ITransactionDetails {
    const { btc, fiat } = new BTCFiatAmount(amountData);
    const { currency, unscaledValue } = fiat;
    const { externalData } = data;
    const currencyCode = country.currencyCode !== currency ? `${currency} ` : "";

    const itemTitle = data?.externalData?.brandName ?? "";
    const imageSource =
      (data?.externalData?.images && data?.externalData?.images[2]?.imageUrl) ??
      Assets.WELCOME_GIFT;
    const fiatValue = `${currencyCode}$${unscaledValue?.toFixed(2)}`;
    const btcValue = btc?.value ? `${btc.unscaledValue.toFixed(8)} BTC` : "";

    return { itemTitle, imageSource, fiatValue, btcValue, symbol: "+ " };
  }

  /* Transaction Items */
  const transactionCards =
    !loading &&
    transactions?.map((transaction: WalletTransaction, index: number) => {
      const transactionDetails = getTransactionDetails(transaction);

      const iso8016Time = new Date(transaction.createdAt);
      const dateOptions = { day: "numeric", month: "short", year: "numeric" };
      const createdAt = iso8016Time.toLocaleDateString("en-GB", dateOptions as any);
      // "en-GB" gives desired date format: DD mmm YYYY

      return (
        <div key={transaction.id}>
          <WalletTransactionCard {...transactionDetails} createdAt={createdAt} />
          {!!(index !== transactions.length - 1 || transactions.length === 1) && (
            <DividerLine />
          )}
        </div>
      );
    });

  const noTransactionsPresent = !!(!loading && !!transactions && !transactions?.length);

  return (
    <Paper className={classes.walletActivity}>
      <Grid container direction="column">
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          lg={12}
          className={classes.transactionHeader}
        >
          <Grid item xs={12} sm={12} md={5} lg={6}>
            {" "}
            <Typography className={classes.balanceHeader}>{t("myActivity")}</Typography>
          </Grid>
          <Grid
            container
            item
            alignItems="flex-start"
            sm={12}
            md={7}
            lg={6}
            className={classes.missingTransactionsContainer}
          >
            {" "}
            <Typography className={classes.missingTransactions}>
              {t("missing")}{" "}
              <strong
                onClick={handleOpenMissingTransactionsModal}
                style={{ cursor: "pointer" }}
              >
                {t("clickHere")}
              </strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            item
            direction="column"
            justifyContent={noTransactionsPresent ? "center" : "flex-start"}
            alignItems={noTransactionsPresent ? "center" : "stretch"}
            lg={12}
            className={classes.transactionContainer}
          >
            {noTransactionsPresent ? (
              <>
                <Grid
                  container
                  item
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  className={classes.moneyBagContainer}
                >
                  <img
                    src={Assets.MONEY_BAG}
                    className={classes.moneyBag}
                    alt="money-bag-emoji"
                  />
                </Grid>
                <Typography className={classes.noRewardsHeader}>
                  {t("noRewards")}
                </Typography>
                <Typography className={classes.noRewardsText}>
                  {t("startVisiting")}
                </Typography>
              </>
            ) : !loading && !!transactions?.length ? (
              <div style={{ width: "100%" }}>{transactionCards}</div>
            ) : (
              <Grid
                container
                item
                className={classes.transactionSkeletonContainer}
                sm={12}
              >
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width="100%"
                  height="100%"
                  style={{ borderRadius: 4 }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
