import React, {useEffect} from "react";
import ConfettiGenerator from "confetti-js";

type ConfettiProps = {
  confetti: boolean;
};

export function ConfettiComponent({confetti}: ConfettiProps) {
  useEffect(() => {
    if (confetti) {
      const confettiSettings = {
        target: "confetti",
        props: ["circle", "square", "triangle", "line"],
        rotate: "true",
        color: [
          [165, 104, 246],
          [230, 61, 135],
          [0, 199, 228],
          [253, 214, 126],
          [255, 0, 114],
        ],
      };
      if (confetti) {
        const confetti = new ConfettiGenerator(confettiSettings);
        confetti.render();
        return () => confetti.clear();
      }
    }
  }, [confetti]);

  return (
    <canvas
      id="confetti"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 5000,
        pointerEvents: "none",
      }}
    />
  );
}
