import { gql } from "@apollo/client";

export const MUTATION_CREATE_OFFER_TRACKING_URL_CLICK = gql`
  mutation CreateOfferTrackingUrlClick(
    $offerId: ID
    $trackingUrl: String
    $platform: String
  ) {
    createOfferTrackingUrlClick(
      offerId: $offerId
      trackingUrl: $trackingUrl
      platform: $platform
    )
  }
`;
