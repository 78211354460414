export const en = {
  pageMessage: {
    one: "Book a hotel.",
    two: "Up to 25% back \n in Bitcoin.",
  },
  inputDestination: "Your destination",
  emptyDestination: "Please enter a destination",
  checkIn: "Check in",
  checkOut: "Check out",
  room: "room",
  rooms: "rooms",
  guest: "guest",
  guests: "guests",
  done: "done",
  btnSearch: "Search",
};
