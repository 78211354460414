export const pt = {
  signUp: "Inscreva-se para continuar",
  firstName: "Primeiro nome",
  lastName: "Sobrenome",
  emailAddress: "Endereço de email",
  password: "Senha",
  passwordConfirmation: "Confirmação de Senha",
  signUpWithEmail: "Continuar com e-mail",
  signUpDisclaimer: {
    one: "Ao se inscrever, eu concordo com Coinmiles'",
    two: " Termos de serviço",
    three: " e",
    four: " política de Privacidade",
    five: ". Coinmiles está disponível apenas no Canadá no momento.",
  },
  alreadyMember: "já é um membro?",
  modal: {
    earnAt: "Comprar em",
    weNeedToKnow: {
      one: "Precisamos saber quem deve ser recompensado!",
      two: "Boas notícias: quando você cria uma conta, ",
      three: "vamos colocar automaticamente ",
      four: " Sats na sua carteira.",
    },
  },
  drawer: {
    letsCheckOut: "Vamos conferir as ofertas",
    aCoolGift: "Um presente legal para você",
    loginToViewDeals: {
      one: "Faça login para ver as ofertas. Isso é ",
      two: "rápido, de graça ",
      three: "e nós te damos ",
      four: " Sats como bônus de boas-vindas",
    },
  },
  landingPage: {
    header: {
      verified1: "OBRIGADO(A)",
      verified2: "POR",
      verified3: "SE JUNTAR",
      verified4: "À COINMILES!",
      expiredCode: "Link inválido",
      invalidCode: "Código inválido",
      error: "Um erro ocorreu",
    },
    verified:
      "Sua conta agora está confirmada. Agora você pode entrar usando o seu e-mail ",
    expiredCode:
      "Seu link de confirmação é inválido ou expirou. Você já confirmou sua conta? Caso ainda não, clique no botão abaixo para entrar em contato com o suporte.",
    invalidCode:
      "O código de confirmação que você forneceu é inválido. Clique no botão abaixo para entrar em contato com o suporte",
    error: "Ocorreu um erro com sua confirmação, tente novamente.",
    button: {
      verified: "Ir para fazer login",
      expiredCode: "Entre em contato com o suporte",
      invalidCode: "Entre em contato com o suporte",
      backToHome: "Voltar para a página inicial",
      openWithApp: "Voltar para o aplicativo",
      openWithWebsite: "Entrar",
    },
  },
  success: {
    thanks: "Obrigado por se juntar à Coinmiles!",
    confirmation: {
      one: "Enviamos um e-mail de confirmação para ",
      two: "Por favor, confirme sua conta para começar a usar Coinmiles.",
    },
    didntReceive: "Não recebeu um e-mail?",
    sendAgain: " Enviar novamente",
    ok: "OK",
    confirmAndContinue: "Tudo bem",
    confirmationResent: "Confirmação reenviada",
  },
};
