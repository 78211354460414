import { Auth } from "aws-amplify";
import SecureLS from "secure-ls";

import { currentUserVar, cognitoUserStateVar } from "@apollo";

import { User } from "@models";
import { fetchSignInData, LocaleUtils, ModalHelper, navigate } from "@utils";

interface SignInParams {
  emailAddress: string;
  password: string;
}

interface CompleteSignInParams {
  currencyCode: string;
  updateUserMetadata: (user: User) => Promise<void>;
}

export class AuthUtils {
  static signIn = async ({ emailAddress, password }: SignInParams): Promise<void> => {
    try {
      const cognitoUser = await Auth.signIn({
        username: emailAddress,
        password,
      });

      if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        cognitoUserStateVar(cognitoUser);
        navigate("/reset-password", {
          state: {
            referrer: "new-password-required",
          },
          replace: false,
        });
      } else {
        const { currencyCode } = await LocaleUtils.setLocale();

        // Assigns current country and user to local state Reactive Vars then redirects
        navigate("/", {
          state: { signIn: true, currencyCode },
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  static completeSignIn = async ({
    currencyCode,
    updateUserMetadata = null,
  }: CompleteSignInParams): Promise<void> => {
    try {
      const user = await fetchSignInData(currencyCode);

      if (!!user.didLogin && !user.metadata) await updateUserMetadata(user);

      const encryptedLocalStorage = new SecureLS();
      encryptedLocalStorage.set("persisted-user", user);
      currentUserVar(user);

      if (!!user.didLogin) setTimeout(() => ModalHelper.close(), 2500);
    } catch {
      ModalHelper.close();
      await Auth.signOut();
      currentUserVar(null);
      navigate("/sign-in", {
        state: { signInError: true },
      });
    }
  };

  static signOut = async (redirectTo: string = "/"): Promise<void> => {
    currentUserVar(null);

    const localStorageCountry: string = localStorage.getItem("userCountry") || "CA";
    const localStorageLanguage: string = localStorage.getItem("i18nextLng") || "en";

    const encryptedLocalStorage = new SecureLS();
    encryptedLocalStorage.removeAll();
    localStorage.clear();

    localStorage.setItem("userCountry", localStorageCountry);
    localStorage.setItem("i18nextLng", localStorageLanguage);

    await Auth.signOut();

    navigate(redirectTo);
  };
}
