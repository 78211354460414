export const pt = {
  myProfile: "Meu perfil",
  profileSubheader: "Encontre tudo o que você precisa sobre sua conta aqui",
  linkedCardDisplay: {
    dialogMessageString:
      "Atualmente, a vinculação de cartão de crédito está disponível apenas no aplicativo. Baixe agora para vincular seu cartão e ser recompensado(a).",
    myLinkedCards: "Meus cartões vinculados",
    linkAnotherCard: "Deseja vincular outro cartão?",
    noLinkedCards: "Você não tem nenhum cartão de crédito vinculado.",
    linkFirstCard:
      "Vincule um cartão agora para ser recompensado quando fizer compras na loja.",
    linkCardButton: "Vincular cartão de crédito",
  },
  modals: {
    upload: {
      uploading: "Enviando...",
      cropAndUpload: "Recorte e envie sua foto de perfil",
      selectAnotherPhoto: "Selecione outra foto",
      uploadPhoto: "Carregar foto",
      dragAndDrop: "Arraste e solte sua foto aqui",
      or: "OR",
      selectFromFiles: "Selecione a partir de seus arquivos",
    },
    changePasswordSuccess: {
      passwordChanged: "Senha alterada",
      successText: {
        one:
          "Sua senha foi alterada com sucesso. Faça login novamente com sua nova senha para continuar usando Coinmiles.",
        two: "Ok",
      },
    },
    deleteLinkedCard: {
      deleteConfirmation: "Tem certeza de que deseja desvincular este cartão?",
      yesUnlink: "Sim, desvincular cartão",
      noKeepLinked: "Não, mantenha-o vinculado",
    },
  },
};
