import { useEffect, useState } from "react";
import { detectAnyAdblocker } from "just-detect-adblock";

import { isServerSideRendering } from "@utils";

export const useDetectAdBlock = (): boolean => {
  if (isServerSideRendering()) return;

  const [adBlockDetected, setAdBlockDetected] = useState<boolean>(undefined);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const detected: boolean = await detectAnyAdblocker();
        // Used to detect Ghostery, which is not picked up the just-detect-adblock
        await fetch(
          "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
          {
            method: "HEAD",
            mode: "no-cors",
          },
        );
        setAdBlockDetected(detected);
      } catch (e) {
        setAdBlockDetected(true);
      }
    })();
  }, []);

  return adBlockDetected;
};
