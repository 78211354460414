import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Auth } from "aws-amplify";

// Component imports
import {
  DialogButton,
  Error as ErrorComponent,
  LoadingSpinner,
} from "@components";
import { Grid, Typography } from "@material";

// Misc imports

import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";
import { AuthUtils, isServerSideRendering, ModalHelper } from "@utils";
import { sendSegmentTrackEvent } from "helpers/analytics";

const useStyles = makeStyles((theme: Theme) => ({
  textFieldContainer: {
    height: 79,
    width: 308,
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  textField: {
    width: 308,
    height: 48,
    padding: 2,
    paddingLeft: 16,
    border: `1px solid ${theme.palette.colors.border.light}`,
    fontFamily: "Roboto, sans-serif",
    fontSize: 16,
    borderRadius: 2,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  formFieldText: {
    marginTop: 10,
    color: "#000000",
    fontSize: 14,
    textAlign: "center",
    paddingBottom: 6,
  },
  forgotPasswordContainer: {
    textDecoration: "none",
    height: 16,
    marginBottom: 24,
    width: 308,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  forgotPasswordText: {
    cursor: "pointer",
    color: "#464646",
    fontSize: 14,
    marginTop: 5,
    textAlign: "center",
    float: "right",
  },
  logInButtonContainer: {
    marginTop: 9,
    marginBottom: 10,
  },
}));

type FormValues = {
  emailAddress: string;
  password: string;
};

type Props = {
  prefilledEmail?: string | undefined;
};

export function SignInForm({ prefilledEmail }: Props) {
  const classes: any = useStyles({});
  const { t } = useTranslation(["signIn", "common"]);

  const [loading, setLoading] = useState<boolean>(false);

  const signIn: SubmitHandler<FormValues> = async (data): Promise<void> => {
    if (isServerSideRendering()) return;

    setLoading(true);
    setInvalidCreds(false);

    const { emailAddress, password } = data;

    try {
      await AuthUtils.signIn({
        emailAddress,
        password,
      });
    } catch (err) {
      await Auth.signOut();
      setLoading(false);

      if (err.code === "UserNotConfirmedException") {
        handleOpenActivationModal(emailAddress);
      } else if (err.code === "NotAuthorizedException") {
        setInvalidCreds(true);
      } else {
        setInvalidCreds(true);
        throw new Error(`[WEBAPP SIGN IN ERROR]: ${err.message}`);
      }
    }

    sendSegmentTrackEvent("emailSigninButton");
  };

  // Form & Modal Functionality
  const [invalidCreds, setInvalidCreds] = useState<boolean>(false);

  const { register, handleSubmit } = useForm({
    defaultValues: { emailAddress: prefilledEmail || "" },
  });

  const handleOpenActivationModal = (emailAddress: string): void => {
    ModalHelper.open({
      modalType: "activation",
      modalProps: { emailAddress },
    });
  };

  const handleOpenRestorePasswordModal = (): void => {
    ModalHelper.open({ modalType: "resetPassword" });
  };

  return (
    <form onSubmit={handleSubmit(signIn)}>
      <Grid
        container
        item
        justifyContent="flex-start"
        alignItems="flex-end"
        className={classes.textFieldContainer}
      >
        <Typography className={classes.formFieldText}>
          {t("common:emailAddress")}
        </Typography>
        <input
          id="sign-in-email-address"
          placeholder={t("common:emailAddress")}
          name="emailAddress"
          ref={register({
            required: true,
          })}
          className={classes.textField}
        />
      </Grid>
      <Grid
        container
        item
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
        style={{ marginTop: "-6px" }}
        xs={12}
      >
        <Typography className={classes.formFieldText}>
          {t("common:password")}
        </Typography>
        <input
          id="sign-in-password"
          placeholder={t("common:password")}
          name="password"
          type="password"
          ref={register({
            required: true,
          })}
          className={classes.textField}
        />
        {invalidCreds && (
          <ErrorComponent>
            {t("common:errors.invalidCredentials")}
          </ErrorComponent>
        )}
        <Grid
          container
          item
          justifyContent="flex-end"
          className={classes.forgotPasswordContainer}
        >
          <Typography
            className={classes.forgotPasswordText}
            onClick={handleOpenRestorePasswordModal}
          >
            {t("forgotPassword")}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.logInButtonContainer}>
        <DialogButton
          colorVariant="pink"
          width="100%"
          fontSize={16}
          height={56}
          borderRadius={10}
          type="submit"
          hoverFade={false}
        >
          {loading ? (
            <LoadingSpinner size={24} color="white" />
          ) : (
            t("loginWithEmail")
          )}
        </DialogButton>
      </Grid>
    </form>
  );
}
