import { CurrencyTypes } from "./currency-types";
import { CountryCodes } from "./country-codes";

export enum AffiliateServiceAreas {
  All = "ALL",
  Australia = "AUSTRALIA",
  Belgium = "BELGIUM",
  Brazil = "BRAZIL",
  Canada = "CANADA",
  France = "FRANCE",
  UnitedKingdom = "UNITED_KINGDOM",
  UnitedStates = "UNITED_STATES",
  NewZealand = "NEW_ZEALAND",
}

interface AffiliateServiceArea {
  id: string;
  name: string;
  countryCode: string;
  currencyCode: string;
  currencySign: string;
  flag: string;
}

export const affiliateServiceAreas: AffiliateServiceArea[] = [
  {
    id: AffiliateServiceAreas.All,
    name: "All Countries",
    countryCode: "**",
    currencyCode: "***",
    currencySign: "$",
    flag: "",
  },
  {
    id: AffiliateServiceAreas.Australia,
    name: "Australia",
    countryCode: CountryCodes.AU,
    currencyCode: "AUD",
    currencySign: "$",
    flag:
      "https://coinmiles-affiliate-offers-production.s3.ca-central-1.amazonaws.com/flags/AU.png",
  },
  {
    id: AffiliateServiceAreas.Belgium,
    name: "Belgium",
    countryCode: CountryCodes.BE,
    currencyCode: CurrencyTypes.EUR,
    currencySign: "€",
    flag:
      "https://coinmiles-affiliate-offers-production.s3.ca-central-1.amazonaws.com/flags/BE.png",
  },
  {
    id: AffiliateServiceAreas.Brazil,
    name: "Brazil",
    countryCode: CountryCodes.BR,
    currencyCode: "BRL",
    currencySign: "R$",
    flag:
      "https://coinmiles-affiliate-offers-production.s3.ca-central-1.amazonaws.com/flags/BR.png",
  },
  {
    id: AffiliateServiceAreas.Canada,
    name: "Canada",
    countryCode: CountryCodes.CA,
    currencyCode: CurrencyTypes.CAD,
    currencySign: "$",
    flag:
      "https://coinmiles-affiliate-offers-production.s3.ca-central-1.amazonaws.com/flags/CA.png",
  },
  {
    id: AffiliateServiceAreas.France,
    name: "France",
    countryCode: CountryCodes.FR,
    currencyCode: CurrencyTypes.EUR,
    currencySign: "€",
    flag:
      "https://coinmiles-affiliate-offers-production.s3.ca-central-1.amazonaws.com/flags/FR.png",
  },
  {
    id: AffiliateServiceAreas.UnitedKingdom,
    name: "United Kingdom",
    countryCode: CountryCodes.UK,
    currencyCode: "GBP",
    currencySign: "£",
    flag:
      "https://coinmiles-affiliate-offers-production.s3.ca-central-1.amazonaws.com/flags/UK.png",
  },
  {
    id: AffiliateServiceAreas.UnitedStates,
    name: "United States",
    countryCode: CountryCodes.US,
    currencyCode: CurrencyTypes.USD,
    currencySign: "$",
    flag:
      "https://coinmiles-affiliate-offers-production.s3.ca-central-1.amazonaws.com/flags/US.png",
  },
  {
    id: AffiliateServiceAreas.NewZealand,
    name: "New Zealand",
    countryCode: CountryCodes.NZ,
    currencyCode: "NZD",
    currencySign: "$",
    flag:
      "https://coinmiles-affiliate-offers-production.s3.ca-central-1.amazonaws.com/flags/NZ.png",
  },
];
