import React from "react";

// Component imports
import { Grid, Typography } from "@material";

// Misc Imports
import { makeStyles } from "@styles";

const useStyles = makeStyles((theme: Theme) => ({
    transaction: {
        backgroundColor: theme.palette.white,
        width: "100%",
        height: 88,
        paddingTop: 15,
        paddingBottom: 15,
        paddingRight: 15,
        [theme.breakpoints.down("xs")]: {
            height: "auto",
            paddingRight: "3vw",
        },
    },
    brandLogo: {
        height: "56px",
        width: "56px",
        borderRadius: "16px",
        [theme.breakpoints.down("xs")]: {
            marginRight: 8,
        },
    },
    logoContainer: {
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        height: 62,
        width: "100%",
        maxWidth: 62,
        marginRight: 16,
        [theme.breakpoints.down("xs")]: {
            height: 46,
            marginRight: 8,
        },
    },
    companyText: {
        fontSize: 18,
        fontWeight: 600,
        [theme.breakpoints.down("xs")]: {
            fontSize: 14,
        },
    },
    dateText: {
        fontSize: 14,
        color: theme.palette.colors.text.subheader,
        [theme.breakpoints.down("xs")]: {
            fontSize: 12,
        },
    },
    fiatText: {
        fontSize: 17,
        fontWeight: 500,
        [theme.breakpoints.down("xs")]: {
            fontSize: 12,
        },
    },
    btcText: {
        fontSize: 14,
        color: theme.palette.colors.text.subheader,
        [theme.breakpoints.down("xs")]: {
            fontSize: 12,
        },
    },
}));

export function WalletTransactionCard({
    itemTitle,
    imageSource,
    fiatValue,
    btcValue,
    symbol,
    createdAt,
}: ITransactionDetails) {
    const classes: any = useStyles({});

    return (
        <Grid container item className={classes.transaction} sm={12}>
            <Grid
                container
                item
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sm={12}
            >
                <Grid
                    container
                    item
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={8}
                    lg={9}
                >
                    <Grid
                        item
                        xs={3}
                        sm={4}
                        md={3}
                        lg={2}
                        className={classes.logoContainer}
                        style={{
                            backgroundImage: `url("${imageSource}")`,
                        }}
                    />
                    <Grid container item direction="column" xs sm md lg>
                        <Grid item>
                            {" "}
                            <Typography className={classes.companyText}>
                                {itemTitle}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.dateText}>
                                {createdAt}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    direction="column"
                    alignItems="flex-end"
                    xs={4}
                    lg={3}
                >
                    <Grid item container direction="column" alignItems="flex-end">
                        <Grid item>
                            {!!fiatValue && (
                                <Typography className={classes.fiatText}>
                                    {`${symbol} ${fiatValue}`}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item>
                            {fiatValue !== "" && btcValue !== "" && (
                                <Typography className={classes.btcText}>
                                    {`${btcValue}`}
                                </Typography>
                            )}
                            {/* Story reward - Sats text */}
                            {fiatValue === "" && btcValue !== "" && (
                                <Typography className={classes.fiatText}>
                                    {`${symbol} ${btcValue}`}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
