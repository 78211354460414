import { affiliateServiceAreas } from "@models";

// This function takes in a country code ("CA", "US") and returns the full
// AffiliateServiceArea object, complete with currencyCode, name, flag, etc.
export const getAffiliateServiceArea = (
    countryCode: string,
): AffiliateServiceArea | undefined =>
    affiliateServiceAreas?.find(
        (serviceArea: AffiliateServiceArea) => serviceArea.countryCode === countryCode,
    ) || undefined;
