import React, { forwardRef, useState, useCallback, useEffect } from "react";

// Library imports
import { motion } from "framer-motion";
import Dropzone from "react-dropzone";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { useLazyQuery, UserPoolClient } from "@apollo";
import { USER_POOL_GET_PRESIGNED_URL_S3 } from "@queries";

// Misc Imports
import { useMediaQuery, useUpdateUser } from "@hooks";
import { useTranslation } from "@i18n";
import { makeStyles, pageVariants } from "@styles";
import { ModalHelper } from "@utils";

// Component imports
import { DialogButton, DividerLineWithText, Error, LoadingSpinner } from "@components";
import { CloudUploadIcon } from "@icons";
import { Paper, Grid, Typography } from "@material";

const useStyles = makeStyles((theme: Theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        height: 340,
        width: 638,
        borderRadius: 10,
        backgroundColor: "#FFFFFF",
        outline: "none !important",
        [theme.breakpoints.down(720)]: {
            width: 320,
        },
    },
    container: {
        width: 598,
        height: 292,
        outline: "none !important",
        [theme.breakpoints.down(720)]: {
            width: 272,
            height: 272,
            margin: "24px 24px 24px 24px",
        },
    },
    cropContainer: {
        position: "relative",
        height: 292,
        width: 292,
        maxWidth: 292,
        maxHeight: 292,
        borderRadius: 6,
        backgroundColor: "#efefef",
        border: "1px solid black",
        overflow: "hidden",
        [theme.breakpoints.down(720)]: {
            height: 272,
            width: 272,
            maxWidth: 272,
            maxHeight: 272,
        },
    },
    buttonContainer: {
        height: 292,
        width: "100%",
    },
    cloudUploadIcon: { opacity: 0.2, marginBottom: 14 },
    uploadingText: {
        width: 209,
        fontSize: 26,
        textAlign: "center",
        marginBottom: 24,
    },
    cropPhotoText: {
        width: 209,
        fontSize: 24,
        textAlign: "center",
        marginBottom: 24,
        [theme.breakpoints.down("xs")]: {
            fontSize: 18,
        },
    },
    dragDropText: { fontSize: 20 },
}));

type Crop = {
    aspect: number;
    unit?: string;
    width?: number;
    height?: number;
    x?: number;
    y?: number;
};

export const ProfilePicUploadModal = forwardRef(() => {
    const classes: any = useStyles({});
    const { t } = useTranslation(["profile", "common"]);

    const { updateUser } = useUpdateUser({ onCompleted: ModalHelper.close });

    const containerSwitchSize: boolean = useMediaQuery("(min-width:720px)");

    const [crop, setCrop] = useState<Crop>({ aspect: 1 / 1 });
    const [photoData, setPhotoData] = useState<string | ArrayBuffer>(null);
    const [imageRef, setImageRef] = useState<HTMLImageElement>(null);
    const [croppedPhotoFile, setCroppedPhotoFile] = useState<File>(null);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [fileUploadError, setFileUploadError] = useState<boolean>(false);
    const [filename, setFilename] = useState<string>(undefined);

    const [getPresignedUrlS3, { data }] = useLazyQuery<{
        getPresignedUrlS3: string;
    }>(USER_POOL_GET_PRESIGNED_URL_S3, {
        client: UserPoolClient,
        variables: { filename, filepath: "profilePhotos" },
    });

    useEffect(() => {
        if (data && croppedPhotoFile) {
            (async function uploadProfilePhotoToS3(): Promise<void> {
                try {
                    const presignedUrl: string = data.getPresignedUrlS3
                        .split("url=")[1]
                        .slice(0, -1);

                    await fetch(presignedUrl, {
                        method: "PUT",
                        body: croppedPhotoFile,
                        headers: {
                            "X-Amz-ACL": "public-read",
                        },
                    });

                    const fileUrl = presignedUrl.split("?")[0];
                    setTimeout(() => updateUser({ profilePhotoUrl: fileUrl }), 2000);
                } catch (err) {
                    resetPhoto();
                    setFileUploadError(true);
                }
            })();
        }
    }, [data]);

    const resetPhoto = () => {
        setCrop({ aspect: 1 / 1 });
        setPhotoData(null);
        setImageRef(null);
        setCroppedPhotoFile(null);
    };

    const handleFile = (file: Blob) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => setPhotoData(reader.result));
        reader.readAsDataURL(file);
    };

    const onLoad = useCallback((image: HTMLImageElement) => {
        setImageRef(image);
    }, []);

    const makeClientCrop = async (crop: Crop) => {
        if (imageRef && crop.width && crop.height) {
            getCroppedImg(imageRef, crop);
        }
    };

    const getCroppedImg = (image: HTMLImageElement, crop: Crop) => {
        const canvas: HTMLCanvasElement = document.createElement("canvas");
        const scaleX: number = image.naturalWidth / image.width;
        const scaleY: number = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx: CanvasRenderingContext2D = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        const reader = new FileReader();

        canvas.toBlob((blob: Blob) => {
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                dataURLToFile(reader.result, filename);
            };
        });
    };

    const dataURLToFile = (dataURL, filename: string) => {
        const arr: string[] = dataURL.split(",");
        const bstr: string = atob(arr[1]);
        let n: number = bstr.length;
        const u8arr = new Uint8Array(n);

        const mime: string = arr[0].match(/:(.*?);/)[1];

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        const croppedImage = new File([u8arr], filename, { type: mime });

        setCroppedPhotoFile(croppedImage);
    };

    const onSubmitCroppedPhotoUpload = async () => {
        setFilename(`${Date.now()}-profile-photo.png`);
        setSubmitted(true);
        getPresignedUrlS3();
    };

    return (
        <Paper
            className={classes.paper}
            style={
                containerSwitchSize
                    ? {}
                    : photoData
                    ? { height: 525, alignItems: "flex-start" }
                    : { height: 320 }
            }
        >
            {photoData ? (
                <motion.div initial="out" animate="in" exit="out" variants={pageVariants}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        className={classes.container}
                    >
                        <Grid
                            container
                            item
                            justifyContent="center"
                            alignItems="center"
                            lg={6}
                            className={classes.cropContainer}
                        >
                            <Grid />
                            <ReactCrop
                                src={photoData}
                                crop={crop}
                                minWidth={36}
                                minHeight={36}
                                maxWidth={containerSwitchSize ? 292 : 272}
                                maxHeight={containerSwitchSize ? 292 : 272}
                                onImageLoaded={onLoad}
                                onChange={(crop: Crop) => setCrop(crop)}
                                onComplete={makeClientCrop}
                            />
                        </Grid>
                        {submitted ? (
                            <Grid
                                container
                                item
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                md={6}
                                style={containerSwitchSize ? {} : { marginTop: 48 }}
                            >
                                <Typography className={classes.uploadingText}>
                                    {t("modals.upload.uploading")}
                                </Typography>
                                <LoadingSpinner size={75} />
                            </Grid>
                        ) : (
                            <Grid
                                container
                                item
                                direction="column"
                                justifyContent={
                                    containerSwitchSize ? "center" : "flex-start"
                                }
                                alignItems="center"
                                sm={containerSwitchSize ? 6 : 12}
                                md={6}
                                className={classes.buttonContainer}
                            >
                                <Grid
                                    item
                                    style={containerSwitchSize ? {} : { marginTop: 12 }}
                                >
                                    <Typography className={classes.cropPhotoText}>
                                        {t("modals.upload.cropAndUpload")}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <DialogButton
                                        colorVariant="white"
                                        width={209}
                                        height={47}
                                        borderRadius={0}
                                        onClick={resetPhoto}
                                        style={{ marginBottom: 16 }}
                                    >
                                        {t("modals.upload.selectAnotherPhoto")}
                                    </DialogButton>
                                </Grid>
                                <Grid item>
                                    <DialogButton
                                        colorVariant="black"
                                        width={209}
                                        height={47}
                                        borderRadius={0}
                                        disabled={croppedPhotoFile ? false : true}
                                        onClick={onSubmitCroppedPhotoUpload}
                                    >
                                        {t("modals.upload.uploadPhoto")}
                                    </DialogButton>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </motion.div>
            ) : (
                <motion.div initial="out" animate="in" exit="out" variants={pageVariants}>
                    {fileUploadError && (
                        <Error center>{t("common:errors.fileUploadError")}</Error>
                    )}
                    <Dropzone onDrop={(profilePic: Blob[]) => handleFile(profilePic[0])}>
                        {({ getRootProps, getInputProps }) => (
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                className={classes.container}
                                style={{ border: "2px dashed #D4D4D4" }}
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <Grid item>
                                    <CloudUploadIcon
                                        width={107}
                                        fill="black"
                                        className={classes.cloudUploadIcon}
                                    />
                                </Grid>
                                {containerSwitchSize && (
                                    <>
                                        <Grid item>
                                            <Typography className={classes.dragDropText}>
                                                {t("modals.upload.dragAndDrop")}
                                            </Typography>
                                        </Grid>
                                        <DividerLineWithText
                                            text={t("modals.upload.or")}
                                            width={209}
                                        />
                                    </>
                                )}
                                <Grid item className={classes.selectFileButtonContainer}>
                                    <DialogButton
                                        colorVariant="black"
                                        width={209}
                                        height={47}
                                        borderRadius={0}
                                    >
                                        {t("modals.upload.selectFromFiles")}
                                    </DialogButton>
                                </Grid>
                            </Grid>
                        )}
                    </Dropzone>
                </motion.div>
            )}
        </Paper>
    );
});
