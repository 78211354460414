import React, { useState } from "react";

import { useMutation, UserPoolClient } from "@apollo";
import {
  MUTATION_REMOVE_LINKED_CARD,
  SCREEN_GET_PROFILE_LINKED_CARDS_DATA,
} from "@queries";

// Misc Imports
import { Assets } from "@assets";
import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";
import { ModalHelper } from "@utils";

// Component imports
import { DialogButton, LoadingSpinner } from "@components";
import { Grid, Paper, Typography } from "@material";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: 446,
    height: 404,
    [theme.breakpoints.down("xs")]: {
      width: 320,
    },
  },
  iconContainer: {
    marginTop: 8,
    width: 150,
    height: 100,
  },
  deleteCardIcon: {
    width: 150,
    height: 100,
  },
  confirmUnlinkTest: {
    width: 316,
    [theme.breakpoints.down("xs")]: {
      width: 240,
    },
  },
  confirmationText: {
    marginBottom: 30,
    marginTop: 20,
    fontSize: 24,
    textAlign: "center",
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  menuButton: {
    marginBottom: 8,
    width: 284,
    [theme.breakpoints.down("xs")]: {
      width: 240,
    },
  },
}));

type Props = {
  cardId: string;
  externalId: string;
};

export function LinkedCardDeleteModal({ cardId, externalId }: Props) {
  const classes: any = useStyles({});
  const { t } = useTranslation("profile");

  const [deleting, setDeleting] = useState<boolean>(false);

  const [removeLinkedCard] = useMutation(MUTATION_REMOVE_LINKED_CARD, {
    client: UserPoolClient,
    variables: { cardId, externalId },
    optimisticResponse: {
      removeLinkedCard: { cardId, externalId },
    },
    refetchQueries: [
      {
        query: SCREEN_GET_PROFILE_LINKED_CARDS_DATA,
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      ModalHelper.close();
    },
    onError: (err) => {
      setDeleting(false);
    },
  });

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        item
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <Grid container className={classes.iconContainer}>
          <img
            src={Assets.DELETE_CARD}
            className={classes.deleteCardIcon}
            alt="delete-credit-card-icon"
          />
        </Grid>
        <Grid className={classes.confirmUnlinkTest}>
          <Typography className={classes.confirmationText}>
            {t("modals.deleteLinkedCard.deleteConfirmation")}
          </Typography>
        </Grid>
        <Grid>
          <Grid className={classes.menuButton}>
            <DialogButton
              colorVariant="black"
              width={"100%"}
              onClick={() => {
                setDeleting(true);
                removeLinkedCard();
              }}
            >
              {deleting ? (
                <LoadingSpinner size={24} color="white" />
              ) : (
                t("modals.deleteLinkedCard.yesUnlink")
              )}
            </DialogButton>
          </Grid>
          <Grid className={classes.menuButton}>
            <DialogButton
              colorVariant="white"
              width={"100%"}
              onClick={ModalHelper.close}
            >
              {t("modals.deleteLinkedCard.noKeepLinked")}
            </DialogButton>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
