import { CurrencyTypes } from "./currency-types";
import { BTCFiatAmount } from "./btc-fiat-amount";

interface AmountConstructor {
  value: number;
  currency?: CurrencyTypes;
}

interface PConvert<T> {
  fromAmount: T;
  toCurrency: CurrencyTypes;
  exchangeRate: number;
}

interface PCurrency {
  currency: CurrencyTypes;
}

interface PCurrencyValue extends PCurrency {
  value: number;
}

interface PSum<T> {
  amounts: T[];
}

export class Amount {
  value: number;
  currency: CurrencyTypes;

  constructor({ value = null, currency = null }: AmountConstructor) {
    this.value = Math.floor(value);
    this.currency = currency;
  }

  static getScaledAmount({ currency, value }: PCurrencyValue): Amount {
    const factor = Math.pow(10, Amount.getScale({ currency }));

    return new Amount({
      currency,
      value: value * factor,
    });
  }

  static getScale({ currency }: PCurrency): number {
    if (currency && currency.toUpperCase() === CurrencyTypes.BTC) {
      return 8;
    }

    return 2;
  }

  static multiply(base: Amount, factor: number): Amount {
    return new Amount({
      value: base.value * factor,
      currency: base.currency,
    });
  }

  /**
   * @public
   * Converts the given currency to another
   *
   * @param param.fromAmount - The fiat amount to convert.
   * @param param.toCurrency - The currency type to convert to. See {@link @common-core#CurrencyTypes}
   * @param param.exchangeRate - The exchange rate to apply when converting the fromAmount
   *
   * @returns an object of type Amount
   */
  static convert({ fromAmount, toCurrency, exchangeRate }: PConvert<Amount>): Amount {
    return Amount.getScaledAmount({
      value: fromAmount.unscaledValue * exchangeRate,
      currency: toCurrency,
    });
  }

  static add(base: Amount, amountToAdd: Amount): Amount {
    if (!base || !amountToAdd || base.currency !== amountToAdd.currency) {
      throw new Error("InvalidOperation: Trying to add amount with different currency");
    }
    return new Amount({
      value: base.value + amountToAdd.value,
      currency: base.currency,
    });
  }

  static subtract(base: Amount, amountToSubtract: Amount): Amount {
    if (!base || !amountToSubtract || base.currency !== amountToSubtract.currency) {
      throw new Error("InvalidOperation: Trying to add amount with different currency");
    }
    return new Amount({
      value: base.value - amountToSubtract.value,
      currency: base.currency,
    });
  }

  get scale(): number {
    return Amount.getScale({ currency: this.currency });
  }

  get unscaledValue(): number {
    const factor = Math.pow(10, Amount.getScale({ currency: this.currency }));

    return this.value / factor;
  }

  static calculateTotalInFiat(amounts: BTCFiatAmount[]): number {
    return amounts.reduce((output: number, amount) => {
      const { value } = amount.fiat;
      return output + Number(value);
    }, 0);
  }

  /**
   * @public
   * Description here...
   *
   * @param locale
   * @returns
   */
  static sum({ amounts }: PSum<Amount>): Amount {
    if (amounts.length == 0) {
      return new Amount({
        value: 0,
      });
    }

    return amounts.reduce((a: Amount, b: Amount) => Amount.add(a, b));
  }

  /**
   * @public
   * Description here...
   *
   * @param locale
   * @returns
   */
  toFormattedString(locale = "cad"): string {
    const value = this.unscaledValue;

    if (locale === "cad") {
      return `${value.toFixed(2)}`;
    }

    if (this.currency) {
      return value.toLocaleString(locale, {
        style: "currency",
        currency: this.currency,
        maximumFractionDigits: this.scale,
      });
    } else {
      return value.toLocaleString(locale, {
        maximumFractionDigits: this.scale,
      });
    }
  }
}
