import { graphql, useStaticQuery } from "gatsby";
import { createPayoutText } from "@utils";

export const useOffers = (): {
  offers: StaticOffer[];
  displayOrders: DisplayOrder[];
} => {
  const {
    coinmiles: {
      offersResponse: { offers, displayOrders },
    },
  } = useStaticQuery<StaticOfferQueryResults>(graphql`
    {
      coinmiles {
        offersResponse: getAllOffersWebApp {
          offers {
            id
            advertiserName
            affiliateNetwork
            trackingUrl
            advertiserUrl
            advertiserDescription
            terms
            displayOrder
            activeDate
            payoutType
            categories
            payoutValue
            divisor
            advertiserLogo
            backgroundImageUrl
            serviceArea
            backgroundImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 490, maxHeight: 358, quality: 85) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            advertiserLogoImage {
              localFile {
                childImageSharp {
                  fluid(maxHeight: 110, maxWidth: 110, quality: 85) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          displayOrders {
            countryCode
            displayOrder {
              id
            }
          }
        }
      }
    }
  `);

  const mappedStaticOffers: StaticOffer[] = offers?.map((offer: ProcessedOffer) => ({
    id: offer.id,
    advertiserName: offer.advertiserName,
    affiliateNetwork: offer.affiliateNetwork,
    trackingUrl: offer.trackingUrl,
    advertiserUrl: offer.advertiserUrl,
    advertiserDescription: offer.advertiserDescription,
    terms: offer.terms,
    categories: offer.categories,
    displayOrder: offer.displayOrder,
    activeDate: offer.activeDate,
    payoutValue: offer.payoutValue,
    serviceArea: offer.serviceArea,
    payoutText: createPayoutText(offer),
    backgroundImage: offer?.backgroundImage?.localFile?.childImageSharp?.fluid || null,
    advertiserLogoImage:
      offer?.advertiserLogoImage?.localFile?.childImageSharp?.fluid || null,
  }));

  return { offers: mappedStaticOffers, displayOrders };
};
