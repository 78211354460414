import React from "react";

// Component imports
import { SearchIcon } from "@icons";
import { TextField, InputAdornment } from "@material";

// Misc Imports

import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";

const useStyles = (styleProps) =>
  makeStyles((theme: Theme) => ({
    searchBar: {
      width: styleProps.width || "100%",
      maxWidth: styleProps.maxWidth || "100%",
    },
    root: {
      backgroundColor: theme.palette.colors.background.lightGrey,
      minHeight: 44,
      maxHeight: 44,
      padding: 8,
      borderRadius: 4,
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
      "&&&&:hover:before": {
        borderBottom: "none",
      },
    },
  }));

type Props = {
  width?: number | string;
  maxWidth?: number | string;
  onChange?: any;
};

export function SearchField({ width, maxWidth, onChange }: Props) {
  const styleProps = { width, maxWidth };
  const classes: any = useStyles(styleProps)({});
  const { t } = useTranslation("common");

  return (
    <TextField
      id="offer-search"
      type="search"
      placeholder={t("search")}
      onChange={onChange}
      className={classes.searchBar}
      InputProps={{
        classes: { root: classes.root },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon width={16} style={{ marginLeft: 3, marginRight: 5 }} />
          </InputAdornment>
        ),
      }}
    />
  );
}
