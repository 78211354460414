import React, { useState, MouseEvent } from "react";

// Component imports
import { ExpandMoreIcon } from "@icons";
import { List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem } from "@material";

// Misc Imports
import { Assets } from "@assets";
import { useTranslation } from "@i18n";
import { withStyles } from "@styles";

const StyledListItem = withStyles((theme) => ({
  root: {
    display: "flex",
    width: 186,
    marginRight: 20,
    padding: 8,
    justifyContent: "space-between",
    position: "relative",
    bottom: 0,
    border: `1px solid ${theme.palette.colors.border.light}`,
    borderRadius: 4,
    height: 44,
    transitionProperty: "background-color",
    transitionDuration: "0.3s",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      marginRight: 4,
    },
    [theme.breakpoints.down(350)]: {
      paddingRight: 0,
    },
    "&:focus": {
      backgroundColor: `${theme.palette.colors.border.light} !important`,
      transitionProperty: "background-color",
      transitionDuration: "0.3s",
    },
  },
}))(ListItem);

const StyledListItemIcon = withStyles((theme) => ({
  root: {
    minWidth: 20,
    maxWidth: 20,
    marginRight: 8,
    [theme.breakpoints.down("xs")]: {
      marginRight: 4,
    },
  },
}))(ListItemIcon);

const StyledListItemText = withStyles((theme) => ({
  root: {
    paddingRight: 0,
    paddingLeft: 0,
  },
  primary: {
    fontSize: 15,
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
    [theme.breakpoints.down(350)]: {
      fontSize: 11,
    },
  },
}))(ListItemText);

const StyledMenu = withStyles((theme) => ({
  paper: {
    marginTop: 8,
    border: `1px solid ${theme.palette.colors.border.light}`,
    [theme.breakpoints.up("md")]: {
      paddingTop: 0,
    },
  },
}))(Menu);

const StyledMenuItem = withStyles((theme) => ({
  root: {
    transitionProperty: "background-color",
    transitionDuration: "0.3s",
    "&:hover": {
      backgroundColor: `${theme.palette.colors.background.lightGrey} !important`,
      transitionProperty: "background-color",
      transitionDuration: "0.3s",
    },
  },
  selected: {
    backgroundColor: `${theme.palette.colors.border.light} !important`,
  },
}))(MenuItem);

type Props = {
  category: string;
  setFilters: any;
};

type OfferCategory = { id: string; name: string };

enum OfferCategoryTypes {
  All = "ALL",
  Mens = "MENS",
  Womens = "WOMENS",
  ActivitiesEvents = "ACTIVITIES_EVENTS",
  SportsOutdoor = "SPORTS_OUTDOOR",
  HomeGarden = "HOME_GARDEN",
  Electronics = "ELECTRONICS",
  HotelTravel = "HOTEL_TRAVEL",
  BeautyWellness = "BEAUTY_WELLNESS",
  BabyKids = "BABY_KIDS",
  FoodGrocery = "FOOD_GROCERY",
  Education = "EDUCATION",
  FinanceAndCrypto = "FINANCE_CRYPTO",
  Luxury = "LUXURY",
}

export function CategoryDropdown({ category, setFilters }: Props) {
  const { t } = useTranslation(["onlineScreen", "common"]);

  const [categoryAnchorEl, setCategoryAnchorEl] = useState<null | HTMLElement>(null);

  const offerCategoryTypes: OfferCategory[] = [
    {
      id: OfferCategoryTypes.All,
      name: "All Offers",
    },
    {
      id: OfferCategoryTypes.Mens,
      name: "Men's",
    },
    {
      id: OfferCategoryTypes.Womens,
      name: "Women's",
    },
    {
      id: OfferCategoryTypes.ActivitiesEvents,
      name: "Activities & Events",
    },
    {
      id: OfferCategoryTypes.SportsOutdoor,
      name: "Sports & Outdoor",
    },
    {
      id: OfferCategoryTypes.HomeGarden,
      name: "Home & Garden",
    },
    {
      id: OfferCategoryTypes.Electronics,
      name: "Electronics",
    },
    {
      id: OfferCategoryTypes.HotelTravel,
      name: "Hotel & Travel",
    },
    {
      id: OfferCategoryTypes.BeautyWellness,
      name: "Beauty & Wellness",
    },
    {
      id: OfferCategoryTypes.BabyKids,
      name: "Baby & Kids",
    },
    {
      id: OfferCategoryTypes.FoodGrocery,
      name: "Food & Grocery",
    },
    {
      id: OfferCategoryTypes.Education,
      name: "Education",
    },
    {
      id: OfferCategoryTypes.FinanceAndCrypto,
      name: "Finance & Crypto",
    },
    {
      id: OfferCategoryTypes.Luxury,
      name: "Luxury",
    },
  ];

  // Maps translations of category names
  // Also maps Icon emojis to array from data imported from affiliates service
  const categoryOptions: FilterOption[] = offerCategoryTypes.map(
    (category: FilterOption) => {
      return {
        id: category.id,
        name: t(`filtersHeader.categories.${category.id}`),
        icon: Assets[category.id],
      };
    },
  );

  const handleClickCategoryMenu = (event: MouseEvent<HTMLElement>) => {
    setCategoryAnchorEl(event.currentTarget);
  };

  const handleCategoryMenuClose = () => {
    setCategoryAnchorEl(null);
  };

  const handleCategorySelect = (event: MouseEvent<HTMLElement>, newCategory: string) => {
    // handleCategoryModalClose();
    newCategory !== null &&
      setFilters((prevState) => ({
        ...prevState,
        category: newCategory,
      }));
    setCategoryAnchorEl(null);
  };

  return (
    <>
      <List aria-label="categories" disablePadding={true}>
        <StyledListItem button onClick={handleClickCategoryMenu}>
          <StyledListItemIcon>
            <img
              src={Assets[category]}
              width={20}
              height={20}
              style={{ marginRight: 0 }}
              alt={`${category}-current-emoji`}
            />
          </StyledListItemIcon>
          <StyledListItemText
            primary={categoryOptions.find((trans) => trans.id === category).name}
          />
          <ExpandMoreIcon width={8} style={{ marginRight: 4 }} />
        </StyledListItem>
      </List>
      <StyledMenu
        id="categories-menu"
        anchorEl={categoryAnchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(categoryAnchorEl)}
        onClose={handleCategoryMenuClose}
        transitionDuration={600}
      >
        {categoryOptions.map((categoryOption: FilterOption, index: number) => (
          <StyledMenuItem
            key={index + 1}
            selected={categoryOption.id === category}
            value={categoryOption.name}
            onClick={(event) => handleCategorySelect(event, categoryOption.id)}
          >
            {categoryOption.icon && (
              <img
                src={categoryOption.icon}
                width={20}
                style={{ marginRight: 8 }}
                alt={`${categoryOption.id}-emoji`}
              />
            )}
            {categoryOption.name}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
}
