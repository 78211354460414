// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-change-password-tsx": () => import("./../../../src/pages/change-password.tsx" /* webpackChunkName: "component---src-pages-change-password-tsx" */),
  "component---src-pages-confirmation-tsx": () => import("./../../../src/pages/confirmation.tsx" /* webpackChunkName: "component---src-pages-confirmation-tsx" */),
  "component---src-pages-gift-cards-tsx": () => import("./../../../src/pages/gift-cards.tsx" /* webpackChunkName: "component---src-pages-gift-cards-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mobile-chatflow-tsx": () => import("./../../../src/pages/mobile-chatflow.tsx" /* webpackChunkName: "component---src-pages-mobile-chatflow-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-redirect-tsx": () => import("./../../../src/pages/redirect.tsx" /* webpackChunkName: "component---src-pages-redirect-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-travel-index-tsx": () => import("./../../../src/pages/travel/index.tsx" /* webpackChunkName: "component---src-pages-travel-index-tsx" */),
  "component---src-pages-travel-result-tsx": () => import("./../../../src/pages/travel/result.tsx" /* webpackChunkName: "component---src-pages-travel-result-tsx" */),
  "component---src-pages-wallet-tsx": () => import("./../../../src/pages/wallet.tsx" /* webpackChunkName: "component---src-pages-wallet-tsx" */),
  "component---src-pages-what-i-like-tsx": () => import("./../../../src/pages/what-i-like.tsx" /* webpackChunkName: "component---src-pages-what-i-like-tsx" */)
}

