import { makeVar } from "@apollo/client";
import { User } from "@models";
import { CognitoUser } from "amazon-cognito-identity-js";

const currentCountryInitialValue: AffiliateServiceArea = null;
export const currentCountryVar = makeVar(currentCountryInitialValue);

const currentUserInitialValue: User = null;
export const currentUserVar = makeVar(currentUserInitialValue);

const modalStateInitialValue: ModalState = {
  modalType: null,
  modalProps: null,
  modalOptions: null,
};
export const modalStateVar = makeVar(modalStateInitialValue);

const bonusSatsInitialValue = 1000;
export const bonusSatsStateVar = makeVar(bonusSatsInitialValue);

const cognitoUserInitialValue: CognitoUser = undefined;
export const cognitoUserStateVar = makeVar(cognitoUserInitialValue);

export const cvtTokenVar = makeVar<string>(null);
