import { useEffect, useRef, useState } from "react";
import {
  currentUserVar,
  useReactiveVar,
  UserPoolClient,
  useQuery,
  cvtTokenVar,
  currentCountryVar,
} from "@apollo";
import { USER_POOL_GET_CVT_TOKEN } from "@queries";
import { navigate } from "@utils";
import { i18n, useTranslation } from "@i18n";

import { Form } from "antd";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import "dayjs/locale/fr-ca";
import FR from "antd/lib/locale/fr_FR";
import EN from "antd/lib/locale/en_US";
import PT from "antd/lib/locale/pt_BR";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { sendSegmentScreenEvent, sendSegmentTrackEvent } from "@helpers";

export interface useTravelScreenInterface {
  user: any;
  t: any;
  searchForm: any;
  geocoderRef: any;
  onSubmitSearch: () => any;
  calendarLocale: any;
  disabledDates: any;
  setDates: any;
  onCalendarDatesChange: (date: any, dateString: any) => void;
  roomGuestProps: any;
  isCounterVisible: any;
}

export const useTravelScreen = (): useTravelScreenInterface => {
  const user = useReactiveVar(currentUserVar);
  const currentLanguage = i18n.language;
  const { t } = useTranslation("travel");
  const localStorageLanguage: string = localStorage.getItem("i18nextLng");
  const [sessionToken, setSessionToken] = useState(null);
  const [destination, setDestination] = useState(null);
  const [latLng, setLatLng] = useState({});
  const [dates, setDates] = useState([]);
  const [calendarLocale, setCalendarLocale] = useState(null);
  const [checkIn, setCheckIn] = useState(null);
  const [checkOut, setCheckOut] = useState(null);
  const [roomCount, setRoomcount] = useState(1);
  const [guestCount, setGuestcount] = useState(1);
  const [isCounterVisible, setCounterVisible] = useState(false);
  const [searchForm] = Form.useForm();
  const country = useReactiveVar(currentCountryVar);

  const geocoderRef = useRef();
  const geocoder = new MapboxGeocoder({
    accessToken: process.env.GATSBY_MAPBOX_API_TOKEN,
    types: "place",
    language: currentLanguage,
    placeholder: t("inputDestination"),
  });

  const { data: dataCVT, loading: cvtLoading } = useQuery<any>(USER_POOL_GET_CVT_TOKEN, {
    client: UserPoolClient,
    fetchPolicy: "network-only",
  });

  const getCalendarLocale = () => {
    if (!localStorageLanguage) {
      setCalendarLocale(EN);
    } else {
      if (localStorageLanguage === "fr") {
        setCalendarLocale(FR);
      } else if (localStorageLanguage === "pt") {
        setCalendarLocale(PT);
      } else {
        setCalendarLocale(EN);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!user) navigate("/sign-in", { replace: true });
    else if (user) {
      sendSegmentScreenEvent("travelScreen", {
        email: user.emailAddress,
        firstName: user.firstName,
        lastName: user.lastName,
        country: country ? country.name : "",
      });
    }
    getCalendarLocale();
  }, []);

  useEffect(() => {
    if (dataCVT) {
      cvtTokenVar(dataCVT.token);
    }
  }, [cvtLoading]);

  useEffect(() => {
    setSessionToken(uuidv4().toString());
  }, [latLng]);

  useEffect(() => {
    if (geocoder && geocoderRef.current) {
      geocoder.addTo(geocoderRef.current);

      geocoder.on("result", (e) => {
        setDestination(e.result.text);

        setLatLng({
          lat: e.result.geometry.coordinates[1],
          lng: e.result.geometry.coordinates[0],
        });
      });

      geocoder.on("clear", () => {
        setDestination(null);
        setLatLng(null);
        !sessionToken ? setSessionToken(null) : null;
      });
    }
  }, [geocoderRef]);

  const onCalendarDatesChange = (date, dateString): void => {
    // reset to disabledDates() when user change a date
    if (dateString?.filter((e: boolean) => String(e).trim()) == "") setDates([]);
    setCheckIn(date ? date[0].format("YYYY-MM-DD") : null);
    setCheckOut(date ? date[1].format("YYYY-MM-DD") : null);
  };

  const disabledDates = (current: any): boolean => {
    if (!dates) return false;

    const beforeCurrent = current && current < dayjs().startOf("day");
    const tooLate = current && current.diff(dayjs().endOf("day"), "days") > 365;
    return beforeCurrent || tooLate;
  };

  const roomGuestCounter = (operation: string, counter: any, limit?: number): void => {
    if (operation.includes("increment")) {
      return counter((prevCount: number) => Math.min(prevCount + 1, limit));
    }
    return counter((prevCount: number) => Math.max(prevCount - 1, 1));
  };

  const roomGuestCounterVisibility = (
    flag: boolean | ((prevState: boolean) => boolean),
  ): void => {
    setCounterVisible(flag);
  };

  const closeRoomGuestCounter = (e: { key: string }) => {
    if (e.key === "3") {
      setCounterVisible(false);
    }
  };

  const counterStyle = (count: number, num: number): boolean => count === num;

  const resetSearchFields = (): void => {
    setDestination(null);
    setCheckIn(null);
    setCheckOut(null);
    setDates([]);
    setGuestcount(1);
    setRoomcount(1);
    searchForm ? searchForm.resetFields() : null;
  };

  const roomGuestProps = {
    roomCount,
    setRoomcount,
    guestCount,
    setGuestcount,
    roomGuestCounter,
    closeRoomGuestCounter,
    roomGuestCounterVisibility,
    isCounterVisible,
    counterStyle,
  };

  const searchParams: any = {
    destination,
    checkIn,
    checkOut,
    guestCount,
    roomCount,
    latLng,
  };

  const onSubmitSearch = (): void => {
    if (!dataCVT || !destination || !checkIn || !checkOut || !latLng) return;

    sendSegmentTrackEvent("travelSearchDetails", {
      destination,
    });

    navigate("/travel/result", { replace: false, state: { searchParams } });
    resetSearchFields();
  };

  return {
    user,
    t,
    searchForm,
    geocoderRef,
    onSubmitSearch,
    calendarLocale,
    disabledDates,
    setDates,
    onCalendarDatesChange,
    roomGuestProps,
    isCounterVisible,
  };
};
