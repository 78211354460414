export const fr = {
  signUp: "Créer un compte pour continuer",
  signUpWithEmail: "Créer un compte avec un courriel",
  signUpDisclaimer: {
    one: "En me créant un compte, j’accepte les termes et conditions",
    two: " de Coinmiles",
    three: " ainsi que",
    four: " sa politique de confidentialité",
    five: ". Coinmiles n’est disponible qu’au Canada en ce moment.",
  },
  alreadyMember: "Vous avez déjà un compte?",
  modal: {
    earnAt: "Activer l’offre de",
    weNeedToKnow: {
      one: "Nous devons savoir qui doit être récompensé.",
      two: "Bonne nouvelle, lorsque vous créez un compte ",
      three: "nous allons automatiquement déposer ",
      four: " Sats dans votre portefeuille!",
    },
  },
  drawer: {
    letsCheckOut: "Allons-y, regardons les offres",
    aCoolGift: "Voici un cadeau!",
    loginToViewDeals: {
      one: "Connectez vous pour accéder à l'offre. C'est ",
      two: "rapide, gratuit ",
      three: "et vous recevrez ",
      four: " Sats comme cadeau de bienvenue.",
    },
  },
  landingPage: {
    header: {
      verified1: "MERCI",
      verified2: "D'AVOIR",
      verified3: "REJOINT",
      verified4: "COINMILES!",
      expiredCode: "Lien invalide",
      invalidCode: "Lien invalide",
      error: "Une erreur s'est produite",
    },
    verified:
      "Votre compte est maintenant créé! Vous pouvez maintenant vous connecter avec ",
    expiredCode:
      "Votre lien de confirmation est invalide ou expiré. Pour obtenir de l'assistance, veuillez consulter notre support technique.",
    invalidCode:
      "Le code de confirmation n'est pas valide. Pour obtenir de l'assistance, veuillez consulter notre support technique.",
    error: "Une erreur s'est produite. Veuillez réessayer.",
    button: {
      verified: "Se connecter",
      expiredCode: "Contacter le support",
      invalidCode: "Contacter le support",
      backToHome: "Retour à la page d'accueil",
      openWithApp: "Retourner dans l'appli",
      openWithWebsite: "Se connecter",
    },
  },
  success: {
    thanks: "Bienvenue chez Coinmiles!",
    confirmation: {
      one: "Nous avons transmis un courriel de confirmation à:  ",
      two:
        "Veuillez s'il vous plait confirmer votre compte pour commencer à utiliser Coinmiles.",
    },
    didntReceive: "Vous n'avez pas reçu de courriel?",
    sendAgain: "Transmettre de nouveau",
    ok: "OK",
    confirmAndContinue: "Envoyer de nouveau",
    confirmationResent: "Courriel de confirmation",
  },
};
