import { makeStyles } from "@styles";

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundImage: `url("https://access-travel-iframe.s3.ca-central-1.amazonaws.com/travel-pool-2x-min.png")`,
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    transitionProperty: "background-color, color",
    transitionDuration: "0.4s",
    transitionTimingFunction: "ease-out",
  },
  mainGrid: {
    alignItems: "center",
    justifyContent: "center",
  },
  leftGrid: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "-2em",
      marginBottom: "3em",
    },
  },
  textContainer: {
    display: "flex",
    justifyContent: "center",
    textShadow: "black 1px 0 10px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  column: {
    flex: "1",
  },
  centerContent: {
    display: "flex",
    justifyContent: "center",
  },
  contentTitleOne: {
    justifyContent: "left",
    color: "white",
    fontSize: "7vmin",
    fontWeight: "bold",
    lineHeight: 1.2,
    [theme.breakpoints.up("xl")]: {
      fontSize: "6.7vmin",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "7.8vmin",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "48px",
    },
  },
  contentTitleTwo: {
    whiteSpace: "pre-line",
    textAlign: "left",
    justifyContent: "left",
    color: "#ededed",
    fontSize: "7vmin",
    fontWeight: "bold",
    lineHeight: 1.2,
    [theme.breakpoints.up("xl")]: {
      fontSize: "6.7vmin",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "7.8vmin",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "48px",
    },
  },
  cardContainer: {
    height: "47vmin",
    minWidth: "45%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    borderRadius: 16,
    [theme.breakpoints.down("xl")]: {
      height: "100%",
      maxHeight: "100%",
      minWidth: "35%",
      padding: "0.75em 2em",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "40%",
    },

    [theme.breakpoints.up(1920)]: {
      maxWidth: "40%",
    },
    [theme.breakpoints.between(1080, 1280)]: {
      maxWidth: "60%",
    },
  },
  card: {
    width: "40vmin",
    color: "black",
  },
}));
