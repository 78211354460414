export const en = {
  noUserOffersHeader: "You haven't liked any offers yet",
  noUserOffersSubheader:
    "Tap the 'heart' of an offer to pick it up and save it here. \n \n A good way to save time!",
  keepExploring: "Keep exploring",
  filters: {
    orderBy: "Order by:",
    type: "Type:",
    whatILikeOptions: {
      "All Offers": "All Offers",
      Online: "Online",
      "In-Store": "In-Store",
    },
    orderOptions: {
      Featured: "Featured",
      "Highest cashback": "Highest cashback",
      "A-Z": "A-Z",
      "Z-A": "Z-A",
      "New offers": "New offers",
    },
    dialogMessage: {
      one:
        "Get Bitcoin cashback when you pay in-store with your own credit-card.",
      two: "Only available on the Coinmiles mobile app. Coming soon to online!",
    },
  },
};
