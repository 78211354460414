import { UserPoolClient } from "@apollo";
import { USER_POOL_GET_REDIRECT_URL } from "@queries";

interface RedirectUrlParams {
  offer: StaticOffer;
}

interface RedirectUrlResponse {
  getRedirectUrl: { url: string };
}

export const createRedirectData = async ({
  offer,
}: RedirectUrlParams): Promise<RedirectData> =>
  new Promise<RedirectData>(async (resolve, reject) => {
    let attempts = 3;

    while (attempts > 0) {
      try {
        const { data } = await UserPoolClient.query<RedirectUrlResponse>({
          query: USER_POOL_GET_REDIRECT_URL,
          variables: { offerId: offer.id },
        });
        const {
          getRedirectUrl: { url: redirectUrl },
        } = data;

        resolve({
          redirectUrl,
          advertiserLogoImage: offer.advertiserLogoImage,
          payoutText: offer.payoutText,
          referrer: "offer-redirect",
          offerId: offer.id,
        });
        break;
      } catch (error) {
        attempts -= 1;

        if (attempts <= 0)
          reject(`Unable to fetch redirect URL for ${offer.advertiserName}: ${error}`);
      }
    }
  });
