export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Query = {
  __typename?: "Query";
  getActiveAdBannerGroups?: Maybe<Array<Maybe<IAdBannerGroup>>>;
  getAllCampaigns?: Maybe<Array<Maybe<Campaign>>>;
  getAllOffersMobile?: Maybe<Array<Maybe<Offer>>>;
  getAllOffersWebApp?: Maybe<WebAppOffers>;
  getBonusSats?: Maybe<Scalars["Int"]>;
  getExchangeRate?: Maybe<ExchangeRate>;
  getGeometryByLocation?: Maybe<CityLocation>;
  getLinkedCards?: Maybe<Array<Maybe<Card>>>;
  getLocationSuggestionsByCity?: Maybe<Array<Maybe<FormattedCitySuggestion>>>;
  getOffersByCountry?: Maybe<Array<Maybe<Offer>>>;
  getOfferTrackingUrlClicksByUser?: Maybe<Array<Maybe<OfferTrackingUrlClick>>>;
  getPresignedUrlS3?: Maybe<Scalars["String"]>;
  getPriceHistory?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  getRedirectUrl?: Maybe<Url>;
  getReferralSats?: Maybe<Scalars["Int"]>;
  getTravelCVTToken?: Maybe<Scalars["String"]>;
  getUser?: Maybe<User>;
  getUserCampaigns?: Maybe<Array<Maybe<UserCampaign>>>;
  getUserOffers?: Maybe<Array<Maybe<Offer>>>;
  getWallet?: Maybe<WalletNew>;
};

export type QueryGetActiveAdBannerGroupsArgs = {
  countryCode?: InputMaybe<Scalars["String"]>;
};

export type QueryGetAllCampaignsArgs = {
  category?: InputMaybe<Scalars["String"]>;
  latLng?: InputMaybe<LatLng>;
};

export type QueryGetAllOffersMobileArgs = {
  country: Scalars["String"];
};

export type QueryGetExchangeRateArgs = {
  convertedCurrency: Scalars["String"];
  initialCurrency: Scalars["String"];
};

export type QueryGetGeometryByLocationArgs = {
  placeId: Scalars["ID"];
};

export type QueryGetLocationSuggestionsByCityArgs = {
  searchField?: InputMaybe<Scalars["String"]>;
  sessionToken?: InputMaybe<Scalars["String"]>;
};

export type QueryGetOffersByCountryArgs = {
  country: Scalars["String"];
};

export type QueryGetPresignedUrlS3Args = {
  bucket?: InputMaybe<ImageBuckets>;
  filename: Scalars["String"];
  filepath: Scalars["String"];
};

export type QueryGetPriceHistoryArgs = {
  convertedCurrency?: InputMaybe<Scalars["String"]>;
  initialCurrency?: InputMaybe<Scalars["String"]>;
};

export type QueryGetRedirectUrlArgs = {
  offerId: Scalars["ID"];
};

export type QueryGetWalletArgs = {
  currency?: InputMaybe<Scalars["String"]>;
};

export type IAdBannerGroup = {
  __typename?: "IAdBannerGroup";
  bannerType: AdBannerType;
  countryCode: Scalars["String"];
  displayPosition?: Maybe<Scalars["Int"]>;
  groupName: IMultilingualText;
  id?: Maybe<Scalars["String"]>;
  isActive: Scalars["Boolean"];
  offerList?: Maybe<Array<Maybe<IAdBannerListItem>>>;
};

export enum AdBannerType {
  BannerTypeA = "BannerTypeA",
  BannerTypeB = "BannerTypeB",
  BannerTypeC = "BannerTypeC",
}

export type IMultilingualText = {
  __typename?: "IMultilingualText";
  en?: Maybe<Scalars["String"]>;
  fr?: Maybe<Scalars["String"]>;
};

export type IAdBannerListItem = {
  __typename?: "IAdBannerListItem";
  name?: Maybe<Scalars["String"]>;
  offerId?: Maybe<Scalars["String"]>;
};

export type LatLng = {
  lat?: InputMaybe<Scalars["Float"]>;
  lng?: InputMaybe<Scalars["Float"]>;
};

export type Campaign = {
  __typename?: "Campaign";
  account?: Maybe<Brand>;
  accountId?: Maybe<Scalars["String"]>;
  brand?: Maybe<Brand>;
  brandId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  fees?: Maybe<Array<Maybe<Fee>>>;
  id: Scalars["ID"];
  images?: Maybe<Array<Maybe<Image>>>;
  isEcommerce?: Maybe<Scalars["Boolean"]>;
  isOpenCovid19?: Maybe<Scalars["Boolean"]>;
  location?: Maybe<BrandLocation>;
  locationId?: Maybe<Scalars["String"]>;
  locations?: Maybe<Array<Maybe<BrandLocation>>>;
  redeemValue?: Maybe<Scalars["String"]>;
  socialLinks?: Maybe<Array<Maybe<SocialLinks>>>;
  status?: Maybe<Scalars["String"]>;
  taxProvince?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
};

export type Brand = {
  __typename?: "Brand";
  createdAt?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  externalId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  images?: Maybe<Array<Maybe<Image>>>;
  industry?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  registeredAddress?: Maybe<Location>;
  registrationNumber?: Maybe<Scalars["String"]>;
  skipACH?: Maybe<Scalars["Boolean"]>;
  socialLinks?: Maybe<Array<Maybe<SocialLinks>>>;
  updatedAt?: Maybe<Scalars["String"]>;
  useLogoImage?: Maybe<Scalars["Boolean"]>;
};

export type Image = {
  __typename?: "Image";
  original?: Maybe<Scalars["String"]>;
  thumbnail?: Maybe<Scalars["String"]>;
};

export type Location = {
  __typename?: "Location";
  formattedShortAddress?: Maybe<Scalars["String"]>;
  geolocation?: Maybe<Geolocation>;
  id: Scalars["ID"];
  locality?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  route?: Maybe<Scalars["String"]>;
  streetNumber?: Maybe<Scalars["String"]>;
};

export type Geolocation = {
  __typename?: "Geolocation";
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
};

export type SocialLinks = {
  __typename?: "SocialLinks";
  isActive?: Maybe<Scalars["Boolean"]>;
  type?: Maybe<Scalars["String"]>;
  URL?: Maybe<Scalars["String"]>;
};

export type Fee = {
  __typename?: "Fee";
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["Int"]>;
};

export type BrandLocation = {
  __typename?: "BrandLocation";
  accountId?: Maybe<Scalars["String"]>;
  addressComponents?: Maybe<Array<Maybe<AddressComponent>>>;
  createdAt?: Maybe<Scalars["String"]>;
  formattedShortAddress?: Maybe<Scalars["String"]>;
  geolocation?: Maybe<Geolocation>;
  id?: Maybe<Scalars["String"]>;
  locality?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  route?: Maybe<Scalars["String"]>;
  streetNumber?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
};

export type AddressComponent = {
  __typename?: "AddressComponent";
  longName?: Maybe<Scalars["String"]>;
  shortName?: Maybe<Scalars["String"]>;
  types?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Offer = {
  __typename?: "Offer";
  activeDate?: Maybe<Scalars["String"]>;
  adBanners?: Maybe<OfferAdBanners>;
  advertiserDescription?: Maybe<Scalars["String"]>;
  advertiserId?: Maybe<Scalars["Int"]>;
  advertiserLogo?: Maybe<Scalars["String"]>;
  advertiserName?: Maybe<Scalars["String"]>;
  advertiserUrl?: Maybe<Scalars["String"]>;
  affiliateNetwork?: Maybe<Scalars["String"]>;
  backgroundImageUrl?: Maybe<Scalars["String"]>;
  categories?: Maybe<Array<Maybe<Scalars["String"]>>>;
  country?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  currency?: Maybe<Scalars["String"]>;
  deepLink?: Maybe<Scalars["Boolean"]>;
  displayOrder?: Maybe<Scalars["Int"]>;
  divisor?: Maybe<Scalars["Int"]>;
  expiresDate?: Maybe<Scalars["String"]>;
  featured?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  isNewOffer?: Maybe<Scalars["Boolean"]>;
  payout?: Maybe<Scalars["String"]>;
  payoutAmount?: Maybe<Scalars["String"]>;
  payoutType?: Maybe<Scalars["String"]>;
  payoutValue?: Maybe<Scalars["Int"]>;
  productId?: Maybe<Scalars["Int"]>;
  serviceArea?: Maybe<Array<Maybe<Scalars["String"]>>>;
  terms?: Maybe<Scalars["String"]>;
  trackingUrl?: Maybe<Scalars["String"]>;
};

export type OfferAdBanners = {
  __typename?: "OfferAdBanners";
  description?: Maybe<OfferAdBannerDescriptions>;
  imageUrls?: Maybe<OfferAdBannerImageUrls>;
};

export type OfferAdBannerDescriptions = {
  __typename?: "OfferAdBannerDescriptions";
  bannerTypeA?: Maybe<IMultilingualText>;
  bannerTypeB?: Maybe<IMultilingualText>;
  en?: Maybe<Scalars["String"]>;
  fr?: Maybe<Scalars["String"]>;
};

export type OfferAdBannerImageUrls = {
  __typename?: "OfferAdBannerImageUrls";
  bannerTypeA?: Maybe<IMultilingualText>;
  bannerTypeB?: Maybe<Scalars["String"]>;
};

export type WebAppOffers = {
  __typename?: "WebAppOffers";
  displayOrders?: Maybe<Array<Maybe<CountryDisplay>>>;
  offers?: Maybe<Array<Maybe<Offer>>>;
};

export type CountryDisplay = {
  __typename?: "CountryDisplay";
  countryCode: Scalars["String"];
  displayOrder: Array<Maybe<OfferDisplayOrder>>;
  id: Scalars["ID"];
  sectionImages?: Maybe<Array<Maybe<Image>>>;
  status: Scalars["String"];
};

export type OfferDisplayOrder = {
  __typename?: "OfferDisplayOrder";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type ExchangeRate = {
  __typename?: "ExchangeRate";
  exchangeRate?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

export type CityLocation = {
  __typename?: "CityLocation";
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
};

export type Card = {
  __typename?: "Card";
  brand?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  expMonth?: Maybe<Scalars["String"]>;
  expYear?: Maybe<Scalars["String"]>;
  externalId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  last4?: Maybe<Scalars["String"]>;
  maskedNumber?: Maybe<Scalars["String"]>;
};

export type FormattedCitySuggestion = {
  __typename?: "FormattedCitySuggestion";
  city?: Maybe<Scalars["String"]>;
  locality?: Maybe<Scalars["String"]>;
  placeId?: Maybe<Scalars["String"]>;
};

export type OfferTrackingUrlClick = {
  __typename?: "OfferTrackingUrlClick";
  createdAt?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  offer?: Maybe<Offer>;
  offerId?: Maybe<Scalars["String"]>;
  platform?: Maybe<OfferTrackingPlatform>;
  trackingUrl?: Maybe<Scalars["String"]>;
};

export enum OfferTrackingPlatform {
  Mobile = "Mobile",
  Web = "Web",
}

export enum ImageBuckets {
  profilePhoto = "profilePhoto",
  support = "support",
}

export type Url = {
  __typename?: "Url";
  url: Scalars["String"];
};

export type User = {
  __typename?: "User";
  accessRegistered?: Maybe<Scalars["Boolean"]>;
  country?: Maybe<Scalars["String"]>;
  credentials?: Maybe<Array<Maybe<UserCredentials>>>;
  didLogin?: Maybe<Scalars["Boolean"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  emailOptIn?: Maybe<Scalars["Boolean"]>;
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  language?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  metadata?: Maybe<UserMetadata>;
  profilePhotoUrl?: Maybe<Scalars["String"]>;
};

export type UserCredentials = {
  __typename?: "UserCredentials";
  type?: Maybe<Scalars["String"]>;
};

export type UserMetadata = {
  __typename?: "UserMetadata";
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  device?: Maybe<UserDeviceData>;
  language?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  webOrMobile?: Maybe<Scalars["String"]>;
};

export type UserDeviceData = {
  __typename?: "UserDeviceData";
  model?: Maybe<Scalars["String"]>;
  os?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type UserCampaign = {
  __typename?: "UserCampaign";
  campaignId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type WalletNew = {
  __typename?: "WalletNew";
  balanceIncludingFrozen?: Maybe<BtcFiatAmount>;
  balanceIncludingPendingInFiat?: Maybe<Amount>;
  balanceNotIncludingFrozen?: Maybe<BtcFiatAmount>;
  transactions?: Maybe<Array<Maybe<WalletTransaction>>>;
};

export type BtcFiatAmount = {
  __typename?: "BTCFiatAmount";
  btc?: Maybe<Amount>;
  currency?: Maybe<Scalars["String"]>;
  exchangeRate?: Maybe<Scalars["String"]>;
  fiat?: Maybe<Amount>;
  value?: Maybe<Scalars["Int"]>;
};

export type Amount = {
  __typename?: "Amount";
  currency?: Maybe<Scalars["String"]>;
  value: Scalars["Int"];
};

export type WalletTransaction = {
  __typename?: "WalletTransaction";
  amount?: Maybe<BtcFiatAmount>;
  createdAt?: Maybe<Scalars["String"]>;
  destinationWalletAddress?: Maybe<Scalars["String"]>;
  externalRef?: Maybe<WalletExternalRefs>;
  id: Scalars["ID"];
  status?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
};

export type WalletExternalRefs = {
  __typename?: "WalletExternalRefs";
  data?: Maybe<WalletExternalRefsData>;
  id: Scalars["ID"];
  logo?: Maybe<Scalars["String"]>;
  programName?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type WalletExternalRefsData = {
  __typename?: "WalletExternalRefsData";
  advertiserName?: Maybe<Scalars["String"]>;
  affiliateName?: Maybe<Scalars["String"]>;
  amount?: Maybe<BtcFiatAmount>;
  campaign?: Maybe<Campaign>;
  cardLastFour?: Maybe<Scalars["Int"]>;
  cardLinkingTransaction?: Maybe<WalletExternalRefsDataCardLinkingTransaction>;
  emailAddress?: Maybe<Scalars["String"]>;
  externalData?: Maybe<WalletExternalData>;
  hotelName?: Maybe<Scalars["String"]>;
  lockingDate?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  merchantValue?: Maybe<Scalars["String"]>;
  newUserFirstName?: Maybe<Scalars["String"]>;
  newUserId?: Maybe<Scalars["String"]>;
  newUserStatus?: Maybe<ReferralStatus>;
  passcode?: Maybe<Scalars["String"]>;
  programName?: Maybe<Scalars["String"]>;
  referrerFirstName?: Maybe<Scalars["String"]>;
  referrerId?: Maybe<Scalars["String"]>;
  referrerStatus?: Maybe<ReferralStatus>;
  spentAmount?: Maybe<Scalars["Int"]>;
  status?: Maybe<ReferralStatus>;
  totalSaleAmount?: Maybe<Scalars["Float"]>;
  type?: Maybe<Scalars["String"]>;
};

export type WalletExternalRefsDataCardLinkingTransaction = {
  __typename?: "WalletExternalRefsDataCardLinkingTransaction";
  amount?: Maybe<Amount>;
  card?: Maybe<Card>;
};

export type WalletExternalData = {
  __typename?: "WalletExternalData";
  amount?: Maybe<Amount>;
  campaign?: Maybe<Campaign>;
  offer?: Maybe<Offer>;
};

export enum ReferralStatus {
  ApprovedReferral = "ApprovedReferral",
  AwaitingNewUserForApproved = "AwaitingNewUserForApproved",
  AwaitingNewUserForCompleted = "AwaitingNewUserForCompleted",
  CompletedReferral = "CompletedReferral",
  ExpiredReferral = "ExpiredReferral",
  NewReferral = "NewReferral",
}

export type Mutation = {
  __typename?: "Mutation";
  createMissingTransaction?: Maybe<MissingTransaction>;
  createOfferTrackingUrlClick?: Maybe<Scalars["Boolean"]>;
  createReferral?: Maybe<Referral>;
  createUserCampaign?: Maybe<UserCampaign>;
  createUserOffer?: Maybe<Offer>;
  deleteUserCampaign?: Maybe<UserCampaign>;
  deleteUserOffer?: Maybe<Scalars["String"]>;
  redeemStoryReward?: Maybe<IStory>;
  removeLinkedCard?: Maybe<Card>;
  sendUserInfoToMailerLite?: Maybe<Scalars["Boolean"]>;
  transferBTC?: Maybe<WalletTransaction>;
  transferInteract?: Maybe<WalletTransaction>;
  updateUser?: Maybe<User>;
};

export type MutationCreateMissingTransactionArgs = {
  transactionInput?: InputMaybe<MissingTransactionInput>;
};

export type MutationCreateOfferTrackingUrlClickArgs = {
  offerId?: InputMaybe<Scalars["ID"]>;
  platform?: InputMaybe<Scalars["String"]>;
  trackingUrl?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateReferralArgs = {
  referrerId: Scalars["ID"];
};

export type MutationCreateUserCampaignArgs = {
  campaignId: Scalars["ID"];
};

export type MutationCreateUserOfferArgs = {
  offerId: Scalars["ID"];
};

export type MutationDeleteUserCampaignArgs = {
  campaignId: Scalars["ID"];
};

export type MutationDeleteUserOfferArgs = {
  offerId: Scalars["ID"];
};

export type MutationRedeemStoryRewardArgs = {
  affiliateId: Scalars["String"];
  countryCode: Scalars["String"];
  language: Scalars["String"];
};

export type MutationRemoveLinkedCardArgs = {
  cardId?: InputMaybe<Scalars["ID"]>;
  externalId: Scalars["ID"];
};

export type MutationSendUserInfoToMailerLiteArgs = {
  emailAddress: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type MutationTransferBtcArgs = {
  address: Scalars["String"];
  amount: Scalars["Int"];
};

export type MutationTransferInteractArgs = {
  amount?: InputMaybe<BtcFiatAmountInput>;
  emailAddress: Scalars["String"];
  notes: Scalars["String"];
  passcode: Scalars["String"];
};

export type MutationUpdateUserArgs = {
  didLogin?: InputMaybe<Scalars["Boolean"]>;
  emailAddress?: InputMaybe<Scalars["String"]>;
  emailOptIn?: InputMaybe<Scalars["Boolean"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  metadata?: InputMaybe<UserMetadataInput>;
  mobileAppVersion?: InputMaybe<UserMobileAppVersionInput>;
  profilePhotoUrl?: InputMaybe<Scalars["String"]>;
};

export type MissingTransactionInput = {
  clickId: Scalars["String"];
  currency: Scalars["String"];
  details: Scalars["String"];
  images: Array<InputMaybe<Scalars["String"]>>;
  offerId: Scalars["String"];
  orderNumber: Scalars["String"];
  platform: OfferTrackingPlatform;
  totalSaleAmount: Scalars["Float"];
  trackingUrl: Scalars["String"];
  transactionDate: Scalars["String"];
  usedAdBlock: Scalars["Boolean"];
};

export type MissingTransaction = {
  __typename?: "MissingTransaction";
  clickId?: Maybe<Scalars["String"]>;
  contact?: Maybe<ContactPerson>;
  currency?: Maybe<Scalars["String"]>;
  details?: Maybe<Scalars["String"]>;
  existsInDb?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
  images?: Maybe<Array<Maybe<Scalars["String"]>>>;
  internalNotes?: Maybe<Scalars["String"]>;
  networkNotes?: Maybe<Scalars["String"]>;
  offerId?: Maybe<Scalars["String"]>;
  orderNumber?: Maybe<Scalars["String"]>;
  partialOrder?: Maybe<Scalars["Boolean"]>;
  status?: Maybe<MissingTransactionStatus>;
  totalSaleAmount?: Maybe<Scalars["Float"]>;
  trackingUrl?: Maybe<Scalars["String"]>;
  transactionDate?: Maybe<Scalars["String"]>;
  usedAdBlock?: Maybe<Scalars["Boolean"]>;
};

export type ContactPerson = {
  __typename?: "ContactPerson";
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export enum MissingTransactionStatus {
  AwaitAnalyze = "AwaitAnalyze",
  AwaitNetworkResponse = "AwaitNetworkResponse",
  AwaitNetworkSend = "AwaitNetworkSend",
  Invalid = "Invalid",
  Paid = "Paid",
  PendingReview = "PendingReview",
  Rejected = "Rejected",
}

export type Referral = {
  __typename?: "Referral";
  amount: Amount;
  id: Scalars["ID"];
  newUserFirstName: Scalars["String"];
  newUserId: Scalars["ID"];
  newUserStatus: ReferralStatus;
  referrerFirstName: Scalars["String"];
  referrerId: Scalars["ID"];
  referrerStatus: ReferralStatus;
  status: ReferralStatus;
};

export type IStory = {
  __typename?: "IStory";
  affiliateId?: Maybe<Scalars["String"]>;
  affiliateName?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  language?: Maybe<Scalars["String"]>;
  rewardLimit?: Maybe<Amount>;
  rewardValue?: Maybe<Amount>;
  usersRewarded?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type BtcFiatAmountInput = {
  btc?: InputMaybe<AmountInput>;
  exchangeRate?: InputMaybe<Scalars["String"]>;
  fiat?: InputMaybe<AmountInput>;
};

export type AmountInput = {
  currency?: InputMaybe<Scalars["String"]>;
  value: Scalars["Int"];
};

export type UserMetadataInput = {
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  device?: InputMaybe<UserDeviceDataInput>;
  language?: InputMaybe<Scalars["String"]>;
  region?: InputMaybe<Scalars["String"]>;
  webOrMobile?: InputMaybe<Scalars["String"]>;
};

export type UserDeviceDataInput = {
  model?: InputMaybe<Scalars["String"]>;
  os?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export type UserMobileAppVersionInput = {
  nativeAppVersion?: InputMaybe<Scalars["String"]>;
  nativeBuildVersion?: InputMaybe<Scalars["String"]>;
  os?: InputMaybe<Scalars["String"]>;
  updateId?: InputMaybe<Scalars["String"]>;
};

export enum AffiliateNetworks {
  AWIN = "AWIN",
  CJ = "CJ",
  EBAY = "EBAY",
  FLEX = "FLEX",
  IMPACT = "IMPACT",
  PJ = "PJ",
  RAKUTEN = "RAKUTEN",
}

export enum CurrencyTypes {
  CAD = "CAD",
  EUR = "EUR",
  GBP = "GBP",
  USD = "USD",
}

export enum EAdBannerTypes {
  BannerTypeA = "BannerTypeA",
  BannerTypeB = "BannerTypeB",
  BannerTypeC = "BannerTypeC",
}

export type Fees = {
  __typename?: "Fees";
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["Float"]>;
};

export type ReferralCompleted = {
  __typename?: "ReferralCompleted";
  newUserTransactionPresent?: Maybe<Scalars["Boolean"]>;
  referrerTransactionPresent?: Maybe<Scalars["Boolean"]>;
};

export enum TransactionTypes {
  AdHocAllocateReward = "AdHocAllocateReward",
  AdHocRewards = "AdHocRewards",
  Affiliate = "Affiliate",
  BTCTransfert = "BTCTransfert",
  InitialRewards = "InitialRewards",
  Interac = "Interac",
  Redemption = "Redemption",
  Referral = "Referral",
  ReferralForNewUser = "ReferralForNewUser",
  ReferralForReferrer = "ReferralForReferrer",
  StoryAllocateReward = "StoryAllocateReward",
  StoryRewards = "StoryRewards",
  Travel = "Travel",
  GiftCard = "GiftCard",
}

export type Wallet = {
  __typename?: "Wallet";
  balance?: Maybe<BtcFiatAmount>;
  balanceIncludingFrozen?: Maybe<BtcFiatAmount>;
  transactions?: Maybe<Array<Maybe<WalletTransaction>>>;
};

export enum WalletTransactionStatus {
  Completed = "Completed",
  ExpiredReferral = "ExpiredReferral",
  Frozen = "Frozen",
  InteracTransferComplete = "InteracTransferComplete",
  InteracTransferPending = "InteracTransferPending",
  InteracTransferToBeTransfered = "InteracTransferToBeTransfered",
  MissingTransaction = "MissingTransaction",
  NewReferral = "NewReferral",
  PendingBalanceCheck = "PendingBalanceCheck",
  PendingExternalTransfert = "PendingExternalTransfert",
  Reversed = "Reversed",
}

export enum WalletTransactionTypes {
  Deposit = "Deposit",
  Referral = "Referral",
  Withdrawal = "Withdrawal",
}
