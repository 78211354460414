import React from "react";
import {motion} from "framer-motion";

// Component imports
import {BitcoinIllustrationIcon} from "@icons";

// Misc Imports
import {makeStyles} from "@styles";
import {largeBitcoinIcons} from "./icon-configurations";

const useStyles = makeStyles(() => ({}));

interface IconBubble {
  top: number | string;
  left: number | string;
  transform: string;
  size: number;
  delay?: number;
}

type Props = {iconPattern: string};

const randomNumber = (min: number, max: number): number =>
  Math.random() * (max - min) + min;

const bitcoinIconPatterns = {
  largeBitcoinIcons: largeBitcoinIcons,
};

export function BitcoinIconsAnimated({iconPattern}: Props) {
  const classes: any = useStyles({});

  const bitcoinIcons = bitcoinIconPatterns[iconPattern];

  return (
    <div style={{position: "relative"}}>
      {bitcoinIcons.map((icon: IconBubble, index: number) => {
        return (
          <motion.div
            key={index + 1}
            initial={{scale: 0, opacity: 0}}
            animate={{scale: 1, opacity: 1}}
            transition={{
              type: "spring",
              stiffness: randomNumber(75, 200),
              delay: icon.delay,
            }}
            id={`bitcoin-icon-${index + 1}`}
            className={classes.brandBubble}
            style={{
              position: "absolute",
              width: icon.size,
              height: icon.size,
              top: icon.top,
              left: icon.left,
            }}
          >
            <BitcoinIllustrationIcon
              width={icon.size}
              style={{transform: icon.transform}}
            />
          </motion.div>
        );
      })}
    </div>
  );
}
