export const pt = {
  shopOnline: "Comprar Online",
  localOffers: "Ofertas locais",
  travel: "Viagens",
  myWallet: "Minha carteira",
  giftCards: "Vales-presente",
  whatILike: "Amei",
  login: "Log in",
  loginToEarn: "Faça Log in para ganhar",
  join: "Inscreva-se para ganhar",
  adblockDetected: {
    bold: "Parece que você ativou o AdBlock! ",
    message:
      "Para que o Coinmiles possa recompensá-lo, você deve desativar seu AdBlocker.",
  },
};
