import { isServerSideRendering } from "@utils";

type OSOptions = "Mac OS" | "iOS" | "Windows" | "Android" | "Linux";

export class DeviceUtils {
  static getPhoneOrDesktop = (): "phone" | "desktop" => {
    if (isServerSideRendering()) return;
    const touchDevice = !!(
      navigator.maxTouchPoints || "ontouchstart" in document.documentElement
    );

    return touchDevice ? "phone" : "desktop";
  };

  static getOS = (): OSOptions => {
    if (isServerSideRendering()) return;
    let os: OSOptions;

    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
    const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
    const iosPlatforms = ["iPhone", "iPad", "iPod"];

    if (macosPlatforms.includes(platform)) {
      os = "Mac OS";
    } else if (iosPlatforms.includes(platform)) {
      os = "iOS";
    } else if (windowsPlatforms.includes(platform)) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (!os && /Linux/.test(platform)) {
      os = "Linux";
    }

    return os;
  };
}
