import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Auth } from "aws-amplify";

// Component imports
import { DialogButton, Error } from "@components";
import { Grid, Typography } from "@material";

// Misc Imports

import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";
import { AuthUtils, ModalHelper, UserUtils } from "@utils";

const useStyles = makeStyles((theme: Theme) => ({
    textFieldContainer: {
        height: "auto",
        width: 308,
    },
    modalText: {
        marginTop: 16,
        color: "#000000",
        fontSize: 14,
        textAlign: "center",
    },
    textField: {
        width: 308,
        height: 48,
        padding: 2,
        paddingLeft: 16,
        border: `1px solid ${theme.palette.colors.border.light}`,
        fontFamily: "Roboto, sans-serif",
        fontSize: 16,
        borderRadius: 2,
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    submitButtonContainer: {
        marginTop: 27,
        marginBottom: 18,
    },
}));

type FormValues = {
    oldPassword: string;
    newPassword: string;
};

export function ChangePasswordForm() {
    const classes: any = useStyles({});
    const { t } = useTranslation(["changePassword", "common"]);

    const [wrongOldPassword, setWrongOldPassword] = useState<boolean>(false);

    const { register, handleSubmit, errors, watch } = useForm();

    const handleRedirectToSignIn = (): void => {
        ModalHelper.close();
        AuthUtils.signOut("/sign-in");
    };

    const handleOpenPasswordChangedSuccessModal = (): void => {
        ModalHelper.open({
            modalType: "passwordChangedSuccess",
            modalProps: { handleRedirectToSignIn },
            modalOptions: { onClose: handleRedirectToSignIn },
        });
    };

    const handleChangePasswordSubmit: SubmitHandler<FormValues> = (data) => {
        setWrongOldPassword(false);

        const { oldPassword, newPassword } = data;

        Auth.currentAuthenticatedUser()
            .then((user) => {
                return Auth.changePassword(user, oldPassword, newPassword);
            })
            .then((data) => {
                handleOpenPasswordChangedSuccessModal();
            })
            .catch((err) => {
                setWrongOldPassword(true);
            });
    };

    return (
        <form onSubmit={handleSubmit(handleChangePasswordSubmit)}>
            <Grid container alignItems="flex-end" className={classes.textFieldContainer}>
                <Typography className={classes.modalText}>
                    {t("common:oldPassword")}
                </Typography>
                <input
                    id="change-password-old-password"
                    placeholder={t("common:oldPassword")}
                    name={"oldPassword"}
                    ref={register({
                        required: true,
                    })}
                    type="password"
                    className={classes.textField}
                />
                {wrongOldPassword && <Error>{t("common:errors.password")}</Error>}
            </Grid>
            <Grid container alignItems="flex-end" className={classes.textFieldContainer}>
                <Typography className={classes.modalText}>
                    {t("common:newPassword")}
                </Typography>
                <input
                    id="change-password-password"
                    placeholder={t("common:newPassword")}
                    name={"newPassword"}
                    ref={register({
                        required: true,
                        validate: (value) => UserUtils.isValidPassword(value),
                    })}
                    type="password"
                    className={classes.textField}
                />
                {errors.newPassword && (
                    <Error>{t("common:errors.passwordRequirements")}</Error>
                )}
            </Grid>
            <Grid container alignItems="flex-end" className={classes.textFieldContainer}>
                <Typography className={classes.modalText}>{t("passwordConf")}</Typography>
                <input
                    id="change-password-password-confirmation"
                    placeholder={t("passwordConf")}
                    name={"newPasswordConfirmation"}
                    ref={register({
                        validate: (value) => value === watch("newPassword"),
                    })}
                    type="password"
                    className={classes.textField}
                />
                {errors.newPasswordConfirmation && (
                    <Error>{t("common:errors.passwordConfirmation")}</Error>
                )}
            </Grid>
            <Grid className={classes.submitButtonContainer}>
                <DialogButton colorVariant="black" width="100%" type="submit">
                    {t("common:submit")}
                </DialogButton>
            </Grid>
        </form>
    );
}
