import React from "react";

import { Footer, Navbar, RootModal } from "@components";
import { useDetectAdBlock } from "@hooks";

import { AdblockBanner } from "./ad-block-banner";

export const Layout = ({ children, location }) => {
  if (location.pathname === "/redirect/" || location.pathname === "/social-sign-in/") {
    return <>{children}</>;
  }

  let isBarePage = false;
  if (location.pathname.includes("confirmation")) {
    isBarePage = true;
  }

  const adBlockDetected = useDetectAdBlock();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      {adBlockDetected && <AdblockBanner />}
      <div style={{ marginLeft: "calc(100vw - 100%)", minHeight: "77vh" }}>
        {!isBarePage && <Navbar />}
        <RootModal />
        {children}
      </div>
      {!isBarePage && <Footer />}

      <style>{`
                *{
                    scrollbar-width: none; /* For Firefox */
                    -ms-overflow-style: none; /* For Internet Explorer and Edge */
                }
                ::-webkit-scrollbar {
                    display: none;
                    width: 0px; /* For Chrome, Safari, and Opera */
                }
      `}</style>
    </div>
  );
};
