const pageVariants = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const activatedTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 1,
};

const cardTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 1.5,
};

const cardVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const redirectTransition = {
  type: "spring",
  ease: "ease-in",
  duration: 3,
  stiffness: 50,
};

const redirectVariants = {
  initial: {
    opacity: 0,
    x: "-100vw",
  },
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: "100vw",
  },
};

const arrowBounce = {
  hover: {
    x: 4,
    transition: {
      yoyo: Infinity,
      duration: 0.6,
      type: "tween",
      ease: "easeOut",
    },
  },
};

const heartGrow = {
  hover: {
    scale: 1.15,
    transition: {
      yoyo: Infinity,
      duration: 0.6,
      type: "tween",
      ease: "easeInOut",
    },
  },
};

const heartClick = {
  scale: [1, 0.6, 1.1, 0.75],
  transition: {
    yoyo: 1,
    type: "spring",
    stiffness: 50,
    damping: 80,
    duration: 1.5,
    ease: "easeInOut",
  },
  transitionEnd: {
    scale: 1,
  },
};

export {
  pageVariants,
  activatedTransition,
  cardTransition,
  cardVariants,
  redirectTransition,
  redirectVariants,
  arrowBounce,
  heartGrow,
  heartClick,
};
