export const fr = {
  noUserOffersHeader: "Vous n’avez sauvegardé aucune offre jusqu’à présent!",
  noUserOffersSubheader:
    "Cliquez simplement sur le coeur de chaque offre pour sauvergarder l’offre ici. Une bonne façon de sauver du temps!",
  keepExploring: "Continuer à explorer",
  filters: {
    orderBy: "Commandé:",
    type: "Type",
    whatILikeOptions: {
      "All Offers": "Toute",
      Online: "En ligne",
      "In-Store": "En magasin",
    },
    orderOptions: {
      Featured: "En vedette",
      "Highest cashback": "Cashback",
      "A-Z": "A-Z",
      "Z-A": "Z-A",
      "New offers": "Nouvelles offres",
    },
    dialogMessage: {
      one:
        "Obtenez automatiquement des remises en Bitcoin lorsque vous payer en magasin avec votre propre carte de crédit.",
      two:
        "Disponible via l’appli Coinmiles, télécharger la maintenant! (Bientôt en version web)!",
    },
  },
};
