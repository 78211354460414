function filterOffersForCountry({ offers, countryId }) {
  return offers.filter((offer: StaticOffer) => {
    if (
      offer.serviceArea?.includes("ALL") ||
      offer.serviceArea?.includes(countryId)
    )
      return offer;
  });
}

function sortOrderedOffers({
  offersForCountry,
  displayOrder,
}: {
  offersForCountry: StaticOffer[];
  displayOrder: string[];
}): StaticOffer[] {
  const orderedOffers = displayOrder
    .map((offerId) =>
      offersForCountry.find(({ id }) => id.toString() === offerId),
    )
    .filter((offer) => !!offer);
  return orderedOffers;
}

function filterUnorderedOffers({
  offersForCountry,
  displayOrder,
}: {
  offersForCountry: StaticOffer[];
  displayOrder: string[];
}): StaticOffer[] {
  const unorderedOffers = offersForCountry.filter((offer) => {
    if (!displayOrder.includes(offer.id.toString())) {
      return offer;
    }
  });
  return unorderedOffers;
}

function concatenateOffers({
  orderedOffers,
  unorderedOffers,
}: {
  orderedOffers: StaticOffer[];
  unorderedOffers: StaticOffer[];
}): StaticOffer[] {
  return orderedOffers.concat(unorderedOffers);
}

function orderOffersByCountry({
  offersForCountry,
  displayOrder,
}): StaticOffer[] {
  const sortParams = { offersForCountry, displayOrder };
  const orderedOffers = sortOrderedOffers(sortParams);
  const unorderedOffers = filterUnorderedOffers(sortParams);

  return concatenateOffers({ orderedOffers, unorderedOffers });
}

export function sortOffersForCountry({
  offers,
  displayOrders,
  countryId,
}: {
  offers: StaticOffer[];
  displayOrders: DisplayOrder[];
  countryId: string;
}): StaticOffer[] {
  const displayOrderForCountry = displayOrders.find((displayOrder) => {
    if (displayOrder.countryCode === countryId) return displayOrder;
  });

  const displayOrder = displayOrderForCountry.displayOrder.map(
    (offer) => offer.id,
  );

  const offersForCountry = filterOffersForCountry({ offers, countryId });

  return orderOffersByCountry({ offersForCountry, displayOrder });
}
