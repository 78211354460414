enum CurrencyTypes {
  CAD = "CAD",
  USD = "USD",
  EUR = "EUR",
  GBP = "GBP",
  BRL = "BRL",
  BTC = "BTC",
  // XBT is the BTC code used by exchanges, eg. Kraken
  XBT = "XBT",
}

export { CurrencyTypes };
