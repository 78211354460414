export function filterOffers(
  offers: StaticOffer[],
  { category, order, searchString }: OnlineScreenFilters,
): StaticOffer[] {
  const filteredOffers = offers
    .filter((offer: StaticOffer) => {
      if (searchString === "") {
        return offer;
      } else if (
        offer.advertiserName.toLowerCase().includes(searchString.toLowerCase())
      ) {
        return offer;
      } else {
        return null;
      }
    })
    .filter((offer: StaticOffer) => {
      if (category === "ALL") {
        return offer;
      } else if (offer.categories && offer.categories.includes(category)) {
        return offer;
      } else {
        return null;
      }
    });

  if (order === "Featured") {
    return filteredOffers;
  } else {
    return filteredOffers.sort((a: StaticOffer, b: StaticOffer) => {
      if (order === "Highest cashback") {
        return a.payoutValue === b.payoutValue
          ? 0
          : a.payoutValue > b.payoutValue
          ? -1
          : 1;
      } else if (order === "A-Z") {
        return a.advertiserName.toLowerCase() === b.advertiserName.toLowerCase()
          ? 0
          : a.advertiserName.toLowerCase() < b.advertiserName.toLowerCase()
          ? -1
          : 1;
      } else if (order === "Z-A") {
        return a.advertiserName.toLowerCase() === b.advertiserName.toLowerCase()
          ? 0
          : a.advertiserName.toLowerCase() > b.advertiserName.toLowerCase()
          ? -1
          : 1;
      } else if (order === "New offers") {
        return a.activeDate === b.activeDate
          ? 0
          : a.activeDate > b.activeDate
          ? -1
          : 1;
      } else {
        return 0;
      }
    });
  }
}

export function filterUserOffers(
  offers: StaticOffer[],
  { category, order }: any,
): StaticOffer[] {
  const filteredOffers = offers.filter((offer: StaticOffer) => {
    if (category === "All Offers") {
      return offer;
    } else if (category === "Online") {
      return offer;
    } else {
      return null;
    }
  });

  if (order === "Featured") {
    return filteredOffers;
  } else {
    return filteredOffers.sort((a: StaticOffer, b: StaticOffer) => {
      if (order === "Highest cashback") {
        return a.payoutValue === b.payoutValue
          ? 0
          : a.payoutValue > b.payoutValue
          ? -1
          : 1;
      } else if (order === "A-Z") {
        return a.advertiserName.toLowerCase() === b.advertiserName.toLowerCase()
          ? 0
          : a.advertiserName.toLowerCase() < b.advertiserName.toLowerCase()
          ? -1
          : 1;
      } else if (order === "Z-A") {
        return a.advertiserName.toLowerCase() === b.advertiserName.toLowerCase()
          ? 0
          : a.advertiserName.toLowerCase() > b.advertiserName.toLowerCase()
          ? -1
          : 1;
      } else if (order === "New offers") {
        return a.activeDate === b.activeDate
          ? 0
          : a.activeDate > b.activeDate
          ? -1
          : 1;
      } else {
        return 0;
      }
    });
  }
}
