import React from "react";

// Misc Imports
import { Assets } from "@assets";
import { useTranslation } from "@i18n";
import { Amount, Wallet } from "@models";
import { makeStyles } from "@styles";
import { convertToFormattedValue, ModalHelper } from "@utils";

// Component imports
import { DialogButton } from "@components";
import { BitcoinIcon } from "@icons";
import { Grid, Paper, Skeleton, Typography } from "@material";

import { LineGraph } from "../line-graph";

const useStyles = makeStyles((theme: Theme) => ({
    walletContainer: {
        backgroundColor: "white",
        border: `1px solid #EBEBEB`,
        width: "100%",
        height: "auto",
        padding: 24,
        [theme.breakpoints.down("sm")]: {
            padding: 15,
        },
    },
    balanceHeader: {
        fontSize: 24,
        fontWeight: 500,
    },
    countryContainer: {
        display: "flex",
        alignItems: "center",
    },
    fiatDisplay: {
        fontSize: 48,
        fontWeight: 800,
        color: "black",
    },
    countryCurrencyText: {
        fontSize: 32,
        fontWeight: 800,
    },
    bitcoinIcon: {
        position: "relative",
        top: 3,
        marginRight: 6,
        fill: "#F7931A",
        width: 18,
    },
    btcDisplay: {
        fontSize: 18,
        fontWeight: 400,
        color: "#888888",
    },
    marketPriceContainer: {
        width: "100%",
        height: "auto",
        padding: 20,
        paddingLeft: 24,
        border: "1px solid #9e9e9e",
        backgroundColor: "#FAFAFA",
        borderRadius: 4,
        marginTop: 30,
        marginBottom: 30,
        paddingTop: 27,
        paddingBottom: 27,
    },
    rocketEmoji: {
        width: 21,
        height: 21,
        marginRight: 4,
    },
    marketPriceHeader: {
        display: "flex",
        alignItems: "center",
        fontSize: 14,
        fontWeight: 600,
        flexWrap: "nowrap",
    },
    marketPrice: {
        fontSize: 21,
        fontWeight: 600,
    },
    sendButton: {
        backgroundColor: "black",
        width: "100%",
        textTransform: "none",
        borderRadius: 0,
        color: "white",
        fontSize: 16,
        fontWeight: 400,
        transitionProperty: "background-color, color",
        transitionDuration: "0.4s",
        transitionTimingFunction: "ease-out",
        "&:hover": {
            backgroundColor: "#303030",
            color: "#F4F4F4",
            transitionProperty: "color, background-color",
            transitionDuration: "0.4s",
        },
    },
}));

type Props = {
    wallet: Wallet;
    country: AffiliateServiceArea;
    btcRate: ExchangeRate;
    priceHistory: number[];
    loading: boolean;
};

export function WalletDisplay({
    wallet,
    country,
    btcRate,
    priceHistory,
    loading,
}: Props) {
    const classes: any = useStyles({});
    const { t } = useTranslation(["wallet", "common"]);

    const handleOpenAppDownloadModal = (): void => {
        const interacLogo = country.countryCode === "CA" ? Assets.INTERAC_LOGO : null;
        ModalHelper.open({
            modalType: "appDownload",
            modalProps: {
                fontSize: { small: 14 },
                logo: interacLogo,
                messageStrings: [
                    t("dialogText.one"),
                    country.countryCode === "CA"
                        ? t("dialogText.two")
                        : t("dialogText.three"),
                ],
            },
        });
    };

    const balanceIncludingPendingInFiat = wallet?.balanceIncludingPendingInFiat
        ? new Amount(wallet.balanceIncludingPendingInFiat)
        : null;

    return (
        <Paper className={classes.walletContainer}>
            <Grid container direction="column" alignItems="flex-start">
                <Grid container item xs={12} justifyContent="space-between">
                    <Typography className={classes.balanceHeader}>
                        {t("myBalance")}
                    </Typography>
                    <div className={classes.countryContainer}>
                        {country?.flag && (
                            <img
                                src={country.flag}
                                width={30}
                                alt={`${country.name}-flag`}
                            />
                        )}
                    </div>
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    style={{ width: 217, height: 72, paddingTop: 8, paddingBottom: 8 }}
                >
                    {!loading && !!wallet ? (
                        <Typography className={classes.fiatDisplay}>
                            {country?.currencySign}
                            {balanceIncludingPendingInFiat.unscaledValue.toFixed(2)}
                            <span className={classes.countryCurrencyText}>
                                {country?.currencyCode}
                            </span>
                        </Typography>
                    ) : (
                        <Skeleton
                            variant="rect"
                            animation="wave"
                            width="100%"
                            height={57}
                            style={{ borderRadius: 4 }}
                        />
                    )}
                </Grid>
                <Grid item container direction="column" justifyContent="space-between">
                    <Grid item>
                        {!loading && !!wallet ? (
                            <Typography className={classes.btcDisplay}>
                                <BitcoinIcon className={classes.bitcoinIcon} />
                                {(
                                    wallet.balanceIncludingFrozen.btc.value / 100000000
                                ).toFixed(8)}{" "}
                                BTC
                            </Typography>
                        ) : (
                            <Skeleton
                                variant="rect"
                                animation="wave"
                                width="100%"
                                height={27}
                                style={{ borderRadius: 4 }}
                            />
                        )}
                    </Grid>
                    <Grid
                        container
                        item
                        direction="row"
                        xs={12}
                        sm={8}
                        md={12}
                        className={classes.marketPriceContainer}
                        style={{
                            padding: 12,
                        }}
                    >
                        {!loading && !!wallet ? (
                            <>
                                <Grid
                                    container
                                    item
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    xs={12}
                                    md={5}
                                    lg={5}
                                >
                                    <Grid item>
                                        <Typography className={classes.marketPriceHeader}>
                                            <img
                                                src={Assets.ROCKET_EMOJI}
                                                className={classes.rocketEmoji}
                                                alt="rocket-emoji"
                                            />
                                            {t("btcPrice")}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.marketPrice}>
                                            {`${
                                                country?.currencySign
                                            }${convertToFormattedValue(
                                                Number(btcRate.price),
                                            )}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    xs={12}
                                    sm={12}
                                    md={7}
                                    lg={7}
                                    style={{
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                    }}
                                >
                                    <LineGraph dataPoints={priceHistory} />
                                </Grid>{" "}
                            </>
                        ) : (
                            <Skeleton
                                variant="rect"
                                animation="wave"
                                width="100%"
                                height={100}
                                style={{ borderRadius: 4 }}
                            />
                        )}
                    </Grid>
                    <Grid item sm={12} md={12} lg={12}>
                        <DialogButton
                            colorVariant="black"
                            width="100%"
                            onClick={handleOpenAppDownloadModal}
                            className={classes.sendButton}
                        >
                            {t("common:send")}
                        </DialogButton>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}
