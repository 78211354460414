import { gql } from "@apollo/client";

export const SCREEN_GET_NAVBAR_DATA = gql`
  query ScreenGetNavbarData($currency: String) {
    wallet: getWallet(currency: $currency) {
      balanceIncludingPendingInFiat
      balanceNotIncludingFrozen {
        btc {
          value
        }
      }
      balanceIncludingFrozen {
        fiat {
          value
        }
      }
      transactions {
        amount {
          fiat {
            currency
          }
          btc {
            currency
            value
          }
        }
      }
    }
  }
`;
