import SecureLS from "secure-ls";
import { isServerSideRendering } from "@utils";

export const openOfferRedirect = (redirectData: RedirectData): void => {
  if (isServerSideRendering()) {
    return;
  }

  const encryptedLocalStorage = new SecureLS();
  if (encryptedLocalStorage.get("redirectData") !== null) {
    encryptedLocalStorage.remove("redirectData");
  }
  encryptedLocalStorage.set("redirectData", redirectData);

  window.open("/redirect", "_blank");
};
