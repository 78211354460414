import React from "react";

// Library imports
import {ResponsiveContainer, AreaChart, Area, YAxis} from "recharts";

// Misc Imports
import {makeStyles} from "@styles";

const useStyles = makeStyles(() => ({
  chart: {
    marginRight: "-6.7%",
  },
}));

type Props = {
  dataPoints: number[];
};

export function LineGraph(props: Props) {
  const classes: any = useStyles({});

  const {dataPoints} = props;

  const generateData = (start, end, step) => {
    const data: Array<{value: number; argument: number}> = [];
    for (let i = start; i < end; i += step) {
      data.push({value: dataPoints[i], argument: i});
    }
    return data;
  };

  const data: Array<{value: number; argument: number}> = generateData(
    0,
    dataPoints.length + 1,
    1
  );
  const minValue: number = Math.min.apply(null, dataPoints);
  const maxValue: number = Math.max.apply(null, dataPoints);

  return (
    <ResponsiveContainer width="100%" height={100} className={classes.chart}>
      <AreaChart
        data={data}
        margin={{top: 5, right: 0, left: 0, bottom: 5}}
        padding={{top: 5, right: 0, left: 0, bottom: 5}}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="10%" stopColor="#2648FF" stopOpacity={0.6} />
            <stop offset="90%" stopColor="#2648FF" stopOpacity={0} />
          </linearGradient>
        </defs>
        <YAxis
          hide
          domain={[minValue - minValue * 0.05, maxValue + maxValue * 0.05]}
        />
        <Area
          type="monotone"
          dataKey="value"
          stroke="#2648FF"
          fillOpacity={1}
          fill="url(#colorUv)"
          margin={{top: 5, right: 0, left: 0, bottom: 5}}
          padding={{top: 5, right: 0, left: 0, bottom: 5}}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
