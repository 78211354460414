export const fr = {
  signIn: "Connectez-vous pour continuer",
  walletSignIn: "Connectez-vous pour voir votre portefeuille ",
  forgotPassword: "Mot de passe oublié?",
  loginWithEmail: "Se connecter avec un courriel",
  notMember: "Aucun compte?",
  joinCoinmiles: "Créez un compte Coinmiles",
  social: {
    facebook: "Continuez avec Facebook",
    apple: "Continuez avec Apple",
  },
  activationModal: {
    confirmEmail: "Confirmer votre courriel",
    sentEmail:
      "Nous vous avons transmis un courriel contenant un lien de confirmation. ",
    confirmBefore: "Veuillez confirmer votre courriel avant de vous connecter.",
    sendAgain: "Envoyer de nouveau",
  },
  restorePasswordModal: {
    restorePassword: "Réinitialiser votre mot de passe",
    enterEmail:
      "Entrez votre adresse courriel pour réinitialiser votre mot de passe.",
    emailAddress: "Adresse électronique",
    send: "Envoyer",
    account: "Vous avez déjà un compte?",
    signIn: "Se connecter",
  },
};
