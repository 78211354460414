import { gql } from "@apollo/client";

export const MUTATION_UPDATE_USER = gql`
  mutation UpdateUser(
    $emailAddress: String
    $firstName: String
    $lastName: String
    $metadata: UserMetadataInput
    $profilePhotoUrl: String
    $didLogin: Boolean
    $emailOptIn: Boolean
  ) {
    user: updateUser(
      emailAddress: $emailAddress
      firstName: $firstName
      lastName: $lastName
      metadata: $metadata
      profilePhotoUrl: $profilePhotoUrl
      didLogin: $didLogin
      emailOptIn: $emailOptIn
    ) {
      emailAddress
      firstName
      lastName
      profilePhotoUrl
      didLogin
      emailOptIn
      metadata {
        country
        language
      }
    }
  }
`;
