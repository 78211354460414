import { currentCountryVar } from "@apollo";
import { getAffiliateServiceArea, getUserCountry } from "@utils";

export const LocaleUtils = {
  supportedCountries: ["CA", "US", "FR", "BR"],

  supportedLanguages: ["en", "fr", "pt"],

  isCountrySupported: function (countryCode: string): boolean {
    return this.supportedCountries.includes(countryCode);
  },

  isLanguageSupported: function (languageCode: string): boolean {
    return this.supportedLanguages.includes(languageCode);
  },

  setLocale: async function (): Promise<AffiliateServiceArea> {
    let userCountryCode: string;
    const savedCountry: string = localStorage.getItem("userCountry");

    if (savedCountry) {
      userCountryCode = savedCountry;
    } else {
      try {
        const { country: countryCode } = await getUserCountry();
        const isSupported = LocaleUtils.isCountrySupported(countryCode);

        isSupported ? (userCountryCode = countryCode) : (userCountryCode = "CA");
      } catch (err) {
        userCountryCode = "CA";
      }
    }

    let userLanguageCode: string;
    const savedLanguage: string = localStorage.getItem("i18nextLng");

    if (LocaleUtils.isLanguageSupported(savedLanguage)) {
      userLanguageCode = savedLanguage;
    } else {
      userLanguageCode = "en";
      localStorage.setItem("i18nextLng", userLanguageCode);
    }

    const userCountry = getAffiliateServiceArea(userCountryCode);

    localStorage.setItem("userCountry", userCountryCode);
    currentCountryVar(userCountry);

    return userCountry;
  },
};
