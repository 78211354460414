import React from "react";

import { bonusSatsStateVar, useReactiveVar } from "@apollo";

// Misc imports
import { useMediaQuery } from "@hooks";
import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";
import { ModalHelper } from "@utils";

// Component imports
import {
  AppleLoginButton,
  BitcoinIconsAnimated,
  DialogButton,
  DividerLineWithText,
  FacebookLoginButton,
  Image,
  Link,
} from "@components";
import { CloseIcon } from "@icons";
import { Grid, Paper, Typography } from "@material";
const useStyles = ({ language, longName }) =>
  makeStyles((theme: Theme) => ({
    paper: {
      position: "relative",
      margin: "auto",
      backgroundColor: theme.palette.white,
      borderRadius: 8,
      outline: "none !important",
      overflow: "hidden",
      width: 960,
      height: 492,
    },
    gridContainer: {
      width: "100%",
      height: "100%",
    },
    cancelIconContainer: {
      position: "absolute",
      alignItems: "center",
      top: 12,
      right: 16,
      zIndex: 5,
    },
    cancelIcon: {
      cursor: "pointer",
    },
    brandLogoContainer: {
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: 8,
    },
    logo: {
      width: 140,
    },
    textAndButtonsContainer: {
      marginLeft: "auto",
      marginRight: "auto",
      width: "87%",
    },
    textContainer: {},
    joinHeaderContainer: {
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: 9,
      width: "auto",
    },
    joinHeaderText: {
      textAlign: "left",
      fontSize:
        language === "fr" && longName
          ? 15
          : language === "fr" && !longName
          ? 20
          : 21.2,
      fontWeight: 800,
      lineHeight: 1.2,
    },
    joinSubheaderText: {
      color: "black",
      fontSize: language === "fr" ? 14 : 16,
      textAlign: "center",
    },
    socialLoginButtonContainer: {
      width: "100%",
    },
    emailSignUpButtonContainer: {
      width: "100%",
      marginBottom: 8,
    },
    // emailSignupButton: {fontFamily: "Roboto", fontSize: 24, fontWeight: 700},
    termsText: {
      textAlign: "center",
    },
    alreadyMemberTextContainer: {},
    alreadyMemberText: {
      textAlign: "center",
      color: "#464646",
      fontSize: 18,
      margin: "10px 8px 8px 8px",
    },
    leftHalf: {
      display: "flex",
      height: 492,
      alignItems: "flex-end",
      padding: 32,
      paddingTop: 25,
    },
    rightHalf: {
      position: "relative",
      width: "100%",
      height: 492,
      backgroundColor: "#EFEFEF",
      zIndex: 2,
    },
  }));

export function SignUpModal({ name, logo }: SignUpData) {
  const language: string = localStorage.getItem("i18nextLng");
  const longName: boolean = name.length >= 14;

  const classes: any = useStyles({ language, longName })({});
  const { t } = useTranslation(["signUp", "common"]);
  const small: boolean = useMediaQuery("(max-width:959px)");

  const bonusSats = useReactiveVar(bonusSatsStateVar);

  return (
    <Paper className={classes.paper}>
      <Grid container direction="row" className={classes.gridContainer}>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          className={classes.leftHalf}
        >
          <Grid item className={classes.cancelIconContainer}>
            <CloseIcon
              className={classes.cancelIcon}
              width={13}
              fill={"#EFEFEF"}
              onClick={ModalHelper.close}
            />
          </Grid>
          <Grid className={classes.brandLogoContainer}>
            {logo && (
              <Image
                image={logo}
                style={{
                  width: 62,
                  borderRadius: 10,
                }}
                alt="advertiser-logo"
              />
            )}
          </Grid>
          <Grid
            container
            item
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.textAndButtonsContainer}
          >
            <Grid
              container
              justifyContent={small ? "center" : "flex-start"}
              className={classes.textContainer}
            >
              <Grid
                container
                alignItems="center"
                className={classes.joinHeaderContainer}
              >
                <Typography className={classes.joinHeaderText}>
                  {name && `${t("modal.earnAt")} ${name}`}
                </Typography>
              </Grid>
              <Grid style={{ marginBottom: 17 }}>
                <Typography className={classes.joinSubheaderText}>
                  {t("modal.weNeedToKnow.one")} {t("modal.weNeedToKnow.two")}{" "}
                  <strong>{t("modal.weNeedToKnow.three")}</strong>
                  {bonusSats}
                  {t("modal.weNeedToKnow.four")}
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.socialLoginButtonContainer}>
              <FacebookLoginButton handleClose={ModalHelper.close} />
              <AppleLoginButton
                handleClose={ModalHelper.close}
                style={{ marginBottom: 0 }}
              />
            </Grid>
            <DividerLineWithText verticalSpacing={16} text={t("common:or")} />
            <Grid className={classes.emailSignUpButtonContainer}>
              <Link
                // className={classes.emailSignupButton}
                to="/sign-up"
              >
                <DialogButton
                  colorVariant="white"
                  borderRadius={10}
                  height={56}
                  fontSize={16}
                  width="100%"
                  onClick={ModalHelper.close}
                >
                  {t("signUpWithEmail")}
                </DialogButton>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={7} lg={7} className={classes.rightHalf}>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundImage: `url("https://bitcoin-miles-image-service-production.s3.ca-central-1.amazonaws.com/tutorial/sign-up-modal-desktop-v2.jpg")`,
            }}
          >
            <BitcoinIconsAnimated iconPattern={"largeBitcoinIcons"} />
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}
