import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Auth } from "aws-amplify";
import { trim } from "validator";

// Component imports
import { DialogButton, Error, Link } from "@components";
import { Grid, Paper, Typography } from "@material";

// Misc Imports

import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";
import { UserUtils, ModalHelper, navigate } from "@utils";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    height: "auto",
    width: "auto",
    border: "1px solid #DADADA",
    borderRadius: 8,
    outline: "none !important",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      width: "95%",
    },
  },
  container: {
    width: 308,
    margin: 32,
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "16px auto 16px auto",
    },
  },
  header: {
    fontSize: 24,
    fontWeight: 700,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: 16,
      fontSize: 20,
    },
  },
  textFieldContainer: {
    height: 79,
    width: 308,
    marginBottom: 24,
  },
  modalText: {
    marginTop: 16,
    color: "#000000",
    fontSize: 16,
    textAlign: "center",
  },
  textField: {
    width: 308,
    height: 48,
    padding: 2,
    paddingLeft: 16,
    border: `1px solid ${theme.palette.colors.border.light}`,
    fontFamily: "Roboto, sans-serif",
    fontSize: 16,
    borderRadius: 2,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  formText: {
    textAlign: "center",
    color: "#464646",
    fontSize: 18,
    marginTop: 10,
    marginLeft: 8,
    marginRight: 8,
    [theme.breakpoints.down("xs")]: {
      marginTop: 8,
    },
  },
}));

type FormValues = {
  emailAddress: string;
};

export function ResetPasswordModal() {
  const classes: any = useStyles({});
  const { t } = useTranslation(["signIn", "common"]);

  const { register, handleSubmit, errors } = useForm();

  const handleRestorePasswordSubmit: SubmitHandler<FormValues> = (data) => {
    const { emailAddress } = data;

    Auth.forgotPassword(trim(emailAddress)).then(() => {
      ModalHelper.close();
      navigate("/reset-password", {
        state: { emailAddress, referrer: "reset-password-modal" },
        replace: false,
      });
    });
  };

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        item
        direction="column"
        justifyContent="center"
        alignItems="center"
        xs={12}
        sm={10}
        className={classes.container}
      >
        <Grid item>
          <Typography className={classes.header}>
            {t("common:resetPassword")}
          </Typography>
          <Typography className={classes.modalText} style={{ width: 200 }}>
            {t("restorePasswordModal.enterEmail")}
          </Typography>
        </Grid>
        <Grid>
          <form onSubmit={handleSubmit(handleRestorePasswordSubmit)}>
            <Grid
              container
              item
              justifyContent="flex-start"
              alignItems="flex-end"
              className={classes.textFieldContainer}
            >
              <Typography className={classes.modalText}>
                {t("common:emailAddress")}
              </Typography>
              <input
                id="sign-up-email"
                placeholder={t("common:emailAddress")}
                name={"emailAddress"}
                ref={register({
                  required: true,
                  validate: (value) => UserUtils.isValidUsername(value),
                })}
                className={classes.textField}
              />
              {errors.newPassword && (
                <Error>{t("common:errors.emailAddress")}</Error>
              )}
            </Grid>
            <DialogButton
              colorVariant="pink"
              borderRadius={10}
              height={48}
              width="100%"
              type="submit"
            >
              {t("common:send")}
            </DialogButton>
          </form>
        </Grid>
        <Grid style={{ width: 312, marginLeft: "auto", marginRight: "auto" }}>
          <Typography className={classes.formText}>
            {t("restorePasswordModal.account")}{" "}
            <Link to="/sign-in">
              <strong>{t("common:signIn")}</strong>
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
