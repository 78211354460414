export const analyticsScreenEvents: any = {
  onlineOffersScreen: "Online Offers Screen",
  onlineOfferDetailsScreen: "Online Details Screen",
  localOffersScreen: "Local Offers Screen",
  localDetailsScreen: "Local Details Screen",
  favouritesScreen: "Favourites Screen",
  walletScreen: "Wallet Screen",
  profileScreen: "Profile Screen",
  sendScreenFirst: "Send Screen First",
  sendScreenLast: "Send Screen Last",
  sendSuccessScreen: "Send Success Screen",
  mapScreen: "Map Screen",
  referralScreen: "Referral Screen",
  linkCardScreen: "Link Card Screen",
  travelScreen: "Travel Screen",
};

export const analyticsTrackEvents: any = {
  accountCreated: "Account Created",
  seeOfferDetailsButton: "See Offer Details Button",
  shopAndEarnButton: "Shop and Earn Button",
  viewOfferDetails: "View Offer Details",
  viewCLODetails: "View CLO Details",
  sendFinalButton: "Send Final Button",
  sendTimeout: "Send Timeout",
  permission: "Permission",
  emailSigninButton: "Email Signin",
  facebookSigninButton: "Facebook Signin",
  appleSigninButton: "Apple Signin",
  emailNewSignupButton: "Email New Signup",
  facebookNewSignupButton: "Facebook New Signup",
  referralShareButton: "Referral Share Button",
  linkCardButton: "Link Card Button",
  tutorialCompleteButton: "Tutorial Complete Button",
  countrySelection: "Country Selection",
  cardLinked: "Card Linked",
  unlinkCard: "Unlink Card",
  signUpCompleteEmailSent: "Signup Complete Email Sent",
  openApp: "Open App",
  travelSearchDetails: "Travel Search Details",
};
