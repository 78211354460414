import QueryString from "query-string";
import { isServerSideRendering } from "@utils";

interface ParsedQuery {
  [key: string]: string | boolean | (string | boolean)[];
}

export const parseUrlParams = (
  locationSearch: string,
  clearParams: boolean = true
): ParsedQuery => {
  if (isServerSideRendering()) return;

  if (!locationSearch) return undefined;

  const parsed: ParsedQuery = QueryString.parse(locationSearch, {
    parseBooleans: true,
  });

  if (clearParams) window.history.replaceState({}, document.title, "/" + "");

  return parsed;
};
