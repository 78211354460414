export const fr = {
  filtersHeader: {
    filters: "Filtres",
    more: "+ Plus",
    orderBy: "Commandé:",
    categories: {
      ALL: "Toutes les offres",
      MENS: "Hommes",
      WOMENS: "Femmes",
      ACTIVITIES_EVENTS: "Activités et événements",
      SPORTS_OUTDOOR: "Sports et Plein Air",
      HOME_GARDEN: "Maison et Jardin",
      ELECTRONICS: "Électronique",
      HOTEL_TRAVEL: "Hôtel et Voyage",
      BEAUTY_WELLNESS: "Beauté et bien-être",
      BABY_KIDS: "Bébé et enfants",
      FOOD_GROCERY: "Alimentation et épicerie",
      EDUCATION: "Éducation",
      FINANCE_CRYPTO: "Finance et Crypto",
      LUXURY: "Luxe",
    },
    orderOptions: {
      Featured: "En vedette",
      "Highest cashback": "Cashback",
      "A-Z": "A-Z",
      "Z-A": "Z-A",
      "New offers": "Nouvelles offres",
    },
    displayOptions: {
      Cards: "Cartes",
      List: "Liste",
    },
    successModal: {
      welcome: "Bienvenue chez Coinmiles!",
      youGotBtc: "Vous venez de recevoir 1000 sats",
      youCanNowOfficially:
        "Vous pouvez officiellement dire que vous avez reçu des Bitcoin gratuitement. Fantastique! Continuez à en gagner en utilisant Coinmiles pour vos achats en ligne.",
      great: "Génial!",
    },
    emailOptInModal: {
      welcome: "Restez à l'affût!",
      youCanNowOfficially:
        "Nous souhaitons pouvoir vous envoyer des courriels contenant des offres spéciales et des rabais exclusifs.",
      yesButton: "D'accord",
      noButton: "Pas pour moi",
    },
  },
  redirect: {
    expiredOffer: "Offre expirée",
    redirecting: "Nous vous redirigeons vers...",
    activated: "Activé!",
    adBlockDetectedHeader: "Nous avons détecté un Adblock",
    adBlockDetectedText:
      "Vous devez désactiver votre Adblock pour être récompensé dans Coinmiles. Lorsque vous l'aurez désactivé, rafraîchissez la page et vous serez automatiquement redirigé.",
    adBlockRefresh: "Rafraîchir",
    happyShopping: "Bon magasinage!",
    oops: "Oups! Cette offre n’est plus valide.",
    pleaseTry: "Veuillez essayer une offre différente.",
    report: "Rapport",
    rewardsMessage: {
      one: "Vos remises seront ",
      two: "automatiquement",
      three: " ajouté à votre compte.",
    },
  },
};
