export const en = {
    dialogText: {
        one:
            "We take security seriously, that's why we limited the transfer feature to the Coinmiles mobile app.",
        two:
            "Download it now to transfer your Bitcoin to your favourite wallet or to your bank account through Interac!",
        three:
            "Download it now to transfer your Bitcoin to your favourite wallet or to your bank account!",
    },
    myBalance: "My Balance",
    btcPrice: "BTC Price",
    interacTransfer: "Interac Transfer",
    bitcoinSent: "Bitcoin Sent",
    myActivity: "My Activity",
    missing: "Missing transactions?",
    clickHere: " Click Here",
    noRewards: "No rewards yet?",
    startVisiting: "Start visiting our partners and earn some cash back.",
    welcomeBonus: "Welcome Bonus",
    bonus: "Bonus",
    you: "You",
    referralSignUp: "signed up!",
    newUserReferralSignUp: "You were referred by ",
    missingTransactionsModal: {
        whyTransactionMissing: "Why is one of my transactions missing?",
        missingTransactionText: {
            one:
                "Don’t worry, your transaction may take up to 7 days to appear. While we do our best to provide you with real-time notifications as we receive them, we are working with various partners that may not have implemented this real-time capacity yet. \n \n In some cases, such as with travel advertisers, sales reporting may take weeks if not longer to show. This is because travel sites generally only report sales when customers check in for flights or hotels or pick up their rental car. \n \n If one of your transactions still doesn’t appear after this delay, notify us via email by ",
            two: "clicking here ",
            three: "and make sure to attach a copy of your receipt.",
        },
        transactionOtherCurrency:
            "Why are some transactions reported in another currency?",
        transactionCurrencyAnswer:
            "Our partners operate from various countries. Some of them automatically convert your purchase amount into their local currency and then pay us your reward in that currency. The currency we show you is the currency in which we receive your payout.",
    },
    rewardGift: "Gift from "
};
