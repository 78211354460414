export const en = {
  myProfile: "My Profile",
  profileSubheader: "Find all that you need about your account here",
  linkedCardDisplay: {
    dialogMessageString:
      "Credit card linking is currently only available on the mobile app. Download now to link your card and get rewarded.",
    myLinkedCards: "My linked cards",
    linkAnotherCard: "Do you want to link another card?",
    noLinkedCards: "You don't have any linked credit cards.",
    linkFirstCard: "Link a card now to get rewarded when you shop in store.",
    linkCardButton: "Link credit card",
  },
  modals: {
    upload: {
      uploading: "Uploading...",
      cropAndUpload: "Crop and upload your profile photo",
      selectAnotherPhoto: "Select another photo",
      uploadPhoto: "Upload photo",
      dragAndDrop: "Drag and drop your photo here",
      or: "OR",
      selectFromFiles: "Select from your files",
    },
    changePasswordSuccess: {
      passwordChanged: "Password changed",
      successText: {
        one:
          "Your password was changed successfully. Login again with your new password to continue using Coinmiles.",
        two: "Ok",
      },
    },
    deleteLinkedCard: {
      deleteConfirmation: "Are you sure you want to unlink this card?",
      yesUnlink: "Yes, unlink card",
      noKeepLinked: "No, keep it linked",
    },
  },
};
