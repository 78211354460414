import * as React from "react";

export const BitcoinIllustrationIcon = (
  props: React.SVGProps<SVGSVGElement>,
  style: any
) => (
  <svg viewBox="0 0 175 168" style={style} {...props}>
    <defs>
      <clipPath id="prefix__clip-path">
        <circle className="prefix__cls-1" cx={80} cy={84} r={79} />
      </clipPath>
      <clipPath id="prefix__clip-path-2">
        <circle className="prefix__cls-2" cx={95} cy={84} r={61.5} />
      </clipPath>
      <radialGradient
        id="prefix__radial-gradient"
        cx={95}
        cy={84}
        r={73.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.96} stopColor="#f4a508" />
        <stop offset={1} stopColor="#f48908" />
      </radialGradient>
      <style>
        {
          ".prefix__cls-1{fill:#ff7f00}.prefix__cls-2{fill:#ff9d02}.prefix__cls-4{fill:#fd9f02}.prefix__cls-5{fill:#c97632;opacity:.3}.prefix__cls-6{fill:#ffc933}"
        }
      </style>
    </defs>
    <path className="prefix__cls-1" d="M66 156l29 6v2s-27 1-43-6 14-2 14-2z" />
    <circle className="prefix__cls-1" cx={80} cy={84} r={79} />
    <g clipPath="url(#prefix__clip-path)">
      <path
        className="prefix__cls-4"
        d="M-9.86 11.42h81.54v6.91H-9.86zM-9.86 26.73h81.54v6.91H-9.86zM-9.86 42.03h81.54v6.91H-9.86zM-9.86 57.34h81.54v6.91H-9.86zM-9.86 72.64h81.54v6.91H-9.86zM-9.86 87.95h81.54v6.91H-9.86zM-9.86 103.26h81.54v6.91H-9.86zM-9.86 118.56h81.54v6.91H-9.86zM-9.86 133.87h81.54v6.91H-9.86zM-9.86 149.18h81.54v6.91H-9.86z"
      />
      <path
        className="prefix__cls-5"
        d="M-2 55.36S2 85 22.73 91.9s69.12 79 69.12 79l-56.29 1L4 143.25l-13.86-33.57V82z"
      />
    </g>
    <circle className="prefix__cls-6" cx={95} cy={84} r={80} />
    <circle cx={95} cy={84} r={73.5} fill="url(#prefix__radial-gradient)" />
    <circle className="prefix__cls-6" cx={95} cy={84} r={69.5} />
    <circle className="prefix__cls-2" cx={95} cy={84} r={61.5} />
    <g clipPath="url(#prefix__clip-path-2)">
      <path
        className="prefix__cls-5"
        d="M37 129c29 38 79 14 79 14s-41.3-.52-60.06-25.19S42 48 42 48s-34 43-5 81z"
      />
    </g>
    <path
      className="prefix__cls-1"
      d="M93.51 117.52h-6c0 .6-.07 1.14-.07 1.68v10.39a4.26 4.26 0 01-4 4.54 4.16 4.16 0 01-4.6-4c-.2-3.61-.19-7.23-.13-10.84 0-1.4-.35-1.85-1.8-1.82-4.38.1-8.76 0-13.14 0h-1.6v-8.11c0-.91.39-1.28 1.29-1.26h4a4.35 4.35 0 004.54-4.61V65.74c0-2.88-1.63-4.48-4.54-4.49-1.58 0-3.16-.06-4.74 0-1 0-1.46-.19-1.41-1.33.09-2 .09-4 0-5.95 0-1.14.38-1.37 1.41-1.36H78.59c0-.68.09-1.22.09-1.76v-9.63c.06-2.9 1.33-4.61 3.53-5a4.26 4.26 0 015.2 4.15c.07 3.52 0 7 0 10.55v1.61h6v-3.8-8a4.31 4.31 0 014-4.59c2.5-.21 4.21 1.09 4.72 3.72a15.47 15.47 0 01.17 2.73c0 2.8.1 5.61 0 8.4-.07 1.54.53 1.91 1.93 2.2a75.17 75.17 0 019.85 2.5A14.87 14.87 0 01123 63.2a13.89 13.89 0 01-3.73 16.6c-.65.55-1.32 1.08-2.15 1.76.58.28 1 .52 1.45.69 7.81 3 11.18 9.61 10.26 17.6-1 9.08-7.71 14.51-15.7 16.32a71.3 71.3 0 01-9.36 1.21c-1.12.11-1.46.44-1.44 1.56.07 3.26.08 6.52 0 9.78a8 8 0 01-.69 3.22 4.07 4.07 0 01-4.8 2.05 4.36 4.36 0 01-3.33-4.39v-10.24zm-6-19.83c0 2.49.07 5 0 7.48 0 1.19.32 1.71 1.51 1.67 4.62-.14 9.25-.12 13.86-.45a9.38 9.38 0 007.41-4.24c3.06-4.54.93-11.1-4.18-12.41a30.39 30.39 0 00-5.86-.89c-3.65-.19-7.31-.18-11-.26-1.32 0-1.85.49-1.77 1.92.09 2.39-.01 4.79-.01 7.18zm0-26.86v7.48c0 .73.05 1.35 1 1.31 4-.13 7.94 0 11.85-.44A8.11 8.11 0 00107 67.84c-1.37-3.31-4.37-4.91-7.67-5.33-3.5-.46-7.1-.2-10.66-.3-1 0-1.19.41-1.17 1.28 0 2.45-.03 4.89-.03 7.34z"
    />
    <path
      className="prefix__cls-6"
      d="M91.63 115.64h-5.95c0 .6-.08 1.14-.08 1.69v10.39a4.25 4.25 0 01-4 4.53 4.16 4.16 0 01-4.6-4.05c-.19-3.61-.19-7.23-.13-10.84 0-1.39-.35-1.85-1.8-1.82-4.38.1-8.76 0-13.14 0h-1.64v-8.12c0-.91.39-1.28 1.29-1.26h4a4.36 4.36 0 004.6-4.59V63.86c0-2.88-1.62-4.48-4.54-4.49h-4.73c-1 .05-1.47-.19-1.42-1.33.09-2 .1-4 0-6-.05-1.14.38-1.36 1.42-1.35H76.77c0-.68.09-1.23.09-1.77v-9.63c.06-2.89 1.33-4.61 3.54-5a4.24 4.24 0 015.2 4.14c.06 3.52 0 7 0 10.55v1.61h6v-3.8-8a4.32 4.32 0 014-4.59c2.5-.21 4.2 1.1 4.71 3.72a15.53 15.53 0 01.17 2.73c0 2.8.1 5.61 0 8.4-.08 1.55.53 1.92 1.92 2.2a74.77 74.77 0 019.85 2.5 15 15 0 018.92 7.45 13.92 13.92 0 01-3.73 16.6l-2.16 1.77c.58.27 1 .52 1.45.69C124.53 83.35 127.89 90 127 98c-1 9.08-7.72 14.51-15.71 16.32a72.9 72.9 0 01-9.35 1.22c-1.13.1-1.47.44-1.45 1.55.07 3.26.08 6.52 0 9.78a7.9 7.9 0 01-.69 3.23 4.07 4.07 0 01-4.8 2 4.32 4.32 0 01-3.32-4.39c-.05-3.41 0-6.83 0-10.24zm-6-19.83c0 2.49.06 5 0 7.48 0 1.19.32 1.71 1.5 1.67 4.62-.14 9.26-.12 13.86-.45a9.37 9.37 0 007.41-4.23c3.07-4.55.93-11.11-4.17-12.42A29.84 29.84 0 0098.3 87c-3.65-.2-7.31-.19-11-.27-1.33 0-1.86.5-1.78 1.93.17 2.34.08 4.76.08 7.15zm0-26.86v7.49c0 .72 0 1.34 1 1.31 4-.14 8 0 11.86-.45a8.12 8.12 0 006.6-11.3c-1.37-3.31-4.37-4.91-7.67-5.33-3.51-.45-7.11-.2-10.67-.3-1 0-1.19.41-1.17 1.29.04 2.4.01 4.85.01 7.34z"
    />
    <ellipse
      cx={122.7}
      cy={60.53}
      rx={16.79}
      ry={29.03}
      transform="rotate(-37.24 122.71 60.53)"
      fill="#fff2e1"
      opacity={0.3}
    />
    <ellipse
      cx={119.3}
      cy={67.92}
      rx={49.95}
      ry={62.33}
      transform="rotate(-37.24 119.309 67.927)"
      opacity={0.15}
      fill="#fff2e1"
    />
  </svg>
);
