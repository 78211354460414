const largeBitcoinIcons: any[] = [
  {
    top: 199,
    left: 176,
    transform: "rotate(-16deg)",
    size: 40,
    delay: 0.3,
  },
  {
    top: 140,
    left: 220,
    transform: "rotate(31deg)",
    size: 47,
    delay: 0.5,
  },
  {
    top: 90,
    left: 138,
    transform: "rotate(-17deg)",
    size: 55,
    delay: 0.7,
  },
  {
    top: 34,
    left: 236,
    transform: "rotate(25deg)",
    size: 66,
    delay: 0.8,
  },
  {
    top: "-30px",
    left: 117,
    transform: "rotate(-36deg)",
    size: 70,
    delay: 1,
  },
];

export { largeBitcoinIcons };
