import React from "react";

type Props = {
  profilePhotoUrl: string;
  size: number;
};

export const ProfilePhoto = ({profilePhotoUrl, size}: Props) => {
  return (
    <img
      src={profilePhotoUrl}
      alt="user-profile"
      style={{margin: "auto", height: size, width: size, borderRadius: "50%"}}
    />
  );
};
