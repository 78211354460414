import { affiliateServiceAreas } from "@models";

export function convertToFormattedValue(value: number, includeDecimal = true): string {
    if (!value) return "";

    const num_parts = value.toFixed(2).toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return includeDecimal ? `${num_parts.join(".")}` : num_parts[0];
}

// Get the currency code (CAN, USD, EUR, etc.) based on country
export function getCurrencyCodeByCountry(countrySearch: string): string {
    return (
        affiliateServiceAreas.find((country) => country.countryCode === countrySearch)
            ?.currencyCode ?? "CAD"
    );
}

// Get the currency sign ($, €, £, etc.) based on country
export function getCurrencySignByCountry(countryCode: string): string {
    return (
        affiliateServiceAreas.find((country) => country.countryCode === countryCode)
            ?.currencySign ?? "$"
    );
}

// Get the currency sign ($, €, £, etc.) based on currency
export function getCurrencySignByCurrency(currency: string): "$" | "€" {
    return {
        CAD: "$",
        USD: "$",
        EUR: "€",
    }[currency] as "$" | "€";
}
