import React from "react";
import PropTypes from "prop-types";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";

import { ApolloProvider, UserPoolClient } from "@apollo";

import { useIsClient } from "@hooks";
import { i18n, I18nextProvider } from "@i18n";
import { GatsbyTheme as theme } from "@styles";

import { HeadComponent } from "../head-component";

export const TopLayout = ({ children }) => {
  const { isClient, key } = useIsClient();

  return (
    <div key={key}>
      <HeadComponent />
      <ApolloProvider client={UserPoolClient}>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {!isClient ? null : children}
          </ThemeProvider>
        </I18nextProvider>
      </ApolloProvider>
    </div>
  );
};

TopLayout.propTypes = {
  children: PropTypes.node,
};
