import React, { useState } from "react";

// Library imports
import { motion, AnimatePresence } from "framer-motion";

// Misc Imports

import { useUpdateUser } from "@hooks";
import { useTranslation } from "@i18n";
import { User, Wallet } from "@models";
import { makeStyles, cardVariants } from "@styles";
import { AuthUtils, ModalHelper, navigate } from "@utils";

// Component imports
import { BtcBalanceDisplay, ProfilePhoto } from "@components";
import { CameraIcon, CloseIcon } from "@icons";
import { Paper, Grid, Typography } from "@material";

const useStyles = makeStyles((theme: Theme) => ({
  profileContainer: {
    backgroundColor: "white",
    border: `1px solid #EBEBEB`,
    width: "100%",
    height: 510,
    padding: 24,
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      padding: 15,
    },
  },
  cameraIconContainer: {
    position: "absolute",
    display: "flex",
    alignSelf: "flex-end",
    height: 42,
    width: 42,
    border: "1px solid #CDCDCD",
    borderRadius: 4,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#F4F4F4 !important",
      transitionProperty: "background-color",
      transitionDuration: "0.3s",
    },
  },
  cameraIcon: {
    position: "absolute",
    display: "flex",
    alignSelf: "flex-end",
    height: 24,
    width: 24,
    border: "1px solid #CDCDCD",
    borderRadius: 4,
  },
  profilePictureContainer: {
    position: "relative",
    marginTop: 15,
    height: 116,
    width: 116,
  },
  deleteContainer: {
    height: 116,
    width: 116,
    borderRadius: "50%",
    position: "absolute",
    top: "0",
    left: "0",
    backgroundColor: "grey",
    opacity: 0.8,
  },
  initialsBackground: {
    marginTop: 15,
    backgroundColor: "#582DFF",
    width: 116,
    height: 116,
    borderRadius: "50%",
  },
  initialsText: {
    color: "white",
    fontSize: 42,
    fontWeight: 600,
  },
  usernameText: {
    marginTop: 13,
    color: "black",
    fontSize: 24,
    fontWeight: 500,
  },
  userEmailText: {
    marginTop: 4,
    color: "black",
    fontSize: 16,
    fontWeight: 400,
  },
  btcDisplayContainer: {
    marginTop: 13,
  },
  transactionsDisplayContainer: {
    marginTop: 10,
    marginBottom: 28,
  },
  sendButtonMobile: {
    fontSize: "4.5vw",
    fontWeight: 800,
  },
  profileButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 8,
    backgroundColor: "white",
    cursor: "pointer",
    height: 47,
    width: 254,
    minWidth: 254,
    textTransform: "none",
    border: "1px solid #464646",
    borderRadius: 0,
    color: "black",
    fontSize: 16,
    fontWeight: 500,
    transitionProperty: "background-color",
    transitionDuration: "0.4s",
    transitionTimingFunction: "ease-out",
    "&:hover": {
      backgroundColor: "#F4F4F4 !important",
      transitionProperty: "background-color",
      transitionDuration: "0.4s",
    },
  },
}));

type Props = {
  user: User;
  wallet: Wallet;
};

export function ProfileDisplay({ user, wallet }: Props) {
  const classes: any = useStyles({});
  const { t } = useTranslation("common");

  const { updateUser } = useUpdateUser();

  const handleOpenProfilePicUploadModal = (): void => {
    ModalHelper.open({ modalType: "profilePicUpload" });
  };

  const handleClearProfilePic = async (): Promise<void> =>
    updateUser({ profilePhotoUrl: null });

  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);

  const handleMouseHover = (): void => {
    setDeleteVisible(!deleteVisible);
  };

  const getUserInitials = (firstName: string, lastName: string): string =>
    firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();

  const onSignOut = async (): Promise<void> => {
    await AuthUtils.signOut();
  };

  return (
    <Paper className={classes.profileContainer}>
      <Grid container direction="column" alignItems="flex-start">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.cameraIconContainer}
          onClick={handleOpenProfilePicUploadModal}
        >
          <CameraIcon width={24} />
        </Grid>
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          sm={12}
          md={12}
          lg={12}
        >
          {user.profilePhotoUrl ? (
            <>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                onMouseEnter={!deleteVisible ? handleMouseHover : undefined}
                onMouseLeave={deleteVisible ? handleMouseHover : undefined}
                className={classes.profilePictureContainer}
              >
                <ProfilePhoto
                  profilePhotoUrl={user.profilePhotoUrl}
                  size={116}
                />
                <AnimatePresence>
                  {user.profilePhotoUrl && deleteVisible && (
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={cardVariants}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={classes.deleteContainer}
                      >
                        <CloseIcon
                          width={25}
                          fill={"black"}
                          onClick={handleClearProfilePic}
                          style={{ cursor: "pointer" }}
                        />
                      </Grid>
                    </motion.div>
                  )}
                </AnimatePresence>
              </Grid>
            </>
          ) : (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={classes.initialsBackground}
            >
              <Typography className={classes.initialsText}>
                {getUserInitials(user.firstName, user.lastName)}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid container justifyContent="center" alignItems="center">
          <Typography className={classes.usernameText}>
            {`${user.firstName} ${user.lastName}`}
          </Typography>
        </Grid>
        <Grid container justifyContent="center" alignItems="center">
          <Typography className={classes.userEmailText}>
            {user.emailAddress}
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.btcDisplayContainer}
        >
          <BtcBalanceDisplay
            btcBalance={wallet.balanceNotIncludingFrozen.btc.value}
          />
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.transactionsDisplayContainer}
        >
          <Typography className={classes.userEmailText}>
            {wallet.transactions.length}{" "}
            {wallet.transactions.length === 1
              ? t("transaction")
              : t("transactions")}
          </Typography>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" item sm={12}>
          <div
            className={classes.profileButton}
            onClick={() => navigate("/change-password")}
          >
            {t("changePassword")}
          </div>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" item sm={12}>
          <div className={classes.profileButton} onClick={onSignOut}>
            {t("logOut")}
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}
