export const pt = {
  filtersHeader: {
    filters: "Filtros",
    howItWorks: "Como funciona",
    more: "+ Mais",
    orderBy: "Filtrar por:",
    categories: {
      ALL: "Todas as Ofertas",
      MENS: "Homem",
      WOMENS: "Mulher",
      ACTIVITIES_EVENTS: "Atividades e Eventos",
      SPORTS_OUTDOOR: "Esportes e atividades ao ar livre",
      HOME_GARDEN: "Casa e Decoração",
      ELECTRONICS: "Eletrônicos",
      HOTEL_TRAVEL: "Hotéis e Viagens",
      BEAUTY_WELLNESS: "Beleza e Bem-estar",
      BABY_KIDS: "Infantil",
      FOOD_GROCERY: "Comida e Supermecado",
      EDUCATION: "Educação",
      FINANCE_CRYPTO: "Finanças e Crypto",
      LUXURY: "Artigos de luxo",
    },
    orderOptions: {
      Featured: "Destaques",
      "Highest cashback": "Muito mais em cashback",
      "A-Z": "A-Z",
      "Z-A": "Z-A",
      "New offers": "Novas ofertas",
    },
    displayOptions: {
      Cards: "Cartões",
      List: "Lista",
    },
  },
  successModal: {
    welcome: "Bem-vindo(a) à Coinmiles!",
    youGotBtc: "Você acabou de obter 1000 sats",
    youCanNowOfficially:
      "Agora você pode dizer oficialmente que recebeu alguns Bitcoins de graça. Incrível! Continue ganhando usando Coinmiles para suas compras online.",
    great: "Ótimo!",
  },
  emailOptInModal: {
    title: "Fique por dentro!",
    content:
      "Gostaríamos de te enviar e-mails de tempos em tempos contendo ofertas e promoções exclusivas.",
    yesButton: "Tudo bem",
    noButton: "Agora não",
  },
  redirect: {
    expiredOffer: "Oferta expirada",
    redirecting: "Redirecionando você à...",
    activated: "Ativado!",
    adBlockDetectedHeader: "Bloqueador de anúncios detectado",
    adBlockDetectedText:
      "Para que o Coinmiles possa recompensá-lo, você deve desativar seu bloqueador de anúncios. Depois de desativá-lo, pressione Atualizar e você será redirecionado automaticamente.",
    adBlockRefresh: "Atualizar",
    happyShopping: "Boas compras",
    oops: "Ops! Esta oferta não é mais válida.",
    pleaseTry: "Tente uma oferta diferente.",
    report: "Reportar",
    rewardsMessage: {
      one: "Suas recompensas serão ",
      two: "automaticamente",
      three: " ser adicionado à sua conta.",
    },
  },
};
