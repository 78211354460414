export const pt = {
  ambassador: "Embaixador(a)",
  applyFilters: "Aplicar filtros",
  backToMain: "Voltar à página principal",
  blog: "Blog",
  categories: "Categorias",
  changePassword: "Alterar a senha",
  checkOut: "Finalizar compra",
  contact: "Contato",
  details: "Detalhes",
  download: "Baixe o aplicativo!",
  earn: "Ganhe até ",
  exclusive: "Ver oferta exclusiva",
  faq: "perguntas frequentes",
  featured: "ofertas em destaque",
  filters: "Filtros",
  firstName: "Nome",
  gotIt: "Entendi",
  travel: "Viagens",
  orderBy: "Ordenar por",
  emailAddress: "Endereço de email",
  joinToEarn: "Inscreva-se para ganhar",
  joinPartner: "Seja um parceiro",
  keepExploring: "Continue explorando",
  lastName: "Sobrenome",
  listView: "Exibição de lista",
  loading: "Carregando...",
  login: "Faça Log in",
  logOut: "Sair",
  newPassword: "Nova senha",
  noThanks: "Não obrigado(a)",
  ok: "OK",
  oldPassword: "Senha antiga",
  or: "OU",
  password: "Senha",
  passwordConfirmation: "Confirmação de Senha",
  privacyPolicy: "política de Privacidade",
  profile: "Perfil",
  resetPassword: "Redefinir senha",
  search: "Buscar",
  select: "Selecionar",
  selectCategory: "Selecione uma categoria",
  selectCountry: "Selecione um país",
  selectLanguage: "Selecione um idioma",
  selectLocale: "Escolha seu país/idioma",
  send: "Enviar",
  shop: "Comprar",
  shopAt: "Compre em",
  shopOnline: "Compre online",
  signIn: "Faça log in",
  signInToEarn: "Faça login para ganhar",
  signInToView: "Faça login para ver a oferta",
  signUp: "Sign up",
  submit: "Enviar",
  termsAndConditions: "Termos e Condições",
  termsOfService: "Termos de serviço",
  transaction: "Transação",
  transactions: "Transações",
  viewDeal: "Ver oferta",
  wallet: "Carteira",
  welcome: "Seja bem-vindo(a)",
  whatILike: "Meus favoritos",
  giftCards: "Cartões de presente",
  yourBalance: "Seu saldo",
  errors: {
    emailAddress: "O endereço de email não é válido.",
    firstName: "Caracteres inválidos detectados no nome de usuário.",
    invalidCredentials: "E-mail ou senha inválidos",
    lastName: "Caracteres inválidos detectados no nome de usuário.",
    password: "A senha não é válida",
    passwordConfirmation: "A confirmação da senha não corresponde",
    passwordRequirements:
      "Digite uma senha contendo uma letra minúscula, uma letra maiúscula e um número. Certifique-se de que sua senha contém pelo menos 8 caracteres.",
    fileUploadError: "Ocorreu um erro ao tentar carregar sua foto de perfil.",
    mongoError: "Algo deu errado ao fazer login. Tente novamente.",
  },
};
