import React, {useMemo} from "react";
import {makeStyles} from "@styles";

const useStyles = makeStyles(() => ({
  gridCardRowContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(237px, 1fr))",
    gridColumnGap: 18,
    marginTop: 16,
    maxWidth: 1400,
  },
}));

type Props = {
  children: JSX.Element[];
  style: any;
};

export function GridCardRowContainer({children, style}: Props) {
  const classes: any = useStyles({});
  return (
    <div style={style} className={classes.gridCardRowContainer}>
      {children}
    </div>
  );
}
