import { gql } from "@apollo/client";

export const SCREEN_GET_PROFILE_LINKED_CARDS_DATA = gql`
  query ScreenGetProfileData {
    linkedCards: getLinkedCards {
      id
      brand
      expMonth
      expYear
      externalId
      last4
    }
  }
`;
