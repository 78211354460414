import React from "react";

// Component imports
import { DialogButton } from "@components";
import { Grid, Paper, Typography } from "@material";

// Misc Imports

import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";
import { ModalHelper } from "@utils";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: 800,
    height: "auto",
    padding: 24,
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 16,
      width: "95%",
      maxWidth: 400,
      height: "auto",
      margin: "auto",
    },
  },
  dialog: {
    height: "auto",
    borderRadius: 8,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
      alignItems: "flex-start",
    },
  },
  rocketIcon: { marginTop: 8 },
  missingTransactionText: {
    fontSize: 26,
    fontWeight: 600,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  },
  dialogText: {
    width: "80%",
    fontSize: 15.5,
    textAlign: "left",
    marginTop: 16,
    marginBottom: 8,
    whiteSpace: "pre-line",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      marginTop: 8,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      fontSize: 13,
    },
  },
  confirmButtonContainer: {
    width: "80%",
    marginTop: 24,
    marginBottom: 7,
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
}));

export function MissingTransactionsModal() {
  const classes: any = useStyles({});
  const { t } = useTranslation(["wallet", "common"]);

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        className={classes.dialog}
      >
        <Grid container item direction="column" justifyContent="center">
          <Typography className={classes.missingTransactionText}>
            {t("common:faq")}
          </Typography>
        </Grid>
        <Grid container item justifyContent="center">
          <Typography className={classes.dialogText}>
            <strong>
              {t("missingTransactionsModal.whyTransactionMissing")}
            </strong>
            <br />
            {t("missingTransactionsModal.missingTransactionText.one")}
            <strong>
              <a
                href="mailto: support@coinmiles.io"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "#000000" }}
              >
                {t("missingTransactionsModal.missingTransactionText.two")}
              </a>
            </strong>
            {t("missingTransactionsModal.missingTransactionText.three")}
            <br /> <br />
            <strong>
              {t("missingTransactionsModal.transactionOtherCurrency")}
            </strong>
            <br />
            {t("missingTransactionsModal.transactionCurrencyAnswer")}
          </Typography>
        </Grid>
        <Grid container item justifyContent="center">
          <Grid className={classes.confirmButtonContainer}>
            <DialogButton
              colorVariant="black"
              width={"100%"}
              onClick={ModalHelper.close}
            >
              {t("common:gotIt")}
            </DialogButton>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
