import { en } from "./translations.en";
import { fr } from "./translations.fr";
import { pt } from "./translations.pt";

export const translations = {
  en: {
    onlineScreen: en,
  },
  fr: {
    onlineScreen: fr,
  },
  pt: {
    onlineScreen: pt,
  },
};
