import { analyticsScreenEvents, analyticsTrackEvents } from "@constants";
import { User } from "@models";
import dayjs from "dayjs";

interface SegmentIdentifyEvent {
  user: User;
  id: string;
  language: string;
  countryCode: string;
  region: string;
  city: string;
}

export const sendSegmentIdentifyEvent = ({
  user,
  id,
  language,
  countryCode,
  region,
  city,
}: SegmentIdentifyEvent): void => {
  window?.analytics?.identify(id, {
    email: user.emailAddress,
    firstName: user.firstName,
    lastName: user.lastName,
    countryCode,
    region,
    city,
    language,
  });
};

export const sendSegmentScreenEvent = (
  eventName: AnalyticsScreenEventTypes,
  properties: any = null,
): void => {
  !properties
    ? window?.analytics?.track(analyticsScreenEvents[eventName], {})
    : window?.analytics?.track(analyticsScreenEvents[eventName], properties);
};

export const sendSegmentTrackEvent = (
  eventName: AnalyticsTrackEventTypes,
  properties: any = null,
): void => {
  !properties
    ? window?.analytics?.track(analyticsTrackEvents[eventName], {})
    : window?.analytics?.track(analyticsTrackEvents[eventName], properties);
};

export const sendSegmentAccountCreatedEvent = (emailOptIn: boolean): void => {
  sendSegmentTrackEvent("accountCreated", {
    signUpMethod: "email",
    creationTime: +dayjs().format("HH"),
    signUpOS: "Web",
    userReferral: false,
    emailOptIn,
  });
};
