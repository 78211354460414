import SecureLS from "secure-ls";

import { currentCountryVar, currentUserVar } from "@apollo";
import { User } from "@models";
import {
  getAffiliateServiceArea,
  fetchSignInData,
  getBonusSatsAmount,
  isServerSideRendering,
  LocaleUtils,
} from "@utils";

/*
This function is called on every startup of the app and is responsible for:
  - Assigning the current AffiliateServiceArea to the currentCountry Apollo reactive var
  - Ensuring a language value is set in localStorage (defaults to "en" if not set)
  - If a user was previously signed in, assigning their encrypted data to the currentUser Apollo reactive var 
  - Initializing the current bonus sats amount var from SSM
*/
export const initializeAppStartup = async (): Promise<void> => {
  if (isServerSideRendering()) return;

  (async function initBonusSatsVar() {
    await getBonusSatsAmount();
  })();

  // Updates wallet if page has just been refreshed due to a user changing country and language at the same time
  const userCountrySavedBeforeRefresh: string = localStorage.getItem(
    "userCountrySavedBeforeRefresh",
  );

  const encryptedLocalStorage = new SecureLS();
  const persistedUser: User = encryptedLocalStorage.get("persisted-user");

  if (persistedUser) {
    currentUserVar(persistedUser);

    if (!!userCountrySavedBeforeRefresh) {
      const userCountry: AffiliateServiceArea = getAffiliateServiceArea(
        userCountrySavedBeforeRefresh,
      );
      const { currencyCode } = userCountry;

      await fetchSignInData(currencyCode);

      localStorage.setItem("userCountry", userCountry.countryCode);
      currentCountryVar(userCountry);

      localStorage.removeItem("userCountrySavedBeforeRefresh");
    } else {
      const { currencyCode } = await LocaleUtils.setLocale();
      await fetchSignInData(currencyCode);
    }
  } else {
    if (!!userCountrySavedBeforeRefresh) {
      const newCountry: AffiliateServiceArea = getAffiliateServiceArea(
        userCountrySavedBeforeRefresh,
      );

      localStorage.setItem("userCountry", newCountry.countryCode);
      currentCountryVar(newCountry);

      localStorage.removeItem("userCountrySavedBeforeRefresh");
    } else {
      await LocaleUtils.setLocale();
    }
  }
};
