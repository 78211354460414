import i18n, { Resource } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { translations as Common } from ".";
import { translations as ChangePassword } from "../components/screens/change-password/translations";
import { translations as Footer } from "../components/layout/footer/translations";
import { translations as Navbar } from "../components/layout/navbar/translations";
import { translations as OnlineScreen } from "../components/screens/online/translations";
import { translations as Profile } from "../components/screens/profile/translations";
import { translations as ResetPassword } from "../components/screens/reset-password/translations";
import { translations as SignIn } from "../components/screens/sign-in/translations";
import { translations as SignUp } from "../components/screens/sign-up/translations";
import { translations as Wallet } from "../components/screens/wallet/translations";
import { translations as WhatILike } from "../components/screens/what-i-like/translations";
import { translations as Travel } from "../components/screens/travel/translations";
import { translations as GiftCards } from "../components/screens/gift-cards/translations";

const resources: Resource = {
  en: {
    ...ChangePassword.en,
    ...Common.en,
    ...Footer.en,
    ...Navbar.en,
    ...OnlineScreen.en,
    ...Profile.en,
    ...ResetPassword.en,
    ...SignIn.en,
    ...SignUp.en,
    ...Wallet.en,
    ...WhatILike.en,
    ...Travel.en,
    ...GiftCards.en,
  },
  fr: {
    ...ChangePassword.fr,
    ...Common.fr,
    ...Footer.fr,
    ...Navbar.fr,
    ...OnlineScreen.fr,
    ...Profile.fr,
    ...ResetPassword.fr,
    ...SignIn.fr,
    ...SignUp.fr,
    ...Wallet.fr,
    ...WhatILike.fr,
    ...Travel.fr,
    ...GiftCards.fr,
  },
  pt: {
    ...ChangePassword.pt,
    ...Common.pt,
    ...Footer.pt,
    ...Navbar.pt,
    ...OnlineScreen.pt,
    ...Profile.pt,
    ...ResetPassword.pt,
    ...SignIn.pt,
    ...SignUp.pt,
    ...Wallet.pt,
    ...WhatILike.pt,
    ...Travel.pt,
    ...GiftCards.pt,
  },
};

// Language detection options
const options: object = {
  order: ["localStorage"],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: options,
    fallbackLng: "en",
    debug: process.env.NODE_ENV === "production" ? false : true,

    interpolation: {
      escapeValue: false,
    },

    react: {
      wait: true,
    },
  });

export { i18n };
