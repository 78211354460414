export const pt = {
  signIn: "Faça login para continuar",
  walletSignIn: "Faça login para ver sua carteira",
  forgotPassword: "Esqueceu sua senha?",
  loginWithEmail: "Continuar com e-mail",
  notMember: "Não é um membro?",
  joinCoinmiles: " Junte-se à Coinmiles",
  social: {
    facebook: "Continuar com o Facebook",
    apple: "Continuar com a Apple",
  },
  activationModal: {
    confirmEmail: "Confirme seu email",
    sentEmail: "Enviamos um e-mail contendo um link de confirmação.",
    confirmBefore: "Confirme seu e-mail antes de entrar.",
    sendAgain: "Envie novamente",
  },
  restorePasswordModal: {
    restorePassword: "Restaurar senha",
    enterEmail: "Digite seu endereço de e-mail para redefinir sua senha.",
    email: "Endereço de email",
    send: "Enviar",
    account: "Você tem uma conta?",
    signIn: " Log in",
  },
};
