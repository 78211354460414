import React from "react";

import {Assets} from "@assets";

type Props = {
  variant?: string;
  className?: any;
};

export const CoinmilesLogo = ({variant, className}: Props) => (
  <img
    src={variant === "white" ? Assets.COINMILES_LOGO_W : Assets.COINMILES_LOGO}
    alt="coinmiles-logo"
    style={{filter: variant === "black" ? "brightness(0%)" : null}}
    className={className}
  />
);
