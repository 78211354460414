import React, { useState, MouseEvent } from "react";

// Component imports
import { CompassIcon, ExpandMoreIcon, ShopIcon } from "@icons";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material";

// Misc Imports
import { useMediaQuery } from "@hooks";
import { useTranslation } from "@i18n";
import { makeStyles, withStyles } from "@styles";
import { ModalHelper } from "@utils";

const useStyles = makeStyles((theme: Theme) => ({
  categoryHeaderContainer: {
    width: 250,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonOuterContainer: {
    marginLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 8,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  categoryButton: {
    cursor: "pointer",
    outline: "none",
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    height: 43,
    width: "100%",
    padding: 8,
    fontSize: 14,
    fontWeight: 400,
    textAlign: "center",
    border: `1px solid ${theme.palette.colors.border.light}`,
    borderRadius: 4,
    textTransform: "none",
    marginBottom: 8,
    marginRight: 0,
    [theme.breakpoints.down("sm")]: {
      width: "32%",
      padding: 4,
      paddingLeft: `10px !important`,
      marginRight: 8,
      "&:last-child": {
        marginRight: 0,
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: 4,
      marginRight: 0,
    },
  },
  categoryButtonSelected: {
    backgroundColor: "black !important",
    color: "white !important",
    transitionProperty: "background-color, color",
    transitionDuration: "0.3s",
    "&:hover": {
      color: "white !important",
      backgroundColor: "black !important",
      transitionProperty: "background-color, color",
      transitionDuration: "0.3s",
    },
  },
  categoryButtonUnselected: {
    paddingLeft: 12,
    backgroundColor: "white",
    color: "black",
    transitionProperty: "background-color",
    transitionDuration: "0.3s",
    "&:hover": {
      backgroundColor: "#F4F4F4 !important",
      transitionProperty: "background-color",
      transitionDuration: "0.3s",
    },
  },
  innerButton: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: 8,
    },
  },
  buttonGroup: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginLeft: 8,
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  orderByText: {
    fontSize: 16,
  },
  typeText: {
    fontSize: 16,
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {},
  },
  menuDropdown: {
    width: "100%",
    paddingTop: 10,
    paddingBottom: 8,
  },
  dropdownItem: {
    display: "flex",
    padding: "8px !important",
    justifyContent: "space-between",
    position: "relative",
    bottom: 0,
    border: `1px solid ${theme.palette.colors.border.light}`,
    borderRadius: 4,
    height: 44,
    transitionProperty: "background-color",
    transitionDuration: "0.3s",
    "&:focus": {
      backgroundColor: "#efefef !important",
      transitionProperty: "background-color",
      transitionDuration: "0.3s",
    },
  },
  listItemText: {
    fontSize: "15px !important",
  },
  filtersButton: {
    height: 43,
    width: "100%",
    padding: 8,
    fontSize: 14,
    fontWeight: 400,
    textAlign: "center",
    border: `1px solid ${theme.palette.colors.border.light}`,
    borderRadius: 8,
    textTransform: "none",
  },
  buttonIcon: {
    marginLeft: 3,
    marginTop: 8,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 4,
    },
  },
}));

const StyledMenu = withStyles({
  paper: {
    marginTop: 8,
    border: "1px solid #efefef !important",
  },
})(Menu);

const StyledMenuItem = withStyles({
  root: {
    transitionProperty: "background-color",
    transitionDuration: "0.3s",
    "&:hover": {
      backgroundColor: "#F4F4F4 !important",
      transitionProperty: "background-color",
      transitionDuration: "0.3s",
    },
  },
  selected: {
    backgroundColor: "#efefef !important",
  },
})(MenuItem);

type Props = {
  category: string;
  setCategory: any;
  order: string;
  setOrder: any;
};

export function WhatILikeFilters({
  category,
  setCategory,
  order,
  setOrder,
}: Props) {
  const classes: any = useStyles({});
  const extraSmall: boolean = useMediaQuery("(max-width:599px)");
  const small: boolean = useMediaQuery("(max-width:959px)");
  const { t } = useTranslation("whatILike");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const orderOptions: FilterOption[] = [
    { id: "Featured", name: "Featured" },
    { id: "Highest cashback", name: "Highest cashback" },
    { id: "A-Z", name: "A-Z" },
    { id: "Z-A", name: "Z-A" },
    { id: "New offers", name: "New offers" },
  ];
  const orderOptionsTranslated: FilterOption[] = orderOptions.map(
    (option: { id: string }) => {
      return { id: option.id, name: t(`filters.orderOptions.${option.id}`) };
    },
  );

  const whatILikeOptions: FilterOption[] = [
    { id: "All Offers", name: "All Offers" },
    { id: "Online", name: "Online", icon: ShopIcon },
    { id: "In-Store", name: "In-Store", icon: CompassIcon },
  ];
  const whatILikeOptionsTranslated: FilterOption[] = whatILikeOptions.map(
    (option: FilterOption) => {
      return {
        id: option.id,
        name: t(`filters.whatILikeOptions.${option.id}`),
        icon: option.icon,
      };
    },
  );

  const handleOpenAppDownloadModal = (): void => {
    ModalHelper.open({
      modalType: "appDownload",
      modalProps: {
        width: 570,
        messageStrings: [
          t("filters.dialogMessage.one"),
          t("filters.dialogMessage.two"),
        ],
      },
    });
  };

  const handleCategorySelect = (event: MouseEvent<HTMLButtonElement>): void => {
    const { value: newCategory } = event.currentTarget as HTMLButtonElement;
    // Temporary handling until In-Store offers for webapp implemented
    if (newCategory === "In-Store") {
      handleOpenAppDownloadModal();
    } else if (newCategory !== null) {
      setCategory(newCategory);
    }
  };

  const handleOrderSelect = (
    event: MouseEvent<HTMLElement>,
    newOrder: string,
  ): void => {
    newOrder !== null && setOrder(newOrder);
    setAnchorEl(null);
  };

  // Dropdown menu handlers
  const handleClickListItem = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent={small ? "space-between" : "flex-start"}
      className={classes.categoryHeaderContainer}
    >
      <Grid container item sm={4} md={12} alignItems="center">
        <Typography className={classes.orderByText}>
          {t("filters.orderBy")}
        </Typography>
        <br />
        <List aria-label="order-by" className={classes.menuDropdown}>
          <ListItem
            button
            className={classes.dropdownItem}
            onClick={handleClickListItem}
          >
            <ListItemText
              primary={
                orderOptionsTranslated.find((trans) => trans.id === order).name
              }
              classes={{ primary: classes.listItemText }}
              style={{ paddingRight: 0 }}
            />
            <ExpandMoreIcon width={8} style={{ marginRight: 4 }} />
          </ListItem>
        </List>
        <StyledMenu
          id="order-by-menu"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          transitionDuration={600}
        >
          {orderOptionsTranslated.map((option: FilterOption, index: number) => (
            <StyledMenuItem
              key={index + 1}
              selected={option.id === order}
              value={option.name}
              onClick={(event) => handleOrderSelect(event, option.id)}
            >
              {option.name}
            </StyledMenuItem>
          ))}
        </StyledMenu>
      </Grid>
      <Grid
        container
        item
        direction={small ? "row" : "column"}
        justifyContent="flex-start"
        alignItems={small ? "flex-end" : "stretch"}
        sm
        md={12}
        className={classes.buttonOuterContainer}
      >
        <Typography className={classes.typeText}>
          {t("filters.type")}
        </Typography>
        <Grid
          container
          direction={small ? "row" : "column"}
          justifyContent="space-between"
        >
          {whatILikeOptionsTranslated.map(
            (option: FilterOption, index: number, array: FilterOption[]) => {
              return (
                <button
                  key={option.id}
                  value={option.id}
                  onClick={handleCategorySelect}
                  className={`${classes.categoryButton} ${
                    category !== option.id
                      ? classes.categoryButtonUnselected
                      : classes.categoryButtonSelected
                  }`}
                >
                  {!extraSmall && option.icon && (
                    <option.icon
                      width={16}
                      fill={category === option.id ? "white" : "black"}
                      style={{ marginRight: 8 }}
                    />
                  )}
                  {option.name}
                </button>
              );
            },
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
