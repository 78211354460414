export const fr = {
  myProfile: "Mon Profil",
  profileSubheader:
    "Trouvez tout ce dont vous avez besoin sur votre compte ici ",
  linkedCardDisplay: {
    dialogMessageString:
      "Veuillez télécharger l’appli Coinmiles pour lier votre carte de crédit et obtenir des remises sur achat en magasin.",
    myLinkedCards: "Mes cartes liées",
    linkAnotherCard: "Voulez-vous lier une autre carte?",
    noLinkedCards: "Vous n’avez aucune carte de liée.",
    linkFirstCard:
      "Lier une carte maintenant pour obtenir des remises pour vos achats en magasin.",
    linkCardButton: "Lier une carte de crédit",
  },
  modals: {
    upload: {
      uploading: "Uploading...",
      cropAndUpload: "Crop and upload your profile photo",
      selectAnotherPhoto: "Select another photo",
      uploadPhoto: "Upload photo",
      dragAndDrop: "Drag and drop your photo here",
      or: "OR",
      selectFromFiles: "Select from your files",
    },
    changePasswordSuccess: {
      passwordChanged: "Mot de passe changé",
      successText: {
        one:
          "Votre mot de passe à été changé avec succès. Connectez-vous à nouveau avec votre nouveau mot de passe pour continuer à utiliser Coinmiles.",
        two: "ok",
      },
    },
    deleteLinkedCard: {
      deleteConfirmation:
        "Êtes-vous certain de vouloir déconnecter cette carte?",
      yesUnlink: "Oui, déconnecter cette carte",
      noKeepLinked: "Non, laissez-la connecté",
    },
  },
};
