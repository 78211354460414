import { useEffect } from "react";
import SecureLS from "secure-ls";

import { Auth } from "@aws-amplify/auth";
import { Hub, HubCapsule } from "@aws-amplify/core";

import { currentUserVar } from "@apollo";

// Misc imports
import { useUpdateUser } from "@hooks";
import {
  fetchSignInData,
  isServerSideRendering,
  LocaleUtils,
  ModalHelper,
  navigate,
} from "@utils";

const isValidSignIn = (authenticationFlowType: string): boolean =>
  authenticationFlowType === "USER_PASSWORD_AUTH";

type HubCallbackAsync = (capsule: HubCapsule) => Promise<void>;

export function useSocialAuthListener(): void {
  if (isServerSideRendering()) {
    return;
  }

  const { updateUserMetadata } = useUpdateUser();

  const socialSignIn = async (): Promise<void> => {
    try {
      await Auth.currentAuthenticatedUser();

      const { currencyCode } = await LocaleUtils.setLocale();

      const user = await fetchSignInData(currencyCode);

      if (!!user.didLogin && !user.metadata) await updateUserMetadata(user);

      const encryptedLocalStorage = new SecureLS();
      encryptedLocalStorage.set("persisted-user", user);
      currentUserVar(user);
      ModalHelper.close();
    } catch (error) {
      await Auth.signOut();
      currentUserVar(null);
      ModalHelper.close();
      navigate("/sign-in", {
        state: { signInError: true },
      });
    }
  };

  // Listener for Auth events for OAuth social login
  useEffect(() => {
    const authEventHandler = async ({
      payload: { data, event },
    }: HubCapsule) => {
      if (!data?.authenticationFlowType) return;

      switch (event) {
        case "signIn":
          if (isValidSignIn(data?.authenticationFlowType)) {
            try {
              ModalHelper.open({
                modalType: "authOverlayModal",
                modalOptions: { disableBackdropClick: true },
              });

              await socialSignIn();
            } catch (err) {
              ModalHelper.close();
            }
          }
          break;
        case "signIn_failure":
          await Auth.signOut();
          currentUserVar(null);
          break;
      }
    };

    Hub.listen("auth", authEventHandler as HubCallbackAsync);

    return () => Hub.remove("auth", authEventHandler as HubCallbackAsync);
  }, []);
}
