import React, { useEffect, useState } from "react";

import { modalStateVar, useReactiveVar } from "@apollo";

// Misc imports
import { makeStyles } from "@styles";
import { ModalHelper } from "@utils";

// Component imports
import { Backdrop, Fade, Modal, SwipeableDrawer } from "@material";

import { SignUpDrawer } from "@components";
import * as modalTypes from "../../modals";

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    maxWidth: 425,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const MODAL_TYPES: {
  [key: string]: (modalProps: any) => JSX.Element;
} = {
  activation: modalTypes.ActivationModal,
  adblockDetected: modalTypes.AdblockDetectedModal,
  appDownload: modalTypes.AppDownloadModal,
  emailOptIn: modalTypes.EmailOptInModal,
  linkedCardDelete: modalTypes.LinkedCardDeleteModal,
  localeSelection: modalTypes.LocaleSelectionModal,
  missingTransactions: modalTypes.MissingTransactionsModal,
  profilePicUpload: modalTypes.ProfilePicUploadModal,
  passwordChangedSuccess: modalTypes.PasswordChangedSuccessModal,
  resetPassword: modalTypes.ResetPasswordModal,
  signUp: modalTypes.SignUpModal,
  signUpSuccess: modalTypes.SignUpSuccessModal,
  authOverlayModal: modalTypes.AuthOverlayModal,
  success: modalTypes.SuccessModal,
  terms: modalTypes.TermsModal,
};

export function RootModal() {
  const classes: any = useStyles({});

  const modalState = useReactiveVar(modalStateVar);

  const modalType: ModalTypes = modalState?.modalType;

  const modalProps = modalState.modalProps;

  const { disableBackdropClick, onClose } = modalState?.modalOptions || {};

  const open: boolean = !!modalType;

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  useEffect(() => {
    setDrawerOpen(open);
  }, [open]);

  if (!modalType) {
    return null;
  }

  if (modalType === "signUpDrawer") {
    const SpecifiedDrawer = SignUpDrawer;

    return (
      <SwipeableDrawer
        anchor="bottom"
        open={drawerOpen}
        onClose={() => {
          if (onClose) onClose();
        }}
        onOpen={() => undefined}
        transitionDuration={400}
        PaperProps={{ classes: { root: classes.drawerPaper } }}
      >
        <SpecifiedDrawer {...modalProps} />
      </SwipeableDrawer>
    );
  } else {
    const open: boolean = !!modalType;
    const SpecifiedModal = MODAL_TYPES[modalType];

    return (
      <Modal
        aria-labelledby={`${modalType}-modal`}
        open={open}
        onClose={() => {
            if (!disableBackdropClick) {
                ModalHelper.close();
            }
            onClose && onClose();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "scroll",
        }}
      >
        <Fade in={open}>
          <SpecifiedModal {...modalProps} />
        </Fade>
      </Modal>
    );
  }
}
