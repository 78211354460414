import { useEffect } from "react";
import SecureLS from "secure-ls";

import { useMutation, currentUserVar, useReactiveVar } from "@apollo";
import { useUpdateUser } from "@hooks";
import { User } from "@models";
import { MUTATION_SEND_USER_INFO_TO_MAILERLITE } from "@queries";
import { getUserMetadata, ModalHelper } from "@utils";
import { sendSegmentAccountCreatedEvent, sendSegmentTrackEvent } from "helpers/analytics";

interface EmailPermissionParams {
  firstName: string;
  lastName: string;
  emailAddress: string;
}

type Props = {
  user: User;
  setConfetti: (confetti: boolean) => void;
};

export const useFirstSignIn = ({ user, setConfetti }: Props): void => {
  const { updateUser } = useUpdateUser();

  // Function called when the user clicks Accept on our custom UI
  const [sendUserInfoToMailerLite] = useMutation<
    boolean,
    EmailPermissionParams
  >(MUTATION_SEND_USER_INFO_TO_MAILERLITE);

  useEffect(() => {
    if (!user || !!user?.didLogin) return;

    const { didLogin } = user;
    const firstSignIn = !didLogin;

    if (firstSignIn) {
      let emailOptIn: boolean;

      const openEmailOptInModal = (): void => {
        ModalHelper.open({
          modalType: "emailOptIn",
          modalProps: {
            onAccept: onEmailOptInModalAccepted,
            onDeny: onEmailOptInModalDenied,
          },
          modalOptions: {
            disableBackdropClick: true,
          },
        });
      };

      const onEmailOptInModalAccepted = (): void => {
        emailOptIn = true;
        const { firstName, lastName, emailAddress } = user;
        sendUserInfoToMailerLite({
          variables: { firstName, lastName, emailAddress },
        });

        sendSegmentTrackEvent("permission", { emailOptIn: true });
        sendSegmentAccountCreatedEvent(true);

        onOpenSuccessModal();
      };

      const onEmailOptInModalDenied = (): void => {
        emailOptIn = false;

        sendSegmentTrackEvent("permission", { emailOptIn: false });
        sendSegmentAccountCreatedEvent(false);

        onOpenSuccessModal();
      };

      const onOpenSuccessModal = (): void => {
        setConfetti(true);
        ModalHelper.open({
          modalType: "success",
          modalProps: {
            onClose: onSuccessModalDismissed,
          },
          modalOptions: {
            disableBackdropClick: true,
          },
        });
      };

      const onSuccessModalDismissed = async (): Promise<void> => {
        setConfetti(false);
        ModalHelper.close();

        const metadata = await getUserMetadata();
        updateUser({ didLogin: true, emailOptIn, metadata });

        const encryptedLocalStorage = new SecureLS();
        encryptedLocalStorage.set("persisted-user", {...user, didLogin: true });
      };

      if (firstSignIn) {
        openEmailOptInModal();
      }
    }
  }, [user]);
};
