import React from "react";
import Img from "gatsby-image";
// import { GatsbyImage as Img } from "gatsby-plugin-image";

type Props = {
    image: any;
    className?: string;
    style?: any;
    alt?: string;
};

export const Image = ({ image, className, style, alt }: Props) => (
    /* Do not delete
     * This will be used with Gatsby V3
     */
    // <Img
    //     className={className}
    //     style={style}
    //     image={image}
    //     alt={alt}
    //     backgroundColor={"#D3D3D3"}
    //     loading="lazy"
    // />

    <Img
        className={className}
        style={style}
        alt={alt}
        fluid={image}
        backgroundColor={"#D3D3D3"}
        loading="lazy"
        fadeIn={true}
    />
);
