import { gql } from "@apollo/client";

export const USER_POOL_GET_WALLET_DATA = gql`
  query UserPoolGetWalletData($currency: String, $convertedCurrency: String!) {
    wallet: getWallet(currency: $currency) {
      balanceIncludingPendingInFiat {
        currency
        value
      }
      balanceNotIncludingFrozen {
        btc {
          value
        }
      }
      balanceIncludingFrozen {
        btc {
          value
        }
        fiat {
          currency
          value
        }
      }
      transactions {
        id
        createdAt
        externalRef {
          type
          data {
            advertiserName
            amount {
              fiat {
                value
              }
            }
            campaign {
              brand {
                images {
                  original
                }
                industry
                name
                useLogoImage
              }
            }
            externalData {
              offer {
                advertiserLogo
              }
              brandName
              images {
                imageUrl
              }
            }
            logo
            newUserFirstName
            referrerFirstName
            hotelName
            affiliateName
          }
        }
        status
        type
        amount {
          fiat {
            currency
            value
          }
          btc {
            currency
            value
          }
        }
      }
    }
    btcRate: getExchangeRate(
      initialCurrency: "XBT"
      convertedCurrency: $convertedCurrency
    ) {
      exchangeRate
      price
    }
    priceHistory: getPriceHistory(initialCurrency: "XBT", convertedCurrency: "CAD")
  }
`;
