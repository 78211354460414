import React from "react";

// Component imports
import { DividerLine } from "@components";
import { Grid, Paper, Skeleton, Typography } from "@material";

// Misc Imports

import { Assets } from "@assets";
import { useTranslation } from "@i18n";
import { CameraIcon } from "@icons";
import { makeStyles } from "@styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "80%",
    maxWidth: 1400,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 35,
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  header: {
    fontSize: 42,
    fontWeight: 800,
    color: "black",
    marginBottom: 12,
  },
  subHeader: {
    color: "#393939",
    fontWeight: 400,
    fontSize: 20,
    marginBottom: 21,
  },
  displaysContainer: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: "auto",
    marginRight: "auto",
    minHeight: 630,
    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
    },
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  displayContainer: {
    [theme.breakpoints.up("sm")]: {
      top: 88,
      marginRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
      marginRight: 0,
    },
  },
  rockEmoji: {
    width: 19,
    height: 19,
    marginLeft: 8,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  profileContainer: {
    backgroundColor: "white",
    border: `1px solid #EBEBEB`,
    width: "100%",
    height: 510,
    padding: 24,
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      padding: 15,
    },
  },
  cameraIconContainer: {
    position: "absolute",
    display: "flex",
    alignSelf: "flex-end",
    height: 42,
    width: 42,
    border: "1px solid #CDCDCD",
    borderRadius: 4,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#F4F4F4 !important",
      transitionProperty: "background-color",
      transitionDuration: "0.3s",
    },
  },
  profilePictureContainer: {
    position: "relative",
    marginTop: 15,
    height: 116,
    width: 116,
  },
  btcDisplayContainer: {
    marginTop: 13,
  },
  transactionsDisplayContainer: {
    marginTop: 10,
    marginBottom: 28,
  },
  profileButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 8,
    backgroundColor: "white",
    cursor: "pointer",
    height: 47,
    width: 254,
    minWidth: 254,
    textTransform: "none",
    border: "1px solid #464646",
    borderRadius: 0,
    color: "black",
    fontSize: 16,
    fontWeight: 500,
    transitionProperty: "background-color",
    transitionDuration: "0.4s",
    transitionTimingFunction: "ease-out",
    "&:hover": {
      backgroundColor: "#F4F4F4 !important",
      transitionProperty: "background-color",
      transitionDuration: "0.4s",
    },
  },
  profileCards: {
    height: 510,
    width: "100%",
    backgroundColor: "white",
    border: `1px solid #EBEBEB`,
    padding: 24,
    minHeight: 411,
  },
  transactionHeader: {
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end",
    },
  },
  linkedCardsHeader: {
    fontSize: 24,
    fontWeight: 500,
  },
}));

export function ProfileSkeleton() {
  const classes: any = useStyles({});
  const { t } = useTranslation("profile");

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h2" className={classes.header}>
          {t("myProfile")}
        </Typography>
        <Typography variant="h6" className={classes.subHeader}>
          {t("profileSubheader")}
          <img
            src={Assets.ROCK_EMOJI}
            className={classes.rockEmoji}
            alt="the-horns"
          />
        </Typography>
        <Grid
          container
          item
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          lg={12}
          className={classes.displaysContainer}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={4}
            className={classes.displayContainer}
          >
            <Paper className={classes.profileContainer}>
              <Grid container direction="column" alignItems="flex-start">
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  className={classes.cameraIconContainer}
                >
                  <CameraIcon width={24} />
                </Grid>
                <Grid
                  container
                  item
                  justifyContent="center"
                  alignItems="center"
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className={classes.profilePictureContainer}
                  >
                    <Skeleton
                      variant="circle"
                      animation="wave"
                      width={116}
                      height={116}
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" alignItems="center">
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={"65%"}
                    height={32}
                    style={{ marginTop: 18 }}
                  />
                </Grid>
                <Grid container justifyContent="center" alignItems="center">
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={"65%"}
                    height={30}
                    style={{ marginTop: 7 }}
                  />
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  className={classes.btcDisplayContainer}
                >
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    width={167}
                    height={28}
                    style={{ borderRadius: 4 }}
                  />
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  className={classes.transactionsDisplayContainer}
                >
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={"25%"}
                    height={22}
                    style={{ marginTop: 5 }}
                  />
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  item
                  sm={12}
                >
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    width={254}
                    height={45}
                  />
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  item
                  sm={12}
                >
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    width={254}
                    height={45}
                    style={{ marginTop: 8 }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid
            container
            item
            justifyContent="flex-end"
            alignItems="center"
            xs={12}
            sm={12}
            md={7}
            lg={8}
          >
            <Paper className={classes.profileCards}>
              <Grid container direction="column">
                <Grid
                  container
                  item
                  direction="row"
                  justifyContent="space-between"
                  lg={12}
                  className={classes.transactionHeader}
                >
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    {" "}
                    <Typography className={classes.linkedCardsHeader}>
                      {t("linkedCardDisplay.myLinkedCards")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    width={"100%"}
                    height={74}
                    style={{ borderRadius: 4, marginTop: 8 }}
                  />
                  <DividerLine style={{ marginTop: 16, marginBottom: 8 }} />
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    width={"100%"}
                    height={47}
                    style={{ borderRadius: 4, marginTop: 27 }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
// }
